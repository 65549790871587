import React from 'react';
import {UserProfileEditView, UserBrevetsView, UserCenterView, UserPasswordView, UserManualsView} from './UserView.js';
import brevetData from '../models/BrevetModel';
import centerData from '../models/CenterModel';
import axios from 'axios';
import {Route} from "react-router-dom";
import Button from "@material-ui/core/Button";
import userData from "../models/UserModel";
import loadImage from 'blueimp-load-image';

var userImageBlobData;

export default class User extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: this.props.constants.user,
            selectedUserImage: null,
            userImage: this.props.constants.placeholderImage,
            center: JSON.parse(JSON.stringify(centerData)),
            brevets: [JSON.parse(JSON.stringify(brevetData))],
            manuals: [],
            allCenters: [],
            selectedCenter: 1001,
            selectedManual: {title: "", sources: [],},
            profileSaveButtonStatus: false,
            passwordSaveButtonStatus: false,
            newUserPassword: "",
            newUserPasswordRepeat: "",
            showChooseCenterModal: false
        };
        this.getAllCenters();
        this.getUserCenter(this.state.user.studentOfCenter);
        this.getUserBrevets();
        this.getUserManuals();
        this.getUserImage();
    }

    getUserCenter = (centerId) => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'center/' + centerId,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (User.validate(res.data)) {
                        this.setState({center: res.data});

                    } else {
                        console.log("fail")
                    }
                }
            )
    };
    getUserBrevets = () => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'student/brevets/',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (User.validate(res.data)) {
                        this.setState({brevets: res.data});
                    } else {
                        console.log("fail")
                    }
                }
            )
    };
    getUserManuals = () => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'student/manuals/',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (User.validate(res.data)) {
                        this.setState({manuals: res.data});
                    } else {
                        console.log("fail")
                    }
                }
            )
    };
    getAllCenters = () => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'centers',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (User.validate(res.data)) {
                        this.setState({allCenters: res.data});
                    } else {
                        console.log("fail")
                    }
                }
            )
    };

    handleUserFieldChange = (event, field, subfield) => {
        this.setState({profileSaveButtonStatus: true});
        this.user = this.state.user;
        if (subfield === null) {
            this.user[field] = event.target.value;
        } else {
            this.user[field][subfield] = event.target.value;
        }
        this.setState({user: this.user});
    };

    handleSelectedFile = (event) => {
        loadImage( //for saving
            event.target.files[0],
            function (canvas) {
                canvas.toBlob(function (blob) {
                    userImageBlobData = blob
                });
            },
            {
                maxWidth: 600,
                maxHeight: 800,
                orientation: true,
                crop: true
            }
        );

        loadImage( //for showing on canvas
            event.target.files[0],
            function (canvas) {
                canvas.id = "user-image";

                var img1 = document.getElementById("user-image");
                var parentDiv = img1.parentNode;
                parentDiv.replaceChild(canvas, img1);
            },
            {
                maxWidth: 150,
                maxHeight: 200,
                orientation: true,
                crop: true
            }
        );
    };
    uploadUserImage = (userId) => {
        if (userImageBlobData) {

            var bodyFormData = new FormData();
            bodyFormData.set('file', userImageBlobData);

            axios({
                method: 'post',
                url: this.props.constants.baseAPIURL + 'user/pic',
                withCredentials: true,
                headers: {'Content-Type': 'multipart/form-data'},
                data: bodyFormData,
            })
                .then(() => {
                        this.setState({
                            userImage: null
                        });
                        this.setState({selectedUser: JSON.parse(JSON.stringify(userData))});
                        this.getUserImage();
                        userImageBlobData = null;
                    }
                )
        } else {
        }
    };
    getUserImage = () => {
        this.setState({userImage: this.props.constants.loadingImage});
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'user/pic',
            withCredentials: true,
            responseType: "arraybuffer"
        })
            .then(res => {
                    res = new Buffer(res.data, 'binary').toString('base64');
                    this.setState({userImage: "data:image/png;base64," + res})
                }
            )
            .catch(() => {
                this.setState({userImage: this.props.constants.placeholderImage});
            })
    };

    handleCenterSelect = (event) => {
        this.setState({selectedCenter: event.target.value});
    };
    saveUser = (user) => {
        axios({
            method: 'put',
            url: this.props.constants.baseAPIURL + 'user',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
            data: user

        })
            .then(res => {
                    if (User.validate(res.data)) {
                        this.setState({profileSaveButtonStatus: false});
                        if (userImageBlobData !== null) {
                            this.uploadUserImage();
                        }
                    } else {
                        console.log("fail")
                    }
                }
            );
    };
    saveUserCenter = (centerId) => {
        axios({
            method: 'put',
            url: this.props.constants.baseAPIURL + 'user',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
            data: {"studentOfCenter": centerId}
        })
            .then(() => {
                if (User.validate()) {
                    this.getUserCenter(centerId);
                    alert("Your dive center has been changed.");
                    this.toggleShowChooseCenterModal();
                } else {
                    console.log("fail")
                }
            })
            .catch(() => {
                alert("Center could not be changed - wrong ID?")
            })
    };
    handlePasswordFieldChange = (event, field) => {
        this.setState({passwordSaveButtonStatus: true});
        this.newUserPassword = event.target.value;
        if (field === "first") {
            this.setState({newUserPassword: this.newUserPassword});
        }
        if (field === "second") {
            this.setState({newUserPasswordRepeat: this.newUserPassword});
        }
        if (this.newUserPassword === "" || this.newUserPasswordRepeat === "") {
            this.setState({passwordSaveButtonStatus: false});
        }
    };
    saveNewPassword = (password, passwordRepeat) => {
        if (password === passwordRepeat) {
            axios({
                method: 'put',
                url: this.props.constants.baseAPIURL + 'user',
                withCredentials: true,
                headers: {'Content-Type': 'application/json'},
                data: {password: password}
            })
                .then(() => {
                        alert("Password has been saved!")
                    }
                );
        } else alert("Passwords don't match!")
    };

    toggleShowChooseCenterModal = () => {
        this.setState({showChooseCenterModal: !this.state.showChooseCenterModal})
    };

    getManual = (id) => {
        this.setState({selectedManual: {title: [], author: "", sources: [""]}});
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'student/manuals/' + id,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (User.validate(res.data)) {
                        this.setState({selectedManual: res.data});
                    } else {
                        console.log("fail")
                    }
                }
            );
    };

    static validate() {
        return true;
    }

    render() {
        return (
            <div className="row">
                <div className="col-12 main-container">
                    <Route exact path='/profile' render={(props) =>
                        <div>
                            <UserProfileEditView {...props}
                                                 user={this.state.user}
                                                 userImage={this.state.userImage}
                                                 handleSelectedFile={this.handleSelectedFile}
                                                 uploadUserImage={this.uploadUserImage}
                                                 handleUserFieldChange={this.handleUserFieldChange}
                                                 saveUser={this.saveUser}
                                                 profileSaveButtonStatus={this.state.profileSaveButtonStatus}/>

                            <div className="card-footer">
                                <div className="row">
                                    <div className="col-12">
                                        <Button variant="contained" color="primary"
                                                disabled={!this.state.profileSaveButtonStatus}
                                                onClick={() => this.saveUser(this.state.user)}>Save Profile
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <UserPasswordView {...props}
                                              user={this.state.user}
                                              newUserPassword={this.state.newUserPassword}
                                              newUserPasswordRepeat={this.state.newUserPasswordRepeat}
                                              handlePasswordFieldChange={this.handlePasswordFieldChange}
                                              saveNewPassword={this.saveNewPassword}/>
                            <div className="card-footer">
                                <div className="row">
                                    <div className="col-12">
                                        <Button variant="contained" color="primary"
                                                disabled={!this.state.passwordSaveButtonStatus}
                                                onClick={() => this.saveNewPassword(this.state.newUserPassword, this.state.newUserPasswordRepeat)}>Save
                                            Password
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }/>
                    <Route exact path='/profile/center' render={(props) =>
                        <UserCenterView {...props}
                                        center={this.state.center}
                                        centers={this.state.allCenters}
                                        handleCenterSelect={this.handleCenterSelect}
                                        selectedCenter={this.state.selectedCenter}
                                        saveUserCenter={this.saveUserCenter}
                                        toggleShowChooseCenterModal={this.toggleShowChooseCenterModal}
                                        showChooseCenterModal={this.state.showChooseCenterModal}/>}/>
                    <Route exact path='/profile/brevets' render={(props) =>
                        <UserBrevetsView {...props}
                                         user={this.state.user}
                                         brevets={this.state.brevets}/>}/>
                    <Route exact path='/profile/manuals' render={(props) =>
                        <UserManualsView {...props}
                                         user={this.state.user}
                                         manuals={this.state.manuals}
                                         selectedManual={this.state.selectedManual}
                                         getManual={this.getManual}/>}/>
                </div>
            </div>
        )
    }
}