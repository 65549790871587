import React from 'react';
import MaterialTable from "material-table";
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import Button from "@material-ui/core/Button";
import {BrevetDetailView} from "../brevet/BrevetView";
import {CenterProfileView} from "../center/CenterView";
import {UserProfileView} from "../user/UserView";
import TextField from '@material-ui/core/TextField';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import Moment from "react-moment";
import Chip from "@material-ui/core/Chip";
import SearchIcon from "@material-ui/core/SvgIcon/SvgIcon";

const theme = createMuiTheme({
    overrides: {
        MuiTableCell: { // Name of the component ⚛️ / style sheet
            body: {
                padding: "4px 10px 4px 10px",
                fontSize: "0.75rem"
            },
            head: {
                padding: "4px 10px 4px 10px",
                fontSize: "0.75rem"
            },
            root: {
                '&:last-child': {
                    paddingRight: "4px"

                }
            }
        }
    }
});

export class TrainerCenterView extends React.Component {
    render() {
        return (
            <div>
                <div className="card-header">
                    <h1>You are Trainer in</h1>
                </div>
                <CenterProfileView center={this.props.trainerCenter}/>
                <div className="card-footer">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <Button variant="contained" color="primary"
                                    onClick={this.props.toggleShowChooseCenterModal}>
                                Change Center
                            </Button>
                        </div>
                    </div>
                </div>

                <Modal isOpen={this.props.showChooseCenterModal}>
                    <ModalHeader>
                        <div>Apply for trainership in a center by its NSC ID</div>
                    </ModalHeader>
                    <ModalBody>
                        The center has to accept your application, before the change will be performed.
                        <TextField
                            id="centerIdChooser"
                            label="Center ID"
                            fullWidth
                            margin="dense"
                            onChange={() => {
                            }}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="contained" color="primary"
                                onClick={() => {
                                    this.props.saveCenterAspirant(document.getElementById("centerIdChooser").value)
                                }}>Change to this center</Button>
                        <Button variant="contained" color="primary"
                                onClick={() => {
                                    this.props.toggleShowChooseCenterModal()
                                }}>Cancel</Button>
                    </ModalFooter>
                </Modal>

            </div>
        )
    }
}

export class TrainerStudentsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showProfileModal: false,
            showBrevetModal: false,
            brevetModalStep: ["block", "none", "none", "none"],
            brevetNextButton: "none",
            brevetBackButton: "none",
            brevetCreateButton: "none",
            brevetCertifiedAt: "",
            brevetComments: "",
            selectNewBrevetType: "OWD",
            selectedUser: {}
        };
    }


    render() {
        return (
            <div>
                <div className="card-header">
                    <h1>Students of your center</h1>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <MuiThemeProvider theme={theme}>
                                <MaterialTable columns={[
                                    {title: 'ID', field: 'id'},
                                    {title: 'First', field: 'firstName'},
                                    {title: 'Last', field: 'lastName'}]}
                                               data={this.props.students}
                                               title="Students"
                                               options={{
                                                   search: true,
                                                   paging: false,
                                                   actionsColumnIndex: 0,
                                                   searchFieldAlignment: "left",
                                                   showTitle: false
                                               }}
                                               actions={[
                                                   {
                                                       icon: 'info',
                                                       tooltip: 'Show info',
                                                       onClick: (event, rowData) => {
                                                           this.setState({
                                                               showProfileModal: !this.state.showProfileModal,
                                                               selectedUser: rowData
                                                           });
                                                           this.props.getUserImage(rowData.id);
                                                       }
                                                   },
                                                   {
                                                       icon: 'school',
                                                       tooltip: 'Brevet ausstellen',
                                                       onClick: (event, rowData) => {
                                                           this.props.checkIfUserIsComplete(rowData);
                                                           this.setState({
                                                               selectedUser: rowData
                                                           })
                                                       }
                                                   }
                                               ]}
                                               localization={{header: {actions: "Options"}}}/>
                            </MuiThemeProvider>
                        </div>
                    </div>
                    <Modal isOpen={this.state.showProfileModal}>
                        <ModalBody>
                            <UserProfileView user={this.state.selectedUser}
                                             userImage={this.props.userImage}/>

                        </ModalBody>
                        <ModalFooter>
                            <Button variant="contained" color="primary"
                                    onClick={() => this.setState({showProfileModal: false})}>Close</Button>
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={this.props.showBrevetModal}>
                        <ModalHeader>
                            <h1>Brevet erstellen</h1>
                        </ModalHeader>
                        <ModalBody>
                            <div style={{display: this.state.brevetModalStep[0]}}>
                                <div className="row">
                                    <div className="col-12">
                                        Add Brevet
                                        for {this.state.selectedUser.firstName} {this.state.selectedUser.lastName}:
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <MuiThemeProvider theme={theme}>
                                            <MaterialTable columns={[
                                                {title: 'Brevet Type', field: 'label'}]}
                                                           data={this.props.brevetTypes}
                                                           title="Select Brevet Type"
                                                           options={{
                                                               showTitle: false,
                                                               search: true,
                                                               actionsColumnIndex: -1,
                                                               searchFieldAlignment: "left"
                                                           }}
                                                           actions={[
                                                               {
                                                                   icon: 'check_circle_outline',
                                                                   tooltip: 'Select Brevet',
                                                                   onClick: (event, rowData) => {
                                                                       this.setState({selectNewBrevetType: rowData.key});
                                                                       this.setState({
                                                                           brevetBackButton: "block",
                                                                           brevetNextButton: "block",
                                                                           brevetModalStep: [this.state.brevetModalStep[3],
                                                                               this.state.brevetModalStep[0],
                                                                               this.state.brevetModalStep[1],
                                                                               this.state.brevetModalStep[2]]
                                                                       });
                                                                   }
                                                               }
                                                           ]}
                                                           localization={{header: {actions: "Options"}}}/>
                                        </MuiThemeProvider>
                                    </div>
                                </div>
                            </div>
                            <div style={{display: this.state.brevetModalStep[1]}}>
                                <form noValidate>
                                    <TextField
                                        label="Certified at"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(event) => this.setState({brevetCertifiedAt: event.target.value})}
                                    />
                                </form>
                            </div>
                            <div style={{display: this.state.brevetModalStep[2]}}>
                                <TextField
                                    label="Comments"
                                    multiline
                                    rows="4"
                                    variant="outlined"
                                    onChange={(event) => this.setState({brevetComments: event.target.value})}
                                />
                            </div>
                            <div style={{display: this.state.brevetModalStep[3]}}>
                                <p>
                                    Create {this.state.selectNewBrevetType} for {this.state.selectedUser.firstName} {this.state.selectedUser.lastName}.
                                </p>
                                <p>
                                    Certified at {this.state.brevetCertifiedAt}
                                </p>
                                <p>
                                    Comments: {this.state.brevetComments}
                                </p>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button style={{display: this.state.brevetBackButton}} variant="contained" color="primary"
                                    onClick={() => {
                                        this.setState({
                                            brevetModalStep: [this.state.brevetModalStep[1],
                                                this.state.brevetModalStep[2],
                                                this.state.brevetModalStep[3],
                                                this.state.brevetModalStep[0]]
                                        });
                                        if (this.state.brevetModalStep[1] === "block"){
                                            this.setState({brevetBackButton: "none", brevetNextButton: "none"});
                                        }
                                        if (this.state.brevetModalStep[3] === "block") {
                                            this.setState({
                                                brevetNextButton: "block", brevetCreateButton: "none"
                                            })
                                        }
                                    }}>Back</Button>
                            <Button style={{display: this.state.brevetNextButton}} variant="contained" color="primary"
                                    disabled={!this.state.brevetCertifiedAt}
                                    onClick={() => {
                                        this.setState({
                                            brevetModalStep: [this.state.brevetModalStep[3],
                                                this.state.brevetModalStep[0],
                                                this.state.brevetModalStep[1],
                                                this.state.brevetModalStep[2]],
                                            nextButtonEnabled:true
                                        });
                                        if (this.state.brevetModalStep[2] === "block") {
                                            this.setState({
                                                brevetNextButton: "none", brevetCreateButton: "block"
                                            })
                                        }
                                        if (this.state.brevetModalStep[0] === "block"){
                                            this.setState({brevetBackButton: "block"});
                                        }
                                    }}>Next</Button>
                            <Button style={{display: this.state.brevetCreateButton}} variant="contained" color="primary"
                                    onClick={() => {
                                        this.props.createBrevet(this.state.selectedUser.id,
                                            this.state.selectNewBrevetType,
                                            this.state.brevetCertifiedAt,
                                            this.state.brevetComments);
                                        this.setState({
                                            brevetModalStep: ["block", "none", "none", "none"],
                                            brevetNextButton: "block",
                                            brevetBackButton: "none",
                                            brevetCreateButton: "none",
                                            brevetCertifiedAt: "",
                                            brevetComments: "",
                                            selectNewBrevetType: "OWD"
                                        })
                                    }}>Add Brevet</Button>
                            <Button variant="contained" color="primary" onClick={() => {
                                this.props.toggleShowBrevetModal();
                                this.setState({
                                    brevetModalStep: ["block", "none", "none", "none"],
                                    brevetNextButton: "block",
                                    brevetCreateButton: "none",
                                    brevetCertifiedAt: "",
                                    brevetComments: "",
                                    selectNewBrevetType: "OWD"
                                })
                            }}>Close</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>)
    }
}

export class TrainerBrevetsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showBrevetModal: false,
            selectedBrevet: {},
            brevetLevelGrouping: {title: 'Brevet level', field: 'level', filtering:false, render: rowData =>
                    <div>
                        {this.props.brevetTypesByKey[rowData.level] ? this.props.brevetTypesByKey[rowData.level].label : rowData.level}
                    </div>
            },
            showHeader: true,
            yearFilter:"",
            yearButtonColors: {"nineteen": "default", "twenty": "default", "all": "primary"},
            groupButtonColor: "default",
            tablePagination:true,
            pageSize:10,
            columnsFilter:true
        };
    }

    render() {
        return (
            <div>
                <div className="card-header">
                    <h1>Brevets you issued</h1>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12" hidden={!this.props.unapprovedBrevets.length}>
                            <h2>Unvalidated Brevets (center needs to validate)</h2>
                            <hr/>
                        </div>
                        <div className="col-12" hidden={!this.props.unapprovedBrevets.length}>
                            <MaterialTable columns={[
                                {title: 'Serial', field: 'id'},
                                {title: 'Brevet level', field: 'level', render: rowData =>
                                        <div>
                                            {this.props.brevetTypesByKey[rowData.level] ? this.props.brevetTypesByKey[rowData.level].label : rowData.level}
                                        </div>
                                },
                                {
                                    title: 'Created', field: 'createdAt', render: rowData =>
                                        <Moment format="YYYY/MM/DD">
                                            {rowData.createdAt}
                                        </Moment>
                                },
                                {title: 'Student first', field: 'student.firstName',
                                    headerStyle:{display:"none"}, cellStyle:{display:"none"}},
                                {title: 'Student', field: 'student.lastName', render: rowData =>
                                        <div>
                                            <div>{rowData.student.lastName}, {rowData.student.firstName}</div>
                                            <div>ID: {rowData.student.id}</div>
                                        </div>},
                                {title: 'Student ID', field: 'student.id',
                                    headerStyle:{display:"none"}, cellStyle:{display:"none"}}
                                    ]}
                                           data={this.props.unapprovedBrevets}
                                           title="Brevetliste"
                                           options={{
                                               toolbar: false,
                                               paging: false,
                                               actionsColumnIndex: 0
                                           }}
                                           actions={[
                                               {
                                                   icon: 'info',
                                                   tooltip: 'Show info',
                                                   onClick: (event, rowData) => {
                                                       this.setState({
                                                           showBrevetModal: !this.state.showBrevetModal,
                                                           selectedBrevet: rowData
                                                       })
                                                   }
                                               },
                                               {
                                                   icon: 'cancel',
                                                   tooltip: 'Delete brevet',
                                                   onClick: (event, rowData) => {
                                                       this.props.deleteUnapprovedBrevet(rowData);
                                                   }
                                               }]}
                                           localization={{header: {actions: "Options"}}}/>
                        </div>
                        <div className="col-12"><br/></div>
                        <div className="col-12">
                            <h2>Validated Brevets</h2>
                            <hr/>
                        </div>
                        <div className="col-12">
                            <MaterialTable columns={[
                                {title: 'Serial', field: 'id',filtering:false,},
                                this.state.brevetLevelGrouping,
                                {
                                    title: 'Created', field: 'createdAt', filtering:false, defaultFilter: this.state.yearFilter, render: rowData =>
                                        <Moment format="YYYY/MM/DD">
                                            {rowData.createdAt}
                                        </Moment>
                                },
                                {title: 'Student ID', field: 'student.id', filterCellStyle: {display: "none"},
                                    headerStyle:{display:"none"}, cellStyle:{display:"none"}},
                                {title: 'Student first', field: 'student.firstName', filterCellStyle: {display: "none"},
                                    headerStyle:{display:"none"}, cellStyle:{display:"none"}},
                                {title: 'Student', field: 'student.lastName', filtering:false, render: rowData =>
                                        <div>
                                            <div>{rowData.student.lastName}, {rowData.student.firstName}</div>
                                            <div>ID: {rowData.student.id}</div>
                                        </div>}
                                ]}
                                           data={this.props.brevets}
                                           icons={{
                                               Filter: () => (<SearchIcon style={{ fontSize: 12 }} color="primary"/>)
                                           }}
                                           title="Brevetliste"
                                           options={{
                                               header: this.state.showHeader,
                                               filtering: this.state.columnsFilter,
                                               search: true,
                                               paging: true,
                                               pageSize: this.state.pageSize,
                                               pageSizeOptions:[5,10,200],
                                               emptyRowsWhenPaging:false,
                                               actionsColumnIndex: 0,
                                               searchFieldAlignment: "left",
                                               showTitle: false
                                           }}
                                           components={{
                                               GroupRow: props => (
                                                   <tr style={{display:"block", padding:"10px"}}>
                                                       <td style={{display:"inline-block", width:"400px"}}>
                                                           <strong>{props.groups[0].title}: </strong>
                                                           {this.props.brevetTypesByKey[props.groupData.value]
                                                               ? this.props.brevetTypesByKey[props.groupData.value].label
                                                               : props.groupData.value}
                                                       </td>
                                                       <td style={{display:"inline-block", width:"100px"}}>
                                                           <strong>Issued:</strong> {props.groupData.data.length}</td>
                                                   </tr>
                                               )/*,
                                                   Pagination: props =>(
                                                       <MTablePagination {...props}>{console.log(props)}
                                                       </MTablePagination>
                                                   )*/
                                           }}
                                           actions={[
                                               {
                                                   icon: 'info',
                                                   tooltip: 'Show info',
                                                   onClick: (event, rowData) => {
                                                       this.setState({
                                                           selectedBrevet: rowData,
                                                           showBrevetModal: !this.state.showBrevetModal,
                                                       });
                                                   }
                                               },
                                               {
                                                   icon: () => <Chip color={this.state.yearButtonColors.nineteen} label="2019"/>,
                                                   tooltip: 'Show only brevets of 2019',
                                                   onClick: () => {
                                                       this.setState({yearFilter: "2019",
                                                           yearButtonColors: {"nineteen": "primary", "twenty": "default", "all": "default"}});
                                                   },
                                                   isFreeAction: true
                                               },
                                               {
                                                   icon: () => <Chip color={this.state.yearButtonColors.twenty} label="2020"/>,
                                                   tooltip: 'Show only brevets of 2020',
                                                   onClick: () => {
                                                       this.setState({yearFilter: "2020",
                                                           yearButtonColors: {"nineteen": "default", "twenty": "primary", "all": "default"}})
                                                   },
                                                   isFreeAction: true
                                               },
                                               {
                                                   icon: () => <Chip color={this.state.yearButtonColors.all} label="All"/>,
                                                   tooltip: 'Show all years',
                                                   onClick: () => {
                                                       this.setState({yearFilter: "",
                                                           yearButtonColors: {"nineteen": "default", "twenty": "default", "all": "primary"}})
                                                   },
                                                   isFreeAction: true
                                               },
                                               {
                                                   icon: () => <Chip color="secondary" variant="outlined"
                                                                     label={this.state.groupButtonColor==="default" ? "Report" : "List"}/>,
                                                   tooltip: 'Group brevets by level',
                                                   onClick: () => {
                                                       this.setState({tablePagesSize:200});
                                                       if(this.state.brevetLevelGrouping.defaultGroupOrder) {
                                                           this.setState({
                                                               brevetLevelGrouping: {title: 'Brevet level', field: 'level', filtering:false, render: rowData =>
                                                                       <div>
                                                                           {this.props.brevetTypesByKey[rowData.level] ? this.props.brevetTypesByKey[rowData.level].label : rowData.level}
                                                                       </div>
                                                               },
                                                               showHeader:true,
                                                               groupButtonColor:"default",
                                                               tablePagination:true,
                                                               pageSize:10,
                                                               columnsFilter:true
                                                           });
                                                       }
                                                       else {
                                                           this.setState({
                                                               brevetLevelGrouping: {title: 'Brevet level', field: 'level', filtering:false, defaultGroupOrder: 1, render: rowData =>
                                                                       <div>
                                                                           {this.props.brevetTypesByKey[rowData.level] ? this.props.brevetTypesByKey[rowData.level].label : rowData.level}
                                                                       </div>
                                                               },
                                                               showHeader:false,
                                                               groupButtonColor:"primary",
                                                               tablePagination:false,
                                                               pageSize:50,
                                                               columnsFilter:false
                                                           });
                                                       }
                                                   },
                                                   isFreeAction: true
                                               }]}
                                           localization={{header: {actions: "Options"}}}/>
                        </div>
                    </div>
                    <Modal isOpen={this.state.showBrevetModal}>
                        <ModalBody>
                            <BrevetDetailView brevet={this.state.selectedBrevet}/>

                        </ModalBody>
                        <ModalFooter>
                            <Button variant="contained" color="primary"
                                    onClick={() => this.setState({showBrevetModal: false})}>Close</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        );
    }
}