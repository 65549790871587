import React from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import loading from "../../assets/loading.gif";

var userFieldsToRender = [
    {"label": "User Name (min 5 characters a-z 0-9)", "key": "userName"},
    {"label": "E-Mail", "key": "email"},
    {"label": "First Name", "key": "firstName"},
    {"label": "Last Name", "key": "lastName"}];

export class RegistrationActivateView extends React.Component {
    render() {
        return (
            <div className="card login-card">
                <div className="card-header">
                    <h1>User activation</h1>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 padding-top-15"
                             hidden={(this.props.activationLoadingStatus !== "success")}>
                            Yout Account has been activated. You can now login.
                        </div>
                        <div className="col-12 padding-top-15 align-right"
                             hidden={(this.props.activationLoadingStatus !== "success")}>
                            <Button variant="contained" color="primary"
                                    onClick={() =>
                                        window.open("/login", "_self")}>Go to login
                            </Button>
                        </div>
                        <div className="col-12 align-center padding-top-15"
                             hidden={(this.props.activationLoadingStatus !== "loading")}>
                            Checking your registration. Please don't close this window.
                            <img src={loading} alt="loading"/>
                        </div>
                        <div className="col-12 padding-top-15"
                             hidden={(this.props.activationLoadingStatus !== "fail")}>
                            No registration for this activation key found.
                        </div>
                        <div className="col-12 padding-top-15 align-right"
                             hidden={(this.props.activationLoadingStatus !== "fail")}>
                            <Button variant="contained" color="primary"
                                    onClick={() =>
                                        window.open("/login", "_self")}>Back to login
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="row">
                        <div className="col-12 align-right">
                            <a className="padding-left-15" href="http://dive-nsc.com/impressum">Imprint</a>
                            <a className="padding-left-15" href="http://dive-nsc.com/datenschutz">Data
                                Protection</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export class RegistrationCreateView extends React.Component {
    render() {
        return (
            <div className="card register-card">
                <div className="card-header">
                    <h2>Register as new user for NSC OBS</h2>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12" hidden={(this.props.registrationLoadingStatus)}>
                            <div className="row">
                                <div className="col-12">
                                    Please fill out the data to receive an e-mail with an activation link. After activation you will be able to complete your profile, upload your image and join your dive center so that you can receive your brevets.
                                </div>
                            </div>
                            <div className="row">
                                {
                                    userFieldsToRender.map((field) => {
                                        return (
                                            <div key={field.label} className="col-md-6 col-xs-12">
                                                <TextField
                                                    id={field.label}
                                                    label={field.label}
                                                    fullWidth
                                                    InputLabelProps={{shrink: true}}
                                                    value={this.props.newUser[field.key][field.subkey]}
                                                    margin="dense"
                                                    onChange={(e) => this.props.handleUserFieldChange(e, field.key, null)}
                                                />
                                            </div>)
                                    })
                                }
                            </div>
                            <div className="row">
                                <div key="password" className="col-md-6 col-xs-12">
                                    <TextField
                                        id="password"
                                        label="Password"
                                        type="password"
                                        fullWidth
                                        InputLabelProps={{shrink: true}}
                                        value={this.props.newUser.password}
                                        margin="dense"
                                        onChange={(e) => this.props.handleUserFieldChange(e, "password", null)}
                                    />
                                </div>
                                <div key="passwordRepeat" className="col-md-6 col-xs-12">
                                    <TextField
                                        id="passwordRepeat"
                                        label="Repeat Password"
                                        type="password"
                                        fullWidth
                                        InputLabelProps={{shrink: true}}
                                        value={this.props.newUser.passwordRepeat}
                                        margin="dense"
                                        onChange={(e) => this.props.handleUserFieldChange(e, "passwordRepeat", null)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 align-center padding-top-15"
                         hidden={(!this.props.registrationLoadingStatus)}>
                        Proceeding with your registration. Please don't close this window.
                        <img src={loading} alt="loading"/>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="row">
                        <div className="col-12 align-right" hidden={(this.props.registrationLoadingStatus)}>
                            <div className="padding-right-15 d-inline">
                                <Button variant="contained" color="primary"
                                        onClick={() => {
                                            this.props.registerUser(this.props.newUser);
                                        }}>Register
                                </Button>
                            </div>
                            <div className="d-inline">
                                <Button variant="contained" color="primary"
                                        onClick={() =>
                                            window.open("/login", "_self")}>Cancel</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export class PasswordResetRequestView extends React.Component {
    render() {
        return (
            <div className="card login-card">
                <div className="card-header">
                    <h2>Forgot Password</h2>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12" hidden={(this.props.passwordResetLoadingStatus)}>
                            <div className="row">
                                <div className="col-12">
                                    Input your user name and e-mail address so that we can send you link to set a new
                                    password.
                                </div>
                                <div className="col-12">
                                    <TextField id="userName"
                                               label="User name"
                                               fullWidth
                                               margin="dense"/>
                                </div>
                                <div className="col-12">
                                    <TextField id="email"
                                               label="E-mail"
                                               fullWidth
                                               margin="dense"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 align-center padding-top-15"
                             hidden={(!this.props.passwordResetLoadingStatus)}>
                            Processing your request. Please don't close this window.
                            <img src={loading} alt="loading"/>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="row">
                        <div className="col-12 align-right" hidden={(this.props.passwordResetLoadingStatus)}>
                            <div className="padding-right-15 d-inline">
                                <Button variant="contained" color="primary"
                                        onClick={() => {
                                            this.props.requestPasswordReset(
                                                document.getElementById("userName").value,
                                                document.getElementById("email").value);
                                        }}>Send
                                </Button>
                            </div>
                            <div className="d-inline">
                                <Button variant="contained" color="primary"
                                        onClick={() =>
                                            window.open("/login", "_self")}>Cancel</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export class PasswordChangeView extends React.Component {
    render() {
        return (
            <div className="card login-card">
                <div className="card-header" hidden={(this.props.match.params.id.length !== 36)}>
                    <h2>Set a new Password</h2>
                </div>
                <div className="card-body" hidden={(this.props.match.params.id.length === 36)}>
                    <div className="row">
                        <div className="col-12">
                            Wrong activation link.
                        </div>
                    </div>
                </div>
                <div className="card-body" hidden={(this.props.match.params.id.length !== 36)}>
                    <div className="row">
                        <div className="col-12" hidden={(this.props.passwordChangeLoadingStatus)}>
                            <div className="row">
                                <div className="col-12">
                                    Input your user name and e-mail address so that we can send you link to set a new
                                    password.
                                </div>
                                <div className="col-12">
                                    <TextField id="password"
                                               label="New password"
                                               type="password"
                                               fullWidth
                                               margin="dense"/>
                                </div>
                                <div className="col-12">
                                    <TextField id="passwordRepeat"
                                               label="Repead password"
                                               type="password"
                                               fullWidth
                                               margin="dense"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 align-center padding-top-15"
                             hidden={(!this.props.passwordChangeLoadingStatus)}>
                            Processing your request. Please don't close this window.
                            <img src={loading} alt="loading"/>
                        </div>
                    </div>
                </div>
                <div className="card-footer" hidden={(this.props.match.params.id.length !== 36)}>
                    <div className="row">
                        <div className="col-12 align-right" hidden={(this.props.passwordChangeLoadingStatus)}>
                            <div className="padding-right-15 d-inline">
                                <Button variant="contained" color="primary"
                                        onClick={() => {
                                            this.props.changePassword(
                                                document.getElementById("password").value,
                                                document.getElementById("passwordRepeat").value);
                                        }}>Send
                                </Button>
                            </div>
                            <div className="d-inline">
                                <Button variant="contained" color="primary"
                                        onClick={() =>
                                            window.open("/login", "_self")}>Cancel</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}