import React from 'react';
import axios from 'axios';
import {Route} from "react-router-dom";
import {
    AdminBrevetsView,
    AdminCentersView,
    AdminGeneralView,
    AdminUsersView,
    AdminBrevetLevelsView,
    AdminManualsView,
    AdminManualTransactionsView
} from "./AdminView";
import centerData from '../models/CenterModel';
import userData from "../models/UserModel";
import countryList from 'react-select-country-list';
import loadImage from 'blueimp-load-image';

var userImageBlobData;

export default class Admin extends React.Component {

    newManual = {title: "", author: "", urls: [], sources: [""], voucher: "NONE",previewImageUrl:"",price: "0",
        isActive:true, isTrainerManual:false, isPublicManual:false, isPublicTrainerManual:false };

    constructor(props) {
        super(props);
        this.state = {
            allCenters: [],
            allTrainers: [],
            allUsers: [],
            allBrevets: [],
            unapprovedBrevets: [],
            allManuals: [],
            allManualTransactions: [],
            newUser: JSON.parse(JSON.stringify(userData)),
            selectedUser: JSON.parse(JSON.stringify(userData)),
            newCenter: JSON.parse(JSON.stringify(centerData)),
            selectedCenter: JSON.parse(JSON.stringify(centerData)),
            selectedManagerCenter: null,
            selectedTrainerCenter: null,
            selectedStudentCenter: null,
            newUserPassword: "",
            newUserPasswordRepeat: "",
            userImage: this.props.constants.placeholderImage,
            brevetTypes: {"OWD": {label: "OWD"}},
            brevetLevels: [{
                key: "SCUBA_DIVER",
                label: "Scuba Diver",
                requiredVoucher: "V100",
                enabled: true
            }],
            selectedBrevetLevel: {issuers: [], canIssue: [], enabled: false},
            selectedManual: this.newManual,
            createNewLevelStatus: false,
            createNewManualStatus: false,
            centerManagers: null,
            showCreateUserModal: false,
            selectedBrevetAddress: <div>no address available</div>
        };
        this.getAllCenters();
        this.getAllTrainers();
        this.getAllUsers();
        this.getAllBrevets();
        this.getAllManuals();
        this.getAllManualTransactions();
        this.getUnapprovedBrevets();
        this.getCenterManagers("1001");
        this.getBrevetAndVoucherTypes();
    }

    getBrevetAndVoucherTypes = () => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'admin/brevet/definitions',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (Admin.validate(res.data)) {
                        this.setState({
                            brevetTypes: res.data.brevetTypes,
                            voucherTypes: res.data.voucherTypes
                        });
                        var allBrevetTypes = [];
                        var singleType = "";
                        for (singleType in res.data.brevetTypes) {
                            allBrevetTypes.push({
                                key: singleType,
                                label: res.data.brevetTypes[singleType].label,
                                requiredVoucher: res.data.brevetTypes[singleType].requiredVoucher,
                                enabled: res.data.brevetTypes[singleType].enabled
                            })
                        }
                        this.setState({brevetLevels: allBrevetTypes});
                    } else {
                        console.log("fail")
                    }
                }
            )
    };

    getAllCenters = () => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'admin/centers',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    this.setState({
                        allCenters: res.data,
                        selectedManagerCenter: res.data[0],
                        selectedTrainerCenter: res.data[0],
                        selectedStudentCenter: res.data[0]
                    });
                    return res.data;
                }
            );
    };
    getAllTrainers = () => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'admin/trainers',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (Admin.validate(res.data)) {
                        this.setState({allTrainers: res.data});
                    } else {
                        console.log("fail")
                    }
                }
            );
    };
    getAllUsers = () => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'admin/users',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (Admin.validate(res.data)) {
                        this.setState({allUsers: res.data});
                    } else {
                        console.log("fail")
                    }
                }
            );
    };
    getAllBrevets = () => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'admin/brevets',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (Admin.validate(res.data)) {
                        this.setState({allBrevets: res.data});
                    } else {
                        console.log("fail")
                    }
                }
            );
    };
    getAllManuals = () => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'admin/manuals',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (Admin.validate(res.data)) {
                        this.setState({allManuals: res.data});
                    } else {
                        console.log("fail")
                    }
                }
            );
    };
    getAllManualTransactions = () => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'admin/manual-transactions',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (Admin.validate(res.data)) {
                        this.setState({
                            allManualTransactions: res.data
                        });
                    } else {
                        console.log("fail")
                    }
                }
            )
    };

    getUnapprovedBrevets = () => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'admin/unapprovedBrevets',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (Admin.validate(res.data)) {
                        this.setState({unapprovedBrevets: res.data});
                    } else {
                        console.log("fail")
                    }
                }
            );
    };
    getUser = (id) => {
        this.setState({userImage: this.props.constants.loadingImage});
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'admin/user/' + id,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (Admin.validate(res.data)) {
                        this.setState({selectedUser: res.data});
                        this.getUserImage();
                        this.setState({
                            selectedBrevetAddress:
                                <div className="row">
                                    <div className="col-12">
                                        <h3>Student Address:</h3>
                                        <div>{res.data.firstName} {res.data.lastName}</div>
                                        <div>{res.data.address.street}</div>
                                        <div>{res.data.address.zipCode} {res.data.address.city}</div>
                                        <div>{countryList().getLabel(res.data.address.country)}</div>
                                    </div>
                                </div>
                        });
                    } else {
                        console.log("fail")
                    }
                }
            );
    };
    getCenter = (id) => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'admin/center/' + id,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (Admin.validate(res.data)) {
                        this.setState({selectedCenter: res.data});
                        this.setState({
                            selectedBrevetAddress:
                                <div className="row">
                                    <div className="col-12">
                                        <h3>Center Address:</h3>
                                        <div>{res.data.name}</div>
                                        <div>{res.data.owner}</div>
                                        <div>{res.data.address.street}</div>
                                        <div>{res.data.address.zipCode} {res.data.address.city}</div>
                                        <div>{res.data.address.country}</div>
                                    </div>
                                </div>
                        });
                    } else {
                        console.log("fail")
                    }
                }
            );
    };

    handleUserFieldChange = (event, field, subfield, isNewUser, isCheckBox) => {
        if (isNewUser) {
            this.user = this.state.newUser;
        }
        else{
            this.user = this.state.selectedUser
        }

        if (!subfield) {
            if(isCheckBox) {
                this.user[field] = event.target.checked;
            }
            else {
                this.user[field] = event.target.value;
            }
        } else {
            this.user[field][subfield] = event.target.value;
        }

        if (isNewUser) {
            this.setState({newUser: this.user});
        }
        else {
            this.setState({selectedUser: this.user});
        }
    };
    saveNewUser = (user) => {
        if (user.password === user.passwordRepeat) {
            axios({
                method: 'post',
                url: this.props.constants.baseAPIURL + 'admin/user',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: user

            })
                .then((res) => {
                        alert("User has been created.");
                        this.setState({newUser: JSON.parse(JSON.stringify(userData))});
                        if (userImageBlobData !== null) {
                            this.uploadUserImage(res.data.id);
                        }
                        this.toggleCreateUserModal();
                        this.getAllUsers();
                    }
                )
                .catch(() => {
                        alert("Could not create user - please check user data!")
                    }
                )
        } else alert("Passwords don't match!")
    };
/*
    handleEditedUserFieldChange = (event, field, subfield) => {
        this.selectedUser = this.state.selectedUser;
        if (subfield === null) {
            this.selectedUser[field] = event.target.value;
        } else {
            this.selectedUser[field][subfield] = event.target.value;
        }
        this.setState({selectedUser: this.selectedUser});
    };
*/
    saveEditedUser = (user) => {
        axios({
            method: 'put',
            url: this.props.constants.baseAPIURL + 'admin/user/' + user.id,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
            data: this.state.selectedUser
        })
            .then(() => {
                    alert("Changes have been saved for user " + user.id + ".");
                    if (userImageBlobData !== null) {
                        this.uploadUserImage(user.id);
                    }
                    this.getAllUsers();
                }
            )
            .catch(() => {
                    alert("Could not save user - please check user data!")
                }
            )
    };

    handleSelectedFile = (event) => {
        loadImage( //for saving
            event.target.files[0],
            function (canvas) {
                canvas.toBlob(function (blob) {
                    userImageBlobData = blob
                });
            },
            {
                maxWidth: 600,
                maxHeight: 800,
                orientation: true,
                crop: true
            }
        );

        loadImage( //for showing on canvas
            event.target.files[0],
            function (canvas) {
                canvas.id = "user-image";

                var img1 = document.getElementById("user-image");
                var parentDiv = img1.parentNode;
                parentDiv.replaceChild(canvas, img1);
            },
            {
                maxWidth: 150,
                maxHeight: 200,
                orientation: true,
                crop: true
            }
        );
    };
    uploadUserImage = (userId) => {
        if (userImageBlobData) {

            var bodyFormData = new FormData();
            bodyFormData.set('file', userImageBlobData);

            axios({
                method: 'post',
                url: this.props.constants.baseAPIURL + 'admin/user/' + userId + '/pic',
                withCredentials: true,
                headers: {'Content-Type': 'multipart/form-data'},
                data: bodyFormData,
            })
                .then(() => {
                        this.setState({
                            userImage: null
                        });
                        this.setState({selectedUser: JSON.parse(JSON.stringify(userData))});
                        this.getUserImage();
                        userImageBlobData = null;
                    }
                )
        } else {
        }
    };
    getUserImage = () => {
        this.setState({userImage: this.props.constants.loadingImage});
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'admin/user/' + this.state.selectedUser.id + '/pic',
            withCredentials: true,
            responseType: "arraybuffer"
        })
            .then(res => {
                    res = new Buffer(res.data, 'binary').toString('base64');
                    this.setState({userImage: "data:image/png;base64," + res})
                }
            )
            .catch(() => {
                this.setState({userImage: this.props.constants.placeholderImage});
            })
    };
    clearUserImage = () => {
        this.setState({
            userImage: this.props.constants.placeholderImage
        });
        userImageBlobData = null;
    };

    getCenterManagers = (centerId) => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'center/' + centerId + '/managers',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (Admin.validate(res.data)) {
                        this.setState({centerManagers: res.data});
                    } else {
                        console.log("fail")
                    }
                }
            );
    };
    kickManager = (centerId, userId) => {
        axios({
            method: 'delete',
            url: this.props.constants.baseAPIURL + 'admin/center/' + centerId + '/manager/' + userId,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (Admin.validate(res.data)) {
                        this.getCenterManagers(centerId);
                    } else {
                        console.log("fail")
                    }
                }
            )
    };

    handleNewCenterFieldChange = (event, field, subfield) => {
        this.newCenter = this.state.newCenter;
        if (subfield === null) {
            this.newCenter[field] = event.target.value;
        } else {
            this.newCenter[field][subfield] = event.target.value;
        }
        this.setState({newCenter: this.newCenter});
    };
    handleNewCenterCheckbox = (event, field, create) => {
        if (create) {
            this.newCenter = this.state.newCenter;
            this.newCenter[field] = event.target.checked;
            this.setState({newCenter: this.newCenter});
        } else {
            this.selectedCenter = this.state.selectedCenter;
            this.selectedCenter[field] = event.target.checked;
            this.setState({selectedCenter: this.selectedCenter})
        }
    };
    handleNewCenterLevelChange = (event, create) => {
        if (create) {
            this.newCenter = this.state.newCenter;
            this.newCenter.level = event.target.value;
            this.setState({newCenter: this.newCenter})
        } else {
            this.selectedCenter = this.state.selectedCenter;
            this.selectedCenter.level = event.target.value;
            this.setState({selectedCenter: this.selectedCenter})
        }
    };

    saveNewCenter = (center) => {
        axios({
            method: 'post',
            url: this.props.constants.baseAPIURL + 'admin/center',
            withCredentials: true,
            headers: {'Content-Type': 'application/json'},
            data: center
        })
            .then(() => {
                    alert("Center has been created.");
                    this.setState({newCenter: JSON.parse(JSON.stringify(centerData))});
                    this.getAllCenters();
                }
            )
            .catch(() => {
                    alert("Could not create center - please check center data!")
                }
            );
    };
    handleEditedCenterFieldChange = (event, field, subfield) => {
        this.selectedCenter = this.state.selectedCenter;
        if (subfield === null) {
            this.selectedCenter[field] = event.target.value;
        } else {
            this.selectedCenter[field][subfield] = event.target.value;
        }
        this.setState({selectedCenter: this.selectedCenter});
    };
    saveEditedCenter = (center) => {
        axios({
            method: 'put',
            url: this.props.constants.baseAPIURL + 'admin/center/' + center.id,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
            data: this.state.selectedCenter
        })
            .then(() => {
                    alert("Changes have been saved for center " + center.id + ".");
                    this.setState({selectedCenter: JSON.parse(JSON.stringify(centerData))});
                    this.getAllCenters();
                }
            )
            .catch(() => {
                    alert("Could not save center - please check center data!")
                }
            );
    };

    handlePasswordFieldChange = (event, field) => {
        this.newUserPassword = event.target.value;
        if (field === "first") {
            this.setState({newUserPassword: this.newUserPassword});
        }
        if (field === "second") {
            this.setState({newUserPasswordRepeat: this.newUserPassword});
        }
    };
    saveNewPassword = (user, password, passwordRepeat) => {
        if (password === passwordRepeat) {
            axios({
                method: 'put',
                url: this.props.constants.baseAPIURL + 'admin/user/' + user.id,
                withCredentials: true,
                headers: {'Content-Type': 'application/json'},
                data: {password: password}
            })
                .then(() => {
                        alert("Password has been saved!")
                    }
                );
        } else alert("Passwords don't match!")
    };

    handleManagerCenterSelect = (event) => {
        this.setState({selectedManagerCenter: event.target.value});
    };
    saveUserAsManager = (user, center) => {
        axios({
            method: 'post',
            url: this.props.constants.baseAPIURL + 'admin/center/' + center + '/manager/' + user.id,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(() => {
                    alert("User saved as manager for center" + center + ".");
                    this.getAllCenters();
                    this.getAllUsers();
                }
            )
    };

    handleTrainerCenterSelect = (event) => {
        this.setState({selectedTrainerCenter: event.target.value});
    };
    saveUserAsTrainer = (user, center) => {
        axios({
            method: 'put',
            url: this.props.constants.baseAPIURL + 'admin/user/' + user.id,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                "trainerOfCenter": center,
                "isTeacher": true
            }
        })
            .then(() => {
                    alert("User saved as trainer for center" + center + ".");
                    this.getAllCenters();
                    this.getAllUsers();
                    this.getAllTrainers();
                }
            )
    };

    handleStudentCenterSelect = (event) => {
        this.setState({selectedStudentCenter: event.target.value});
    };
    saveUserAsStudent = (user, center) => {
        axios({
            method: 'put',
            url: this.props.constants.baseAPIURL + 'admin/user/' + user.id,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
            data: {"studentOfCenter": center}
        })
            .then(() => {
                    alert("User saved as student for center" + center + ".");
                    this.getAllCenters();
                    this.getAllUsers();
                    this.getAllTrainers();
                }
            )
    };

    saveUserAsAdmin = (user, willBeAdmin) => {
        axios({
            method: 'put',
            url: this.props.constants.baseAPIURL + 'admin/user/' + user,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
            data: {"isAdmin": willBeAdmin}
        })
            .then(() => {
                    if (willBeAdmin) {
                        alert("User " + user + " is now an admin.");
                    } else alert("User " + user + " is no longer an admin.")
                }
            )
    };

    getBrevetAddress = (brevet) => {
        if (brevet.sendTo === "CENTER") {
            this.getCenter(brevet.center.id);
        } else {
            this.getUser(brevet.student.id);
        }
    };
    saveBrevetAsSent = (brevet) => {
        var shipDate = new Date(Date.now());

        var newComments = "[SHIPPED: " + shipDate.toLocaleDateString() + "] " + brevet.comments;
        axios({
            method: 'put',
            url: this.props.constants.baseAPIURL + 'admin/brevet/' + brevet.id,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
            data: {comments: newComments}
        })
            .then(() => {
                    alert("Brevet has been marked as shipped.");
                    this.getAllBrevets();
                }
            )
            .catch(() => {
                    alert("Could not mark brevet as shipped.")
                }
            );
    };

    deleteUnapprovedBrevet = (brevet) => {
        axios({
            method: 'delete',
            url: this.props.constants.baseAPIURL + 'admin/brevet/' + brevet.id,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(() => {
                    alert("Unvalidated brevet has been deleted.");
                    this.getUnapprovedBrevets();
                }
            )
            .catch(() => {
                    alert("Could not delete brevet.")
                }
            );
    };

    getBrevetLevel = (id) => {
        this.setState({selectedBrevetLevel: {canIssue: [], issuers: [], enabled: false}});
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'admin/brevetLevel/' + id,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (Admin.validate(res.data)) {
                        this.setState({selectedBrevetLevel: res.data});
                    } else {
                        console.log("fail")
                    }
                }
            );
    };
    handleBreveLevelFieldChange = (event, field) => {
        this.selectedBrevetLevel = this.state.selectedBrevetLevel;
        this.selectedBrevetLevel[field] = event.target.value;
        this.setState({selectedBrevetLevel: this.selectedBrevetLevel});
    };
    handleBrevetLevelCheckbox = (event, field) => {
        this.selectedBrevetLevel = this.state.selectedBrevetLevel;
        this.selectedBrevetLevel[field] = event.target.checked;
        this.setState({selectedBrevetLevel: this.selectedBrevetLevel})
    };
    removeBrevetLevelDependency = (dependencyType, brevetType) => {
        var index;
        if (dependencyType === "issuers") {
            this.selectedBrevetLevel = this.state.selectedBrevetLevel;
            index = this.selectedBrevetLevel.issuers.indexOf(brevetType);
            this.selectedBrevetLevel.issuers.splice(index, 1);
            this.setState({selectedBrevetLevel: this.selectedBrevetLevel})
        }
        if (dependencyType === "canIssue") {
            this.selectedBrevetLevel = this.state.selectedBrevetLevel;
            index = this.selectedBrevetLevel.canIssue.indexOf(brevetType);
            this.selectedBrevetLevel.canIssue.splice(index, 1);
            this.setState({selectedBrevetLevel: this.selectedBrevetLevel})
        }
    };
    addBrevetLevelDependency = (isAddingIssuer, brevetType) => {
        if (isAddingIssuer) {
            this.selectedBrevetLevel = this.state.selectedBrevetLevel;
            if (!this.selectedBrevetLevel.issuers.includes(brevetType.key)) {
                this.selectedBrevetLevel.issuers.push(brevetType.key);
            }
            this.setState({selectedBrevetLevel: this.selectedBrevetLevel})
        }
        if (!isAddingIssuer) {
            this.selectedBrevetLevel = this.state.selectedBrevetLevel;
            if (!this.selectedBrevetLevel.canIssue.includes(brevetType.key)) {
                this.selectedBrevetLevel.canIssue.push(brevetType.key);
            }
            this.setState({selectedBrevetLevel: this.selectedBrevetLevel})
        }
    };
    prepareNewLevel = () => {
        this.setState({
            createNewLevelStatus: true,
            selectedBrevetLevel: {issuers: [], canIssue: [], enabled: false}
        })
    };
    saveEditedBrevetLevel = () => {
        var method;
        var url;

        if (this.state.createNewLevelStatus) {
            method = 'post';
            url = this.props.constants.baseAPIURL + 'admin/brevetLevel/';
        } else {
            method = 'put';
            url = this.props.constants.baseAPIURL + 'admin/brevetLevel/' + this.state.selectedBrevetLevel.id;
        }
        axios({
            method: method,
            url: url,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
            data: this.state.selectedBrevetLevel
        })
            .then(() => {
                    alert("Brevet level has been saved.");
                    this.setState({
                        createNewLevelStatus: false,
                        selectedBrevetLevel: {issuers: [], canIssue: [], enabled: false}
                    });
                    this.getBrevetAndVoucherTypes();
                }
            )
            .catch(() => {
                    alert("Could not save brevet level - please check your data!")
                }
            );
    };

    getManual = (id) => {
        this.setState({selectedManual: this.newManual});
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'admin/manuals/' + id,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (Admin.validate(res.data)) {
                        this.setState({selectedManual: res.data});
                    } else {
                        console.log("fail")
                    }
                }
            );
    };
    handleManualFieldChange = (event, field) => {
        this.selectedManual = this.state.selectedManual;

        if (field === "urls") {
            this.selectedManual[field][0] = event.target.value;
        } else {
            this.selectedManual[field] = event.target.value;
        }
        this.setState({selectedManual: this.selectedManual});
    };
    handleManualCheckbox = (event, field) => {
            this.selectedManual = this.state.selectedManual;
            this.selectedManual[field] = event.target.checked;
            this.setState({selectedManual: this.selectedManual});
    };
    prepareNewManual = () => {
        this.setState({
            createNewManualStatus: true,
            selectedManual: this.newManual
        })
    };
    saveManual = () => {
        var method;
        var url;

        if (this.state.createNewManualStatus) {
            method = 'post';
            url = this.props.constants.baseAPIURL + 'admin/manuals/';
        } else {
            method = 'put';
            url = this.props.constants.baseAPIURL + 'admin/manuals/' + this.state.selectedManual.id;
        }
        axios({
            method: method,
            url: url,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
            data: this.state.selectedManual
        })
            .then(() => {
                    alert("Manual has been saved.");
                    this.setState({
                        createNewManualStatus: false,
                        selectedManual: this.newManual
                    });
                    this.getAllManuals();
                }
            )
            .catch(() => {
                    alert("Could not save manual - please check your data!")
                }
            );
    };
    getManualSource = (url, save) => {
        axios({
            method: 'get',
            url: url,
            withCredentials: false,
            headers: {
                'Content-Type': 'text/html'
            }
        })
            .then(res => {
                    if (Admin.validate(res.data)) {
                        this.selectedManual = this.state.selectedManual;
                        this.selectedManual.sources =[res.data];
                        this.setState({selectedManual: this.selectedManual});
                        if (save) {
                            this.saveManual();
                        }
                    } else {
                        console.log("fail")
                    }
                }
            );
    };

    saveTransaction = (id, comment, invoicedAt, paidAt, isDeleted) => {
        axios({
            method: 'put',
            url: this.props.constants.baseAPIURL + 'admin/manual-transactions/' + id,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                "adminComment": comment,
                "invoicedAt": invoicedAt,
                "paidAt": paidAt,
                "isDeleted": isDeleted
            }
        })
            .then(() => {
                    alert("Transaction has been saved.");
                    this.getAllManualTransactions();
                }
            )
            .catch(() => {
                    alert("Could not save transaction - please check your data!")
                }
            );
    };

    toggleCreateUserModal = () => {
        this.setState({showCreateUserModal: !this.state.showCreateUserModal});
    };

    getBrevetBacksideLink = (brevetID) => {
        if(brevetID) {
            var url = this.props.constants.baseFrontURL + "backsides/" + brevetID + ".pdf";
            var http = new XMLHttpRequest();
            http.open('HEAD', url, false);
            http.send();
            if (http.status === 200) {
                return <div><a className="link" href={url} target="_blank" rel="noopener noreferrer">See current back
                    side
                    here</a></div>;
            } else {
                return <div style={{color: "#ff0000"}}>No individual back side provided.</div>;
            }
        }
    }

    static validate() {
        return true;
    }

    render() {
        return (
            <div className="row">
                <div className="col-12 main-container">
                    <Route exact path='/admin/general' render={(props) =>
                        <AdminGeneralView {...props}
                                          saveUserAsAdmin={this.saveUserAsAdmin}/>}/>
                    <Route exact path='/admin/centers' render={(props) =>
                        <AdminCentersView {...props}
                                          centers={this.state.allCenters}
                                          newCenter={this.state.newCenter}
                                          selectedCenter={this.state.selectedCenter}
                                          getCenter={this.getCenter}
                                          handleNewCenterFieldChange={this.handleNewCenterFieldChange}
                                          saveNewCenter={this.saveNewCenter}
                                          handleEditedCenterFieldChange={this.handleEditedCenterFieldChange}
                                          saveEditedCenter={this.saveEditedCenter}
                                          handleNewCenterCheckbox={this.handleNewCenterCheckbox}
                                          getCenterManagers={this.getCenterManagers}
                                          centerManagers={this.state.centerManagers}
                                          handleNewCenterLevelChange={this.handleNewCenterLevelChange}
                                          kickManager={this.kickManager}
                                          constants={this.props.constants}/>}/>
                    <Route exact path='/admin/students' render={(props) =>
                        <div>
                            <AdminUsersView {...props}
                                            users={this.state.allUsers}
                                            newUser={this.state.newUser}
                                            selectedUser={this.state.selectedUser}
                                            getUser={this.getUser}
                                            handleUserFieldChange={this.handleUserFieldChange}
                                            saveNewUser={this.saveNewUser}
                                            saveEditedUser={this.saveEditedUser}
                                            newUserPassword={this.state.newUserPassword}
                                            newUserPasswordRepeat={this.state.newUserPasswordRepeat}
                                            handlePasswordFieldChange={this.handlePasswordFieldChange}
                                            saveNewPassword={this.saveNewPassword}
                                            allCenters={this.state.allCenters}
                                            selectedManagerCenter={this.state.selectedManagerCenter}
                                            handleManagerCenterSelect={this.handleManagerCenterSelect}
                                            saveUserAsManager={this.saveUserAsManager}
                                            selectedStudentCenter={this.state.selectedStudentCenter}
                                            handleStudentCenterSelect={this.handleStudentCenterSelect}
                                            saveUserAsStudent={this.saveUserAsStudent}
                                            selectedTrainerCenter={this.state.selectedTrainerCenter}
                                            handleTrainerCenterSelect={this.handleTrainerCenterSelect}
                                            saveUserAsTrainer={this.saveUserAsTrainer}
                                            userImage={this.state.userImage}
                                            uploadUserImage={this.uploadUserImage}
                                            getUserImage={this.getUserImage}
                                            handleSelectedFile={this.handleSelectedFile}
                                            clearUserImage={this.clearUserImage}
                                            showCreateUserModal={this.state.showCreateUserModal}
                                            toggleCreateUserModal={this.toggleCreateUserModal}/>
                        </div>}/>
                    <Route exact path='/admin/brevets' render={(props) =>
                        <AdminBrevetsView {...props}
                                          brevets={this.state.allBrevets}
                                          unapprovedBrevets={this.state.unapprovedBrevets}
                                          brevetTypes={this.state.brevetTypes}
                                          constants={this.props.constants}
                                          getBrevetAddress={this.getBrevetAddress}
                                          selectedBrevetAddress={this.state.selectedBrevetAddress}
                                          saveBrevetAsSent={this.saveBrevetAsSent}
                                          deleteUnapprovedBrevet={this.deleteUnapprovedBrevet}/>}/>
                    <Route exact path='/admin/brevet-levels' render={(props) =>
                        <AdminBrevetLevelsView {...props}
                                               brevetLevels={this.state.brevetLevels}
                                               brevetTypes={this.state.brevetTypes}
                                               constants={this.props.constants}
                                               getBrevetLevel={this.getBrevetLevel}
                                               selectedBrevetLevel={this.state.selectedBrevetLevel}
                                               selectedBrevetLevelBackside={this.getBrevetBacksideLink(this.state.selectedBrevetLevel.id)}
                                               handleBreveLevelFieldChange={this.handleBreveLevelFieldChange}
                                               saveEditedBrevetLevel={this.saveEditedBrevetLevel}
                                               handleBrevetLevelCheckbox={this.handleBrevetLevelCheckbox}
                                               removeBrevetLevelDependency={this.removeBrevetLevelDependency}
                                               addBrevetLevelDependency={this.addBrevetLevelDependency}
                                               prepareNewLevel={this.prepareNewLevel}/>}/>
                    <Route exact path='/admin/manuals' render={(props) =>
                        <AdminManualsView {...props}
                                          allManuals={this.state.allManuals}
                                          constants={this.props.constants}
                                          selectedManual={this.state.selectedManual}
                                          getManual={this.getManual}
                                          prepareNewManual={this.prepareNewManual}
                                          handleManualFieldChange={this.handleManualFieldChange}
                                          handleManualCheckbox={this.handleManualCheckbox}
                                          getManualSource={this.getManualSource}/>}/>
                    <Route exact path='/admin/manual-sales' render={(props) =>
                        <AdminManualTransactionsView {...props}
                                                     constants={this.props.constants}
                                                     allManualTransactions={this.state.allManualTransactions}
                                                     saveTransaction={this.saveTransaction}/>}/>
                </div>
            </div>
        )
    }
}