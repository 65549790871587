import React from 'react';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import MaterialTable from "material-table";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/es/MenuItem";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Moment from 'react-moment';
import {BrevetDetailView} from "../brevet/BrevetView";
import countryList from 'react-select-country-list';

import DeleteIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

const theme = createMuiTheme({
    overrides: {
        MuiCard: { // Name of the component ⚛️ / style sheet
            root: {
                margin: "20px auto 20px auto",
                width: "97%"
            }
        }
    }
});
var userFieldsToRender = [
    {"label": "User Name (for login)", "key": "userName"},
    {"label": "E-Mail", "key": "email"},
    {"label": "First Name", "key": "firstName"},
    {"label": "Last Name", "key": "lastName"},
    {"label": "Phone Number", "key": "phoneNumber"},
    {"label": "Street & Number", "key": "address", "subkey": "street"},
    {"label": "ZIP", "key": "address", "subkey": "zipCode"},
    {"label": "City", "key": "address", "subkey": "city"},
    {"label": "Country", "key": "address", "subkey": "country"},
    {"label": "Don't send post", "key": "noMail"},
    {"label": "Birth Date", "key": "birthDate"},
    {"label": "Alias ID", "key": "aliasId"},
    {"label": "Correspondence language", "key": "correspondenceLanguage"},
    {"label": "Trainer fee paid until (year)", "key": "feePaidUntil"},
    {"label": "Active Trainer", "key": "isActiveTrainer"},
    {"label": "Admin Comment", "key": "adminComment"}];
var centerFieldsToRender = [
    {"label": "Name", "key": "name"},
    {"label": "Owner", "key": "owner"},
    {"label": "Phone", "key": "phoneNumber"},
    {"label": "E-Mail", "key": "email"},
    {"label": "Street & Number", "key": "address", "subkey": "street"},
    {"label": "Add. Info", "key": "address", "subkey": "additional"},
    {"label": "ZIP", "key": "address", "subkey": "zipCode"},
    {"label": "City", "key": "address", "subkey": "city"},
    {"label": "State", "key": "address", "subkey": "state"},
    {"label": "Country", "key": "address", "subkey": "country"},
    {"label": "Center fee paid until (year)", "key": "feePaidUntil"},
    {"label": "Admin Comment", "key": "adminComment"}];
var specialtiesToToRender = [
    {"label": "Apnoe", "key": "specialityApnoe"},
    {"label": "Handicap", "key": "specialityHandicap"},
    {"label": "IDC", "key": "specialityIdc"},
    {"label": "Tec", "key": "specialityTec"},
    {"label": "Active Center", "key": "isActiveCenter"},
    {"label": "Has Printer", "key": "hasPrinter"}];
var levelsToRender = [
    {"label": "Dive School", "key": "DIVE_SCHOOL"},
    {"label": "Dive Center", "key": "DIVE_CENTER"},
    {"label": "Premium Center", "key": "PREMIUM_CENTER"},
    {"label": "Supreme Center", "key": "SUPREME_CENTER"},
    {"label": "Freelancer", "key": "FREELANCER"}];
var countrySelectOptions = countryList().getData();
var voucherTypes = ["V100", "V200", "V210", "V212", "V220",
    "V300", "V310", "V320", "V450", "V490",
    "V510", "V530", "V550", "V600", "V610", "V620", "V630", "V700"];
var yearSelectOptions = [0, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];

export class AdminGeneralView extends React.Component {
    render() {
        return (
            <div>
                <div className="card-header">
                    <h1>General Settings</h1>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            You can choose a user by ID to give or take the admin role.
                        </div>
                        <div className="col-12">
                            <TextField
                                id="userIdChooser"
                                label="User ID"
                                margin="dense"/>
                        </div>
                        <div className="col-12 padding-top-15">
                            <Button variant="contained" color="primary"
                                    onClick={() => {
                                        this.props.saveUserAsAdmin(document.getElementById("userIdChooser").value, true)
                                    }}>Give admin role to user</Button>
                        </div>
                        <div className="col-12 padding-top-15">
                            <Button variant="contained" color="primary"
                                    onClick={() => {
                                        this.props.saveUserAsAdmin(document.getElementById("userIdChooser").value, false)
                                    }}>Take admin role from user</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export class AdminCentersView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreateCenterModal: false,
            showCenterProfileModal: false,
            showCenterEditModal: false,
            showCenterManagersModal: false,
            selectedCenter: {}
        }
    }

    render() {
        return (
            <div>
                <div className="card-header">
                    <h1>All Centers</h1>
                </div>
                <div className="card-body">
                    <div>
                        <div className="row">
                            <div className="col-12">
                                <MaterialTable columns={[
                                    {title: 'ID', field: 'id', defaultSort: "desc"},
                                    {title: 'Name', field: 'name'},
                                    {title: 'Country', field: 'country'},
                                    {title: 'Owner', field: 'owner'},
                                    {title: 'E-mail', field: 'email'},
                                    {title: 'Center fee until', field: 'feePaidUntil'},
                                    {title: 'Is active center', field: 'isActiveCenter'},
                                    {
                                        title: 'Admin Comment', field: 'adminComment', render: rowData => {
                                            if (rowData.adminComment) {
                                                return (
                                                    <div>
                                                        {rowData.adminComment.slice(0, 15)}...
                                                    </div>)
                                            } else return (<div></div>)
                                        }

                                    }]}
                                               data={this.props.centers}
                                               options={{
                                                   search: true,
                                                   paging: true,
                                                   pageSize: 10,
                                                   actionsColumnIndex: 0,
                                                   searchFieldAlignment: "left",
                                                   showTitle: false,
                                                   exportButton: true,
                                                   exportAllData: true,
                                                   exportFileName: "all_centers",
                                                   rowStyle: rowData => ({
                                                       backgroundColor: (rowData.isActiveCenter) ? '#e0ffe0' : '#ffe0e0'
                                                   })
                                               }}
                                               actions={[
                                                   {
                                                       icon: 'edit',
                                                       tooltip: 'Edit center',
                                                       onClick: (event, rowData) => {
                                                           this.props.getCenter(rowData.id);
                                                           this.setState({
                                                               showCenterEditModal: !this.state.showCenterEditModal,
                                                               selectedCenter: rowData
                                                           })
                                                       }
                                                   },
                                                   {
                                                       icon: 'euro_symbol',
                                                       tooltip: 'Transfer codes',
                                                       onClick: (event, rowData) => {
                                                           alert('Transfer codes to ' + rowData.name)
                                                       },

                                                   },
                                                   {
                                                       icon: 'business_center',
                                                       tooltip: 'Show Managers',
                                                       onClick: (event, rowData) => {
                                                           this.props.getCenterManagers(rowData.id);
                                                           this.setState({
                                                               showCenterManagersModal: true,
                                                               selectedCenter: rowData
                                                           })
                                                       },
                                                   },
                                                   {
                                                       icon: 'add_circle',
                                                       tooltip: 'Add center',
                                                       onClick: () => {
                                                           this.setState({showCreateCenterModal: true})
                                                       },
                                                       isFreeAction: true
                                                   }]}
                                               localization={{header: {actions: "Options"}}}/>
                            </div>
                        </div>
                    </div>

                    <Modal isOpen={this.state.showCenterEditModal}>
                        <ModalBody>
                            <AdminCenterEditModal center={this.props.selectedCenter}
                                                  handleCenterFieldChange={this.props.handleEditedCenterFieldChange}
                                                  handleNewCenterCheckbox={this.props.handleNewCenterCheckbox}
                                                  handleNewCenterLevelChange={this.props.handleNewCenterLevelChange}
                                                  saveCenter={this.props.saveEditedCenter}/>

                        </ModalBody>
                        <ModalFooter>
                            <Button variant="contained" color="primary"
                                    onClick={() => {
                                        this.props.saveEditedCenter(this.props.selectedCenter);
                                        this.setState({showCenterEditModal: false})
                                    }}>Save
                            </Button>
                            <Button variant="contained" color="primary"
                                    onClick={() => this.setState({showCenterEditModal: false})}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.showCreateCenterModal}>
                        <ModalBody>
                            <AdminCenterCreateModal constants={this.props.constants}
                                                    newCenter={this.props.newCenter}
                                                    handleNewCenterFieldChange={this.props.handleNewCenterFieldChange}
                                                    handleNewCenterCheckbox={this.props.handleNewCenterCheckbox}
                                                    handleNewCenterLevelChange={this.props.handleNewCenterLevelChange}/>

                        </ModalBody>
                        <ModalFooter>
                            <Button variant="contained" color="primary"
                                    onClick={() => {
                                        this.props.saveNewCenter(this.props.newCenter);
                                        this.setState({showCreateCenterModal: false})
                                    }}>Save
                            </Button>
                            <Button variant="contained" color="primary"
                                    onClick={() => this.setState({showCreateCenterModal: false})}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.showCenterManagersModal}>
                        <ModalBody>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-10">
                                        <h2>Center Managers</h2>
                                    </div>
                                    <hr/>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <MaterialTable columns={[
                                            {title: 'ID', field: 'id'},
                                            {title: 'First', field: 'firstName'},
                                            {title: 'Last', field: 'lastName'}]}
                                                       data={this.props.centerManagers}
                                                       title="Center Managers"
                                                       options={{
                                                           filtering: false,
                                                           search: true,
                                                           toolbar: false,
                                                           actionsColumnIndex: 0
                                                       }}
                                                       actions={[
                                                           {
                                                               icon: 'cancel',
                                                               tooltip: 'Remove manager role',
                                                               onClick: (event, rowData) => {
                                                                   this.props.kickManager(this.state.selectedCenter.id, rowData.id);
                                                               }
                                                           }
                                                       ]}
                                                       localization={{header: {actions: "Options"}}}/>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="contained" color="primary"
                                    onClick={() => this.setState({showCenterManagersModal: false})}>Cancel</Button>
                        </ModalFooter>
                    </Modal>

                </div>
            </div>
        )
    }
}

export class AdminCenterCreateModal extends React.Component {
    render() {
        return (
            <div>
                <div className="card-header">
                    <h2>Create Center</h2>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <div className="row">
                                        {centerFieldsToRender.map((field) => {
                                            if ((!field.subkey)) {
                                                var fieldRows = 1;
                                                var fieldCols = "col-md-6 col-xs-12";
                                                var fieldType = 'text';
                                                if (field.key === "adminComment") {
                                                    fieldRows = 4;
                                                    fieldCols = "col-md-12 col-xs-12"
                                                }
                                                if (field.key === "feePaidUntil") {
                                                    return (
                                                        <div key={field.label} className={fieldCols}>
                                                            <InputLabel shrink htmlFor="feePaidUntil">{field.label}</InputLabel>
                                                            <Select
                                                                id={field.label}
                                                                label={field.label}
                                                                fullWidth
                                                                value={this.props.newCenter[field.key]}
                                                                margin="dense"
                                                                onChange={(e) => this.props.handleNewCenterFieldChange(e, field.key, null)}
                                                            >
                                                                {yearSelectOptions.map((year) => {
                                                                    return (
                                                                        <MenuItem key={year}
                                                                                  value={year}>{year}</MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </div>)
                                                }
                                                else return (
                                                    <div key={field.label} className={fieldCols}>
                                                        <TextField
                                                            id={field.label}
                                                            label={field.label}
                                                            type={fieldType}
                                                            fullWidth
                                                            multiline
                                                            rows={fieldRows}
                                                            value={this.props.newCenter[field.key]}
                                                            margin="dense"
                                                            onChange={(e) => this.props.handleNewCenterFieldChange(e, field.key, null)}
                                                        />
                                                    </div>)
                                            } else {
                                                if (field.subkey !== "country") {
                                                    return (
                                                        <div key={field.label} className="col-md-6 col-xs-12">
                                                            <TextField
                                                                id={field.label}
                                                                label={field.label}
                                                                fullWidth
                                                                value={this.props.newCenter[field.key][field.subkey]}
                                                                margin="dense"
                                                                onChange={(e) => this.props.handleNewCenterFieldChange(e, field.key, field.subkey)}
                                                            />
                                                        </div>)
                                                } else return (
                                                    <div key={field.label} className="col-md-6 col-xs-12">
                                                        <InputLabel shrink htmlFor="country">Country</InputLabel>
                                                        <Select
                                                            id={field.label}
                                                            label={field.label}
                                                            fullWidth
                                                            value={this.props.newCenter[field.key][field.subkey]}
                                                            margin="dense"
                                                            onChange={(e) => this.props.handleNewCenterFieldChange(e, field.key, field.subkey)}
                                                        >
                                                            {countrySelectOptions.map((country) => {
                                                                return (
                                                                    <MenuItem
                                                                        value={country.value}>{country.label}</MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                    </div>)
                                            }
                                        })}
                                        <div className="col-md-12 col-xs-12 padding-15">
                                            <Select
                                                value={this.props.newCenter.level}
                                                fullwidth
                                                onChange={(e) => this.props.handleNewCenterLevelChange(e, true)}
                                                name="centerLevelSelect">
                                                {levelsToRender.map((level) => {
                                                    return (
                                                        <MenuItem key={level.key}
                                                                  value={level.key}>{level.label}</MenuItem>)
                                                })}
                                            </Select>
                                        </div>
                                        {specialtiesToToRender.map((field) => {
                                            return (
                                                <div className="col-md-3 col-sm-6 padding-15">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={this.props.newCenter[field.key]}
                                                                onChange={(e) => this.props.handleNewCenterCheckbox(e, field.key, true)}
                                                                value={this.props.newCenter[field.key]}
                                                                color="primary"
                                                            />}
                                                        label={field.label}
                                                    />
                                                </div>)
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export class AdminCenterEditModal extends React.Component {

    render() {
        return (
            <div>
                <div className="card-header">
                    <h1>Centerprofil</h1>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Of {this.props.center.name}</h2>
                            {this.props.center.id}
                            <hr/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                {
                                    centerFieldsToRender.map((field) => {
                                        var fieldRows = 1;
                                        var fieldCols = "col-md-6 col-xs-12";
                                        var fieldType = 'text';
                                        if (field.key === "adminComment") {
                                            fieldRows = 4;
                                            fieldCols = "col-md-12 col-xs-12"
                                        }
                                        if (field.key === "feePaidUntil") {
                                            return (
                                                <div key={field.label} className={fieldCols}>
                                                    <InputLabel shrink htmlFor="feePaidUntil">{field.label}</InputLabel>
                                                    <Select
                                                        id={field.label}
                                                        label={field.label}
                                                        fullWidth
                                                        value={this.props.center[field.key]}
                                                        margin="dense"
                                                        onChange={(e) => this.props.handleCenterFieldChange(e, field.key, null)}
                                                    >
                                                        {yearSelectOptions.map((year) => {
                                                            return (
                                                                <MenuItem key={year}
                                                                          value={year}>{year}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>)
                                        }
                                        else if ((!field.subkey)) {
                                            return (
                                                <div key={field.label} className={fieldCols}>
                                                    <TextField
                                                        id={field.label}
                                                        label={field.label}
                                                        fullWidth
                                                        multiline
                                                        type={fieldType}
                                                        rows={fieldRows}
                                                        value={this.props.center[field.key]}
                                                        margin="dense"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={(e) => this.props.handleCenterFieldChange(e, field.key, null)}
                                                    />
                                                </div>)
                                        }
                                        else if (field.key === "feePaidUntil") {
                                            return (
                                                <div key={field.label} className={fieldCols}>
                                                    <InputLabel shrink htmlFor="feePaidUntil">{field.label}</InputLabel>
                                                    <Select
                                                        id={field.label}
                                                        label={field.label}
                                                        fullWidth
                                                        value={this.props.user[field.key]}
                                                        margin="dense"
                                                        onChange={(e) => this.props.handleUserFieldChange(e, field.key, null, false, false)}
                                                    >
                                                        {yearSelectOptions.map((year) => {
                                                            return (
                                                                <MenuItem key={year}
                                                                          value={year}>{year}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>)
                                        }else {
                                            if (field.subkey !== "country") {
                                                return (
                                                    <div key={field.label} className="col-md-6 col-xs-12">
                                                        <TextField
                                                            id={field.label}
                                                            label={field.label}
                                                            fullWidth
                                                            value={this.props.center[field.key][field.subkey]}
                                                            margin="dense"
                                                            onChange={(e) => this.props.handleCenterFieldChange(e, field.key, field.subkey)}
                                                        />
                                                    </div>)
                                            } else return (
                                                <div key={field.label} className="col-md-6 col-xs-12">
                                                    <InputLabel shrink htmlFor="country">Country</InputLabel>
                                                    <Select
                                                        id={field.label}
                                                        label={field.label}
                                                        fullWidth
                                                        value={this.props.center[field.key][field.subkey]}
                                                        margin="dense"
                                                        onChange={(e) => this.props.handleCenterFieldChange(e, field.key, field.subkey)}
                                                    >
                                                        {countrySelectOptions.map((country) => {
                                                            return (
                                                                <MenuItem
                                                                    value={country.value}>{country.label}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>)
                                        }
                                    })}
                                <div className="col-md-12 col-xs-12 padding-15">
                                    <Select
                                        value={this.props.center.level}
                                        fullwidth
                                        onChange={(e) => this.props.handleNewCenterLevelChange(e, false)}
                                        name="centerLevelSelect">
                                        {levelsToRender.map((level) => {
                                            return (
                                                <MenuItem key={level.key}
                                                          value={level.key}>{level.label}</MenuItem>)
                                        })}
                                    </Select>
                                </div>
                                {specialtiesToToRender.map((field) => {
                                    return (
                                        <div className="col-md-3 col-sm-6 padding-15">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.props.center[field.key]}
                                                        onChange={(e) => this.props.handleNewCenterCheckbox(e, field.key, false)}
                                                        value={this.props.center[field.key]}
                                                        color="primary"
                                                    />}
                                                label={field.label}
                                            />
                                        </div>)
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export class AdminUsersView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showUserEditModal: false,
            showManagerModal: false,
            showTrainerModal: false,
            showStudentModal: false,
            showPasswordModal: false
        }
    }

    render() {
        return (
            <div>
                <div className="card-header">
                    <h1>All Users</h1>
                </div>
                <div className="card-body">
                    <div>
                        <div className="row">
                            <div className="col-12">
                                <MaterialTable columns={[
                                    {title: 'ID', field: 'id', defaultSort: "desc"},
                                    {title: 'First', field: 'firstName'},
                                    {title: 'Last', field: 'lastName'},
                                    {title: 'E-mail', field: 'email'},
                                    {
                                        title: 'Country', field: 'country', render: rowData => {
                                            if (!rowData.country) {
                                                return "unknown"
                                            } else return countryList().getLabel(rowData.country);
                                        }
                                    },
                                    {
                                        title: 'HQ Admin', field: 'isAdmin', render: rowData =>
                                            <div>
                                                {rowData.isAdmin ? "HQ" : ""}
                                            </div>
                                    },
                                    {
                                        title: 'Admin Comment', field: 'adminComment', render: rowData => {
                                            if (rowData.adminComment) {
                                                return (
                                                    <div>
                                                        {rowData.adminComment.slice(0, 15)}...
                                                    </div>)
                                            } else return (<div></div>)
                                        }

                                    },
                                    {title: 'Student of', field: 'studentOfCenter.name'},
                                    {title: 'Trainer of', field: 'trainerOfCenter.name'},
                                    {title: 'Trainer fee until', field: 'feePaidUntil'},
                                    {title: 'Is active trainer', field: 'isActiveTrainer'},
                                    {title: "Correspondence language", field: "correspondenceLanguage"},
                                    {title: 'Do not send mail', field: 'noMail'},]}
                                               data={this.props.users}
                                               options={{
                                                   search: true,
                                                   paging: true,
                                                   pageSize: 10,
                                                   actionsColumnIndex: 0,
                                                   searchFieldAlignment: "left",
                                                   showTitle: false,
                                                   exportButton: true,
                                                   exportAllData: true,
                                                   exportFileName: "all_users",
                                               }}
                                               actions={[
                                                   {
                                                       icon: 'edit',
                                                       tooltip: 'Edit user',
                                                       onClick: (event, rowData) => {
                                                           this.props.getUser(rowData.id);
                                                           this.setState({
                                                               showUserEditModal: !this.state.showUserEditModal,
                                                           })
                                                       }
                                                   },
                                                   {
                                                       icon: 'vpn_key',
                                                       tooltip: 'Set password',
                                                       onClick: (event, rowData) => {
                                                           this.props.getUser(rowData.id);
                                                           this.setState({
                                                               showPasswordModal: !this.state.showPasswordModal,
                                                           })
                                                       }
                                                   },
                                                   {
                                                       icon: 'face',
                                                       tooltip: 'Assign as student to center',
                                                       onClick: (event, rowData) => {
                                                           this.props.getUser(rowData.id);
                                                           this.setState({
                                                               showStudentModal: !this.state.showStudentModal,
                                                           })
                                                       }
                                                   },
                                                   {
                                                       icon: 'business_center',
                                                       tooltip: 'Promote to center admin',
                                                       onClick: (event, rowData) => {
                                                           this.props.getUser(rowData.id);
                                                           this.setState({
                                                               showManagerModal: !this.state.showManagerModal,
                                                           })
                                                       }
                                                   },
                                                   {
                                                       icon: 'school',
                                                       tooltip: 'Promote to center trainer',
                                                       onClick: (event, rowData) => {
                                                           this.props.getUser(rowData.id);
                                                           this.setState({
                                                               showTrainerModal: !this.state.showTrainerModal,
                                                           })
                                                       }
                                                   },
                                                   {
                                                       icon: 'add_circle',
                                                       tooltip: 'Add User',
                                                       onClick: () => {
                                                           this.props.toggleCreateUserModal()
                                                       },
                                                       isFreeAction: true
                                                   }]}
                                               localization={{header: {actions: "Options"}}}/>
                            </div>
                        </div>
                    </div>

                    <Modal isOpen={this.state.showUserEditModal}>
                        <ModalBody>
                            <AdminUserEditModal user={this.props.selectedUser}
                                                handleSelectedFile={this.props.handleSelectedFile}
                                                userImage={this.props.userImage}
                                                uploadUserImage={this.props.uploadUserImage}
                                                handleUserFieldChange={this.props.handleUserFieldChange}
                                                saveUser={this.props.saveEditedUser}/>

                        </ModalBody>
                        <ModalFooter>
                            <Button variant="contained" color="primary"
                                    onClick={() => window.location.href = "mailto:" + this.props.selectedUser.email}>Send
                                E-Mail</Button>
                            <Button variant="contained" color="primary"
                                    onClick={() => {
                                        this.props.saveEditedUser(this.props.selectedUser);
                                        this.setState({showUserEditModal: false})
                                    }}>Save
                            </Button>
                            <Button variant="contained" color="primary"
                                    onClick={() => {
                                        this.props.clearUserImage();
                                        this.setState({showUserEditModal: false});
                                    }}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.showPasswordModal}>
                        <ModalBody>
                            <TextField
                                id="password"
                                label="Password"
                                type="password"
                                fullWidth
                                value={this.props.newUserPassword}
                                margin="dense"
                                onChange={(e) => {
                                    this.props.handlePasswordFieldChange(e, "first");
                                }}
                            />
                            <TextField
                                id="password"
                                label="Password"
                                type="password"
                                fullWidth
                                value={this.props.newUserPasswordRepeat}
                                margin="dense"
                                onChange={(e) => {
                                    this.props.handlePasswordFieldChange(e, "second");
                                }}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="contained" color="primary"
                                    onClick={() => {
                                        this.props.saveNewPassword(this.props.selectedUser, this.props.newUserPassword, this.props.newUserPasswordRepeat);
                                        this.setState({showPasswordModal: false});
                                    }}>Save
                            </Button>
                            <Button variant="contained" color="primary"
                                    onClick={() => this.setState({showPasswordModal: false})}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.props.showCreateUserModal}>
                        <ModalBody>
                            <AdminUserCreateModal constants={this.props.constants}
                                                  newUser={this.props.newUser}
                                                  handleUserFieldChange={this.props.handleUserFieldChange}
                                                  handleSelectedFile={this.props.handleSelectedFile}
                                                  userImage={this.props.userImage}
                                                  uploadUserImage={this.props.uploadUserImage}/>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="contained" color="primary"
                                    onClick={() => {
                                        this.props.saveNewUser(this.props.newUser);
                                    }}>Save
                            </Button>
                            <Button variant="contained" color="primary"
                                    onClick={() => this.props.toggleCreateUserModal()}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.showStudentModal}>
                        <ModalHeader>
                            <h3>Assign {this.props.selectedUser.firstName} {this.props.selectedUser.lastName} as student
                                to center</h3>
                        </ModalHeader>
                        <ModalBody>
                            <div>
                                <div className="row">
                                    <div className="col-12">
                                        <MuiThemeProvider theme={theme}>
                                            <MaterialTable columns={[
                                                {title: 'ID', field: 'id'},
                                                {title: 'Name', field: 'name'}]}
                                                           data={this.props.allCenters}
                                                           title="Select Center"
                                                           options={{
                                                               showTitle: false,
                                                               search: true,
                                                               actionsColumnIndex: -1,
                                                               searchFieldAlignment: "left"
                                                           }}
                                                           actions={[
                                                               {
                                                                   icon: 'check_circle_outline',
                                                                   tooltip: 'Select Center',
                                                                   onClick: (event, rowData) => {
                                                                       this.props.saveUserAsStudent(this.props.selectedUser, rowData.id);
                                                                       this.setState({showStudentModal: false});
                                                                   }
                                                               }
                                                           ]}
                                                           localization={{header: {actions: "Options"}}}/>
                                        </MuiThemeProvider>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="contained" color="primary"
                                    onClick={() => this.setState({showStudentModal: false})}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.showManagerModal}>
                        <ModalHeader>
                            <h3>Promote {this.props.selectedUser.firstName} {this.props.selectedUser.lastName} to Center
                                Manager for</h3>
                        </ModalHeader>
                        <ModalBody>
                            <div>
                                <div className="row">
                                    <div className="col-12">
                                        <MuiThemeProvider theme={theme}>
                                            <MaterialTable columns={[
                                                {title: 'ID', field: 'id'},
                                                {title: 'Name', field: 'name'}]}
                                                           data={this.props.allCenters}
                                                           title="Select Center"
                                                           options={{
                                                               showTitle: false,
                                                               search: true,
                                                               actionsColumnIndex: -1,
                                                               searchFieldAlignment: "left"
                                                           }}
                                                           actions={[
                                                               {
                                                                   icon: 'check_circle_outline',
                                                                   tooltip: 'Select Center',
                                                                   onClick: (event, rowData) => {
                                                                       this.props.saveUserAsManager(this.props.selectedUser, rowData.id);
                                                                       this.setState({showManagerModal: false});
                                                                   }
                                                               }
                                                           ]}
                                                           localization={{header: {actions: "Options"}}}/>
                                        </MuiThemeProvider>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="contained" color="primary"
                                    onClick={() => this.setState({showManagerModal: false})}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.showTrainerModal}>
                        <ModalHeader>
                            <h3>Promote {this.props.selectedUser.firstName} {this.props.selectedUser.lastName} to Center
                                Trainer for</h3>
                        </ModalHeader>
                        <ModalBody>
                            <div>
                                <div className="row">
                                    <div className="col-12">
                                        <FormControl>
                                            <MuiThemeProvider theme={theme}>
                                                <MaterialTable columns={[
                                                    {title: 'ID', field: 'id'},
                                                    {title: 'Name', field: 'name'}]}
                                                               data={this.props.allCenters}
                                                               title="Select Center"
                                                               options={{
                                                                   showTitle: false,
                                                                   search: true,
                                                                   actionsColumnIndex: -1,
                                                                   searchFieldAlignment: "left"
                                                               }}
                                                               actions={[
                                                                   {
                                                                       icon: 'check_circle_outline',
                                                                       tooltip: 'Select Center',
                                                                       onClick: (event, rowData) => {
                                                                           this.props.saveUserAsTrainer(this.props.selectedUser, rowData.id);
                                                                           this.setState({showTrainerModal: false});
                                                                       }
                                                                   }
                                                               ]}
                                                               localization={{header: {actions: "Options"}}}/>
                                            </MuiThemeProvider>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="contained" color="primary"
                                    onClick={() => this.setState({showTrainerModal: false})}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        )
    }
}

export class AdminUserEditModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profileSaveButtonStatus: false
        }
    }

    render() {
        return (
            <div>
                <div className="card-header">
                    <h1>Edit Profile | NSC ID: {this.props.user.id}</h1>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-8 col-sm-12">
                                    <div className="row">
                                        {
                                            userFieldsToRender.map((field) => {
                                                if ((!field.subkey)) {
                                                    var fieldProps;
                                                    var fieldRows = 1;
                                                    var fieldCols = "col-md-6 col-xs-12";
                                                    var fieldType = 'text';
                                                    if (field.key === "birthDate") {
                                                        fieldProps = {shrink: true}
                                                    }
                                                    if (field.key === "adminComment") {
                                                        fieldRows = 4;
                                                        fieldCols = "col-md-12 col-xs-12"
                                                    }
                                                    if (field.key === "feePaidUntil") {
                                                        fieldType = 'number';
                                                    }
                                                    if (field.key === "isActiveTrainer"
                                                        || field.key ==="noMail") {
                                                        return (
                                                            <div key={field.label} className={fieldCols}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={this.props.user[field.key]}
                                                                            onChange={(e) => this.props.handleUserFieldChange(e, field.key, null, false, true)}
                                                                            value={this.props.user[field.key]}
                                                                            color="primary"
                                                                        />}
                                                                    label={field.label}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                    else if (field.key === "feePaidUntil") {
                                                        return (
                                                            <div key={field.label} className={fieldCols}>
                                                                <InputLabel shrink htmlFor="feePaidUntil">{field.label}</InputLabel>
                                                                <Select
                                                                    id={field.label}
                                                                    label={field.label}
                                                                    fullWidth
                                                                    value={this.props.user[field.key]}
                                                                    margin="dense"
                                                                    onChange={(e) => this.props.handleUserFieldChange(e, field.key, null, false, false)}
                                                                >
                                                                    {yearSelectOptions.map((year) => {
                                                                        return (
                                                                            <MenuItem key={year}
                                                                                      value={year}>{year}</MenuItem>
                                                                        )
                                                                    })}
                                                                </Select>
                                                            </div>)
                                                    }
                                                    else return (
                                                        <div key={field.label} className={fieldCols}>
                                                            <TextField InputLabelProps={fieldProps}
                                                                       id={field.label}
                                                                       label={field.label}
                                                                       type={fieldType}
                                                                       fullWidth
                                                                       multiline
                                                                       rows={fieldRows}
                                                                       value={this.props.user[field.key]}
                                                                       margin="dense"
                                                                       onChange={(e) => {
                                                                           this.props.handleUserFieldChange(e, field.key, null, false, false);
                                                                           this.setState({profileSaveButtonStatus: true})
                                                                       }}/>
                                                        </div>)
                                                } else {
                                                    if (field.subkey !== "country") {
                                                        return (
                                                            <div key={field.label} className="col-md-6 col-xs-12">
                                                                <TextField
                                                                    id={field.label}
                                                                    label={field.label}
                                                                    fullWidth
                                                                    value={this.props.user[field.key][field.subkey]}
                                                                    margin="dense"
                                                                    onChange={(e) => this.props.handleUserFieldChange(e, field.key, field.subkey, false, false)}
                                                                />
                                                            </div>)
                                                    } else return (
                                                        <div key={field.label} className="col-md-6 col-xs-12">
                                                            <InputLabel shrink htmlFor="country">Country</InputLabel>
                                                            <Select
                                                                id={field.label}
                                                                label={field.label}
                                                                fullWidth
                                                                value={this.props.user[field.key][field.subkey]}
                                                                margin="dense"
                                                                onChange={(e) => this.props.handleUserFieldChange(e, field.key, field.subkey, false, false)}
                                                            >
                                                                {countrySelectOptions.map((country) => {
                                                                    return (
                                                                        <MenuItem key={country.value}
                                                                                  value={country.value}>{country.label}</MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </div>)
                                                }
                                            })}
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div>
                                        <div className="row">
                                            <div className="image-container">
                                                <img src={this.props.userImage} alt="Please upload."
                                                     id="user-image" className="user-image"/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <label htmlFor="image-upload" className="file-upload-button">
                                                Upload Image
                                            </label>
                                            <input type='file' id='image-upload'
                                                   onChange={(e) => {
                                                       this.props.handleSelectedFile(e);
                                                       this.setState({
                                                           profileSaveButtonStatus: true
                                                       })
                                                   }}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export class AdminUserCreateModal extends React.Component {
    render() {
        return (
            <div>
                <div className="card-header">
                    <h2>Create User</h2>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-8 col-sm-12">
                            <div className="row">
                                {
                                    userFieldsToRender.map((field) => {
                                        if ((!field.subkey)) {
                                            var fieldProps;
                                            var fieldRows = 1;
                                            var fieldCols = "col-md-6 col-xs-12";
                                            var fieldType = "text"
                                            if (field.key === "birthDate") {
                                                //fieldType = "date";
                                                fieldProps = {shrink: true}
                                            }
                                            if (field.key === "adminComment") {
                                                fieldRows = 4;
                                                fieldCols = "col-md-12 col-xs-12"
                                            }
                                            if (field.key === "feePaidUntil") {
                                                fieldType = 'number';
                                            }
                                            if (field.key === "isActiveTrainer"
                                                || field.key ==="noMail") {
                                                return (
                                                    <div key={field.label} className={fieldCols}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={this.props.newUser[field.key]}
                                                                    onChange={(e) => this.props.handleUserFieldChange(e, field.key, null, true, true)}
                                                                    value={this.props.newUser[field.key]}
                                                                    color="primary"
                                                                />}
                                                            label={field.label}
                                                        />
                                                    </div>
                                                )
                                            }
                                            else if (field.key === "feePaidUntil") {
                                                return (
                                                    <div key={field.label} className={fieldCols}>
                                                        <InputLabel shrink htmlFor="feePaidUntil">{field.label}</InputLabel>
                                                        <Select
                                                            id={field.key}
                                                            label={field.label}
                                                            fullWidth
                                                            value={this.props.newUser[field.key]}
                                                            margin="dense"
                                                            onChange={(e) => this.props.handleUserFieldChange(e, field.key, null, true, false)}
                                                        >
                                                            {yearSelectOptions.map((year) => {
                                                                return (
                                                                    <MenuItem key={year}
                                                                              value={year}>{year}</MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                    </div>)
                                            }
                                            else return (
                                                <div key={field.label} className={fieldCols}>
                                                    <TextField InputLabelProps={fieldProps}
                                                               id={field.key}
                                                               label={field.label}
                                                               type={fieldType}
                                                               fullWidth
                                                               multiline
                                                               rows={fieldRows}
                                                               value={this.props.newUser[field.key]}
                                                               margin="dense"
                                                               onChange={(e) => this.props.handleUserFieldChange(e, field.key, null, true, false)}
                                                    />
                                                </div>)
                                        } else {
                                            if (field.subkey !== "country") {
                                                return (
                                                    <div key={field.label} className="col-md-6 col-xs-12">
                                                        <TextField
                                                            id={field.subkey}
                                                            label={field.label}
                                                            fullWidth
                                                            value={this.props.newUser[field.key][field.subkey]}
                                                            margin="dense"
                                                            onChange={(e) => this.props.handleUserFieldChange(e, field.key, field.subkey, true, false)}
                                                        />
                                                    </div>)
                                            } else return (
                                                <div key={field.label} className="col-md-6 col-xs-12">
                                                    <InputLabel shrink htmlFor="country">Country</InputLabel>
                                                    <Select
                                                        id={field.subkey}
                                                        label={field.label}
                                                        fullWidth
                                                        value={this.props.newUser[field.key][field.subkey]}
                                                        margin="dense"
                                                        onChange={(e) => this.props.handleUserFieldChange(e, field.key, field.subkey, true, false)}
                                                    >
                                                        {countrySelectOptions.map((country) => {
                                                            return (
                                                                <MenuItem
                                                                    value={country.value}>{country.label}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </div>)
                                        }
                                    })}
                                <div key="password" className="col-md-6 col-xs-12">
                                    <TextField
                                        id="password"
                                        label="Password"
                                        type="password"
                                        fullWidth
                                        value={this.props.newUser.password}
                                        margin="dense"
                                        onChange={(e) => this.props.handleUserFieldChange(e, "password", null, true, false)}
                                    />
                                </div>
                                <div key="passwordRepeat" className="col-md-6 col-xs-12">
                                    <TextField
                                        id="passwordRepeat"
                                        label="Repeat Password"
                                        type="password"
                                        fullWidth
                                        value={this.props.newUser.passwordRepeat}
                                        margin="dense"
                                        onChange={(e) => this.props.handleUserFieldChange(e, "passwordRepeat", null, true, false)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div>
                                <div className="row">
                                    <div className="image-container">
                                        <img src={this.props.userImage} alt="Please upload."
                                             className="user-image" id="user-image"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <label htmlFor="image-upload" className="file-upload-button">
                                        Upload Image
                                    </label>
                                    <input type='file' accept=".jpg, .jpeg, .png" id='image-upload'
                                           onChange={(e) => {
                                               this.props.handleSelectedFile(e);
                                           }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export class AdminBrevetLevelsView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showLevelModal: false,
            showLevelIssuersModal: false,
            isAddingIssuer: false
        };
    }

    render() {
        return (
            <div>
                <div className="card-header">
                    <h1>Brevet Levels</h1>
                </div>
                <div className="card-body">
                    <div>
                        <div className="row">
                            <div className="col-12">
                                <h2>Active brevet levels</h2>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <MuiThemeProvider theme={theme}>
                                            <MaterialTable columns={[
                                                {title: 'Brevet level', field: 'label'}]}
                                                           data={this.props.brevetLevels}
                                                           title="Select Brevet Type"
                                                           options={{
                                                               showTitle: false,
                                                               search: true,
                                                               actionsColumnIndex: -1,
                                                               searchFieldAlignment: "left",
                                                               rowStyle: rowData => ({
                                                                   backgroundColor: (rowData.enabled != null
                                                                       && rowData.enabled) ? '#e0ffe0' : '#ffe0e0'
                                                               })
                                                           }}
                                                           actions={[
                                                               {
                                                                   icon: 'edit',
                                                                   tooltip: 'View brevet level',
                                                                   onClick: (event, rowData) => {
                                                                       this.props.getBrevetLevel(rowData.key);
                                                                       this.setState({showLevelViewModal: true});
                                                                   }
                                                               },
                                                               {
                                                                   icon: 'add_circle',
                                                                   tooltip: 'Add Brevet level',
                                                                   onClick: () => {
                                                                       this.props.prepareNewLevel();
                                                                       this.setState({showLevelViewModal: true});
                                                                   },
                                                                   isFreeAction: true
                                                               }
                                                           ]}
                                                           localization={{header: {actions: "Options"}}}/>
                                        </MuiThemeProvider>
                                    </div>
                                </div>
                            </div>
                            <Modal isOpen={this.state.showLevelViewModal} className="modal-dialog">
                                <ModalHeader>
                                    {this.props.selectedBrevetLevel.label} details
                                </ModalHeader>
                                <ModalBody>
                                    <div className="row">
                                        <div className="col-12">
                                            <TextField
                                                id="brevetName"
                                                label="Name"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={(this.props.selectedBrevetLevel.label) ? this.props.selectedBrevetLevel.label : ""}
                                                margin="dense"
                                                onChange={(e) => this.props.handleBreveLevelFieldChange(e, "label")}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <TextField
                                                id="isoLevel"
                                                label="Iso-Level"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={(this.props.selectedBrevetLevel.isoLevel) ? this.props.selectedBrevetLevel.isoLevel : ""}
                                                margin="dense"
                                                onChange={(e) => this.props.handleBreveLevelFieldChange(e, "isoLevel")}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <InputLabel shrink htmlFor="voucherType">Voucher Type</InputLabel>
                                            <Select
                                                id="voucherType"
                                                label="Voucher Type"
                                                fullWidth
                                                value={(this.props.selectedBrevetLevel.requiredVoucher) ? this.props.selectedBrevetLevel.requiredVoucher : ""}
                                                margin="dense"
                                                onChange={(e) => this.props.handleBreveLevelFieldChange(e, "requiredVoucher")}
                                            >
                                                {voucherTypes.map((voucher) => {
                                                    return (
                                                        <MenuItem
                                                            key={voucher} value={voucher}>{voucher}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                        <div className="col-12">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id="enabled"
                                                        checked={this.props.selectedBrevetLevel.enabled}
                                                        onChange={(e) => this.props.handleBrevetLevelCheckbox(e, "enabled")}
                                                        value="enabled"
                                                        color="primary"
                                                    />
                                                }
                                                label="Enabled"
                                            />
                                        </div>
                                        <div className="col-12">
                                            <hr/>
                                        </div>
                                        <div className="col-3"><strong>Can issue:</strong></div>
                                        <div className="col-9">
                                            {this.props.selectedBrevetLevel.canIssue.map((brevetLevel) => {
                                                return (
                                                    <Chip
                                                        key={brevetLevel}
                                                        label={this.props.brevetTypes[brevetLevel].label}
                                                        className="chip-margin"
                                                        onDelete={() => this.props.removeBrevetLevelDependency("canIssue", brevetLevel)}
                                                    />
                                                )
                                            })}
                                            <Chip
                                                key="addBrevetLevel"
                                                label="Add"
                                                color="primary"
                                                className="chip-margin"
                                                deleteIcon={<AddIcon/>}
                                                onDelete={() => this.setState({showLevelIssuersModal: true})}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <hr/>
                                        </div>
                                        <div className="col-3"><strong>Can be issued by:</strong></div>
                                        <div className="col-9">
                                            {this.props.selectedBrevetLevel.issuers.map((brevetLevel) => {
                                                return (
                                                    <Chip
                                                        key={brevetLevel}
                                                        label={this.props.brevetTypes[brevetLevel].label}
                                                        className="chip-margin"
                                                        onDelete={() => this.props.removeBrevetLevelDependency("issuers", brevetLevel)}
                                                    />
                                                )
                                            })}
                                            <Chip
                                                key="addBrevetLevel"
                                                label="Add"
                                                color="primary"
                                                className="chip-margin"
                                                deleteIcon={<AddIcon/>}
                                                onDelete={() => this.setState({
                                                    showLevelIssuersModal: true,
                                                    isAddingIssuer: true
                                                })}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <hr/>
                                        </div>
                                        <div className="col-3"><strong>Save backside as:</strong></div>
                                        <div className="col-9">
                                            {this.props.selectedBrevetLevel.id}.pdf
                                            {this.props.selectedBrevetLevelBackside}
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button variant="contained" color="primary"
                                            onClick={() => {
                                                this.setState({showLevelViewModal: false});
                                                this.props.saveEditedBrevetLevel()
                                            }}>Save</Button>
                                    <Button variant="contained" color="primary"
                                            onClick={() => this.setState({showLevelViewModal: false})}>Cancel</Button>
                                </ModalFooter>
                            </Modal>
                            <Modal isOpen={this.state.showLevelIssuersModal} className="modal-dialog">
                                <ModalHeader>
                                    Choose {(this.state.isAddingIssuer) ? "brevet that can issue " + this.props.selectedBrevetLevel.label
                                    : "brevets that can be issued by " + this.props.selectedBrevetLevel.label}
                                </ModalHeader>
                                <ModalBody>
                                    <div className="row">
                                        <div className="col-12">
                                            <MuiThemeProvider theme={theme}>
                                                <MaterialTable columns={[
                                                    {title: 'Brevet level', field: 'label'}]}
                                                               data={this.props.brevetLevels}
                                                               title="Select Brevet Type"
                                                               options={{
                                                                   showTitle: false,
                                                                   search: true,
                                                                   actionsColumnIndex: -1,
                                                                   searchFieldAlignment: "left"
                                                               }}
                                                               actions={[
                                                                   {
                                                                       icon: 'check_circle_outline',
                                                                       tooltip: 'Choose this level',
                                                                       onClick: (event, rowData) => {
                                                                           this.props.addBrevetLevelDependency(this.state.isAddingIssuer, rowData);
                                                                           this.setState({
                                                                               showLevelIssuersModal: false,
                                                                               isAddingIssuer: false
                                                                           });
                                                                       }
                                                                   }
                                                               ]}
                                                               localization={{header: {actions: "Options"}}}/>
                                            </MuiThemeProvider>
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button variant="contained" color="primary"
                                            onClick={() => this.setState({
                                                showLevelIssuersModal: false,
                                                isAddingIssuer: false
                                            })}>Cancel</Button>
                                </ModalFooter>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export class AdminBrevetsView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showAddressModal: false,
            selectedBrevet: null,
            showBrevetModal: false,
            sendTo: "CENTER",
            brevetLevelGrouping: {
                title: 'Brevet level', field: 'level', filtering: false, render: rowData =>
                    <div>
                        {this.props.brevetTypes[rowData.level] ? this.props.brevetTypes[rowData.level].label : "loading"}
                    </div>
            },
            showHeader: true,
            yearFilter: "",
            yearButtonColors: {"nineteen": "default", "twenty": "default", "all": "primary"},
            groupButtonColor: "default",
            tablePagination: true,
            pageSize: 10,
            columnsFilter: true
        }
    }

    getBackSideURL(brevetID) {
        var url = this.props.constants.baseFrontURL + "backsides/" + brevetID + ".pdf";
        var http = new XMLHttpRequest();
        http.open('HEAD', url, false);
        http.send();
        if (http.status === 200) {
            return url;
        } else {
            return this.props.constants.baseFrontURL + "backsides/_FALLBACK.pdf";
        }
    }

    render() {
        return (
            <div>
                <div className="card-header">
                    <h1>All Brevets</h1>
                </div>
                <div className="card-body">
                    <div>
                        <div className="row">
                            <div className="col-12">
                                <h2>Validated Brevets</h2>
                                <div style={{fontSize: 12, color: "gray"}}>To get a specific report you must filter by
                                    trainer and / or center and then click on "Report".
                                    <br/>If you don't filter, you will get a report for all brevets.
                                    <br/>Additionally, you can use the search field in the left corner and the buttons
                                    in the right corner to filter by year.
                                </div>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <MaterialTable columns={[
                                    {title: 'ID', field: 'id', filtering: false, defaultSort: "desc"},
                                    this.state.brevetLevelGrouping,
                                    {
                                        title: 'Created',
                                        field: 'createdAt',
                                        filtering: false,
                                        defaultFilter: this.state.yearFilter,
                                        render: rowData =>
                                            <Moment format="YYYY/MM/DD">
                                                {rowData.createdAt}
                                            </Moment>
                                    },
                                    {
                                        title: 'Student ID', field: 'student.id',
                                        headerStyle: {display: "none"}, cellStyle: {display: "none"}
                                    },
                                    {
                                        title: 'Student first',
                                        field: 'student.firstName',
                                        filterCellStyle: {display: "none"},
                                        headerStyle: {display: "none"},
                                        cellStyle: {display: "none"}
                                    },
                                    {
                                        title: 'Student (filter by ID)',
                                        field: 'student.lastName',
                                        filtering: false,
                                        filterCellStyle: {display: "none"},
                                        render: rowData =>
                                            <div>
                                                <div>{rowData.student.lastName}, {rowData.student.firstName}</div>
                                                <div>ID: {rowData.student.id}</div>
                                            </div>
                                    },
                                    {
                                        title: 'Trainer ID', field: 'trainer.id',
                                        headerStyle: {display: "none"}, cellStyle: {display: "none"}
                                    },
                                    {
                                        title: 'Trainer first',
                                        field: 'trainer.firstName',
                                        filterCellStyle: {display: "none"},
                                        headerStyle: {display: "none"},
                                        cellStyle: {display: "none"}
                                    },
                                    {
                                        title: 'Trainer (filter by ID)',
                                        field: 'trainer.lastName',
                                        filtering: false,
                                        filterCellStyle: {display: "none"},
                                        render: rowData =>
                                            <div>
                                                <div>{rowData.trainer.lastName}, {rowData.trainer.firstName}</div>
                                                <div>ID: {rowData.trainer.id}</div>
                                            </div>
                                    },
                                    {title: 'Center (filter by name)', field: 'center.name'},
                                    {title: 'Send To', field: 'sendTo', filtering: false},
                                    {
                                        title: 'Comments', field: 'comments', filtering: false, render: rowData => {
                                            if (!rowData.comments) {
                                                return null;
                                            } else {
                                                if (rowData.comments.length <= 10) {
                                                    return (rowData.comments);
                                                }

                                                if (rowData.comments.length > 10) {
                                                    return (<div>
                                                        {rowData.comments.slice(0, 10)}...
                                                    </div>);
                                                }
                                            }

                                        }
                                    }]}
                                               data={this.props.brevets}
                                               icons={{
                                                   Filter: () => (<SearchIcon style={{fontSize: 12}} color="primary"/>)
                                               }}
                                               options={{
                                                   header: this.state.showHeader,
                                                   filtering: this.state.columnsFilter,
                                                   search: true,
                                                   paging: true,
                                                   pageSize: this.state.pageSize,
                                                   pageSizeOptions: [5, 10, 200],
                                                   emptyRowsWhenPaging: false,
                                                   actionsColumnIndex: 0,
                                                   searchFieldAlignment: "left",
                                                   showTitle: false,
                                                   exportButton: true,
                                                   exportAllData: true,
                                                   exportFileName: "all_brevets",
                                                   rowStyle: rowData => ({
                                                       backgroundColor: ((rowData.comments != null && rowData.comments.includes("[SHIPPED:")) ||
                                                           rowData.sendTo === "NONE") ? '#e0ffe0' : '#fff8e0'
                                                   })
                                               }}
                                               components={{
                                                   GroupRow: props => (
                                                       <tr style={{display: "block", padding: "10px"}}>
                                                           <td style={{display: "inline-block", width: "400px"}}>
                                                               <strong>{props.groups[0].title}: </strong>
                                                               {this.props.brevetTypes[props.groupData.value]
                                                                   ? this.props.brevetTypes[props.groupData.value].label
                                                                   : props.groupData.value}
                                                           </td>
                                                           <td style={{display: "inline-block", width: "100px"}}>
                                                               <strong>Issued:</strong> {props.groupData.data.length}
                                                           </td>
                                                       </tr>
                                                   )
                                               }}
                                               actions={[
                                                   {
                                                       icon: 'info',
                                                       tooltip: 'Show info',
                                                       onClick: (event, rowData) => {
                                                           this.setState({
                                                               selectedBrevet: rowData,
                                                               showBrevetModal: !this.state.showBrevetModal,
                                                           });
                                                       }
                                                   },
                                                   {
                                                       icon: 'mail_outline',
                                                       tooltip: 'Show address',
                                                       onClick: (event, rowData) => {
                                                           this.props.getBrevetAddress(rowData);
                                                           this.setState({
                                                               sendTo: rowData.sendTo,
                                                               showAddressModal: true
                                                           });
                                                       }
                                                   },
                                                   {
                                                       icon: 'credit_card',
                                                       tooltip: 'Download PDF',
                                                       onClick: (event, rowData) => {
                                                           window.open(this.props.constants.baseAPIURL + "admin/brevet/" + rowData.id + "/pdf")
                                                       }
                                                   },
                                                   {
                                                       icon: 'panorama',
                                                       tooltip: 'Download back side',
                                                       onClick: (event, rowData) => {
                                                           const download = document.createElement('a');
                                                           download.href = this.getBackSideURL(rowData.level);
                                                           download.target = "_blank";
                                                           document.body.appendChild(download);
                                                           download.click();
                                                           document.body.removeChild(download);
                                                       }
                                                   },
                                                   {
                                                       icon: 'art_track',
                                                       tooltip: 'Mark brevet as shipped',
                                                       onClick: (event, rowData) => {
                                                           this.props.saveBrevetAsSent(rowData);
                                                       }
                                                   },
                                                   {
                                                       icon: () => <Chip color={this.state.yearButtonColors.nineteen}
                                                                         label="2019"/>,
                                                       tooltip: 'Show only brevets of 2019',
                                                       onClick: () => {
                                                           this.setState({
                                                               yearFilter: "2019",
                                                               yearButtonColors: {
                                                                   "nineteen": "primary",
                                                                   "twenty": "default",
                                                                   "all": "default"
                                                               }
                                                           });
                                                       },
                                                       isFreeAction: true
                                                   },
                                                   {
                                                       icon: () => <Chip color={this.state.yearButtonColors.twenty}
                                                                         label="2020"/>,
                                                       tooltip: 'Show only brevets of 2020',
                                                       onClick: () => {
                                                           this.setState({
                                                               yearFilter: "2020",
                                                               yearButtonColors: {
                                                                   "nineteen": "default",
                                                                   "twenty": "primary",
                                                                   "all": "default"
                                                               }
                                                           })
                                                       },
                                                       isFreeAction: true
                                                   },
                                                   {
                                                       icon: () => <Chip color={this.state.yearButtonColors.all}
                                                                         label="All"/>,
                                                       tooltip: 'Show all years',
                                                       onClick: () => {
                                                           this.setState({
                                                               yearFilter: "",
                                                               yearButtonColors: {
                                                                   "nineteen": "default",
                                                                   "twenty": "default",
                                                                   "all": "primary"
                                                               }
                                                           })
                                                       },
                                                       isFreeAction: true
                                                   },
                                                   {
                                                       icon: () => <Chip color="secondary" variant="outlined"
                                                                         label={this.state.groupButtonColor === "default" ? "Report" : "List"}/>,
                                                       tooltip: 'Group brevets by level',
                                                       onClick: () => {
                                                           this.setState({tablePagesSize: 200});
                                                           if (this.state.brevetLevelGrouping.defaultGroupOrder) {
                                                               this.setState({
                                                                   brevetLevelGrouping: {
                                                                       title: 'Brevet level',
                                                                       field: 'level',
                                                                       filtering: false,
                                                                       render: rowData =>
                                                                           <div>
                                                                               {this.props.brevetTypes[rowData.level] ? this.props.brevetTypes[rowData.level].label : "loading"}
                                                                           </div>
                                                                   },
                                                                   showHeader: true,
                                                                   groupButtonColor: "default",
                                                                   tablePagination: true,
                                                                   pageSize: 10,
                                                                   columnsFilter: true
                                                               });
                                                           } else {
                                                               this.setState({
                                                                   brevetLevelGrouping: {
                                                                       title: 'Brevet level',
                                                                       field: 'level',
                                                                       filtering: false,
                                                                       defaultGroupOrder: 1,
                                                                       render: rowData =>
                                                                           <div>
                                                                               {this.props.brevetTypes[rowData.level] ? this.props.brevetTypes[rowData.level].label : "loading"}
                                                                           </div>
                                                                   },
                                                                   showHeader: false,
                                                                   groupButtonColor: "primary",
                                                                   tablePagination: false,
                                                                   pageSize: 50,
                                                                   columnsFilter: false
                                                               });
                                                           }
                                                       },
                                                       isFreeAction: true
                                                   }]}
                                               localization={{header: {actions: "Options"}}}/>
                            </div>
                            <div className="col-12"><br/></div>
                            <div className="col-12" hidden={!this.props.unapprovedBrevets.length}>
                                <h2>Unvalidated Brevets</h2>
                                <hr/>
                            </div>
                            <div className="col-12" hidden={!this.props.unapprovedBrevets.length}>
                                <MaterialTable columns={[
                                    {title: 'Serial', field: 'id'},
                                    {
                                        title: 'Level', field: 'level', render: rowData =>
                                            <div>
                                                {this.props.brevetTypes[rowData.level] ? this.props.brevetTypes[rowData.level].label : "loading"}
                                            </div>
                                    },
                                    {title: 'Student First', field: 'student.firstName'},
                                    {title: 'Student Last', field: 'student.lastName'},
                                    {title: 'Trainer First', field: 'trainer.firstName'},
                                    {title: 'Trainer Last', field: 'trainer.lastName'},
                                    {
                                        title: 'Created', field: 'createdAt', render: rowData =>
                                            <Moment format="YYYY/MM/DD">
                                                {rowData.createdAt}
                                            </Moment>
                                    }]}
                                               data={this.props.unapprovedBrevets}
                                               title="Brevetliste"
                                               options={{
                                                   toolbar: false,
                                                   paging: false,
                                                   actionsColumnIndex: 0
                                               }}
                                               actions={[
                                                   {
                                                       icon: 'info',
                                                       tooltip: 'Show info',
                                                       onClick: (event, rowData) => {
                                                           this.setState({
                                                               showBrevetModal: !this.state.showBrevetModal,
                                                               selectedBrevet: rowData
                                                           })
                                                       }
                                                   },
                                                   {
                                                       icon: 'cancel',
                                                       tooltip: 'Delete brevet',
                                                       onClick: (event, rowData) => {
                                                           this.props.deleteUnapprovedBrevet(rowData);
                                                       }
                                                   }]}
                                               localization={{header: {actions: "Options"}}}/>
                            </div>
                            <Modal isOpen={this.state.showAddressModal} className="small-modal">
                                <ModalHeader>
                                    Send Brevet to {this.state.sendTo}
                                </ModalHeader>
                                <ModalBody>
                                    {this.props.selectedBrevetAddress}

                                </ModalBody>
                                <ModalFooter>
                                    <Button variant="contained" color="primary"
                                            onClick={() => this.setState({showAddressModal: false})}>Close</Button>
                                </ModalFooter>
                            </Modal>
                            <Modal isOpen={this.state.showBrevetModal}>
                                <ModalBody>
                                    <BrevetDetailView brevet={this.state.selectedBrevet}/>

                                </ModalBody>
                                <ModalFooter>
                                    <Button variant="contained" color="primary"
                                            onClick={() => this.setState({showBrevetModal: false})}>Close</Button>
                                </ModalFooter>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export class AdminManualsView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showEditManualModal: false,
            showPreviewManualModal: false
        };
    }

    render() {
        return (
            <div>
                <div className="card-header">
                    <h1>Manuals</h1>
                </div>
                <div className="card-body">
                    <div>
                        <div className="row">
                            <div className="col-12">
                                <h2>Available Manuals</h2>
                                <hr/>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <MuiThemeProvider theme={theme}>
                                            <MaterialTable columns={[
                                                {title: 'Manual', field: 'title'}]}
                                                           data={this.props.allManuals}
                                                           title="Select Manual"
                                                           options={{
                                                               showTitle: false,
                                                               search: true,
                                                               actionsColumnIndex: -1,
                                                               searchFieldAlignment: "left",
                                                               rowStyle: rowData => ({
                                                                   backgroundColor: (rowData.enabled != null
                                                                       && rowData.enabled) ? '#e0ffe0' : '#ffe0e0'
                                                               })
                                                           }}
                                                           actions={[
                                                               {
                                                                   icon: 'edit',
                                                                   tooltip: 'Edit Manual',
                                                                   onClick: (event, rowData) => {
                                                                       this.props.getManual(rowData.id);
                                                                       this.setState({showEditManualModal: true});
                                                                   }
                                                               },
                                                               {
                                                                   icon: 'preview',
                                                                   tooltip: 'Preview Manual',
                                                                   onClick: (event, rowData) => {
                                                                       this.props.getManual(rowData.id);
                                                                       this.setState({showPreviewManualModal: true});
                                                                   }
                                                               },
                                                               {
                                                                   icon: 'add_circle',
                                                                   tooltip: 'Add Manual',
                                                                   onClick: () => {
                                                                       this.props.prepareNewManual();
                                                                       this.setState({showEditManualModal: true});
                                                                   },
                                                                   isFreeAction: true
                                                               }
                                                           ]}
                                                           localization={{header: {actions: "Options"}}}/>
                                        </MuiThemeProvider>
                                    </div>
                                </div>
                            </div>
                            <Modal isOpen={this.state.showEditManualModal} className="modal-dialog">
                                <ModalHeader>
                                    {this.props.selectedManual.title} details
                                </ModalHeader>
                                <ModalBody>
                                    <div className="row">
                                        <div className="col-12">
                                            <TextField
                                                id="manualTitle"
                                                label="Title"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={(this.props.selectedManual.title) ? this.props.selectedManual.title : ""}
                                                margin="dense"
                                                onChange={(e) => this.props.handleManualFieldChange(e, "title")}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <TextField
                                                id="manualAuthor"
                                                label="Author"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={(this.props.selectedManual.author) ? this.props.selectedManual.author : ""}
                                                margin="dense"
                                                onChange={(e) => this.props.handleManualFieldChange(e, "author")}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <TextField
                                                id="manualUrl"
                                                label="Manual URL"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={(this.props.selectedManual.urls[0]) ? this.props.selectedManual.urls[0] : ""}
                                                margin="dense"
                                                onChange={(e) => this.props.handleManualFieldChange(e, "urls")}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <TextField
                                                id="previewImageUrl"
                                                label="Preview URL"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={(this.props.selectedManual.previewImageUrl) ? this.props.selectedManual.previewImageUrl : ""}
                                                margin="dense"
                                                onChange={(e) => this.props.handleManualFieldChange(e, "previewImageUrl")}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <TextField
                                                id="price"
                                                label="Price in €"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={(this.props.selectedManual.price) ? this.props.selectedManual.price : "0"}
                                                margin="dense"
                                                onChange={(e) => this.props.handleManualFieldChange(e, "price")}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <InputLabel shrink htmlFor="voucherType">Voucher Type</InputLabel>
                                            <Select
                                                id="voucherType"
                                                label="Voucher Type"
                                                fullWidth
                                                value={(this.props.selectedManual.voucher) ? this.props.selectedManual.voucher : ""}
                                                margin="dense"
                                                onChange={(e) => this.props.handleManualFieldChange(e, "voucher")}
                                            >
                                                {voucherTypes.map((voucher) => {
                                                    return (
                                                        <MenuItem
                                                            key={voucher} value={voucher}>{voucher}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                        <div className="col-6 padding-15">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.props.selectedManual.isActive}
                                                        onChange={(e) => this.props.handleManualCheckbox(e, "isActive")}
                                                        value={this.props.selectedManual.isActive}
                                                        color="primary"
                                                    />}
                                                label="Enabled"
                                            />
                                        </div>
                                        <div className="col-6 padding-15">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.props.selectedManual.isPublicManual}
                                                        onChange={(e) => this.props.handleManualCheckbox(e, "isPublicManual")}
                                                        value={this.props.selectedManual.isPublicManual}
                                                        color="primary"
                                                    />}
                                                label="Free manual for all members"
                                            />
                                        </div>
                                        <div className="col-6 padding-15">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.props.selectedManual.isTrainerManual}
                                                        onChange={(e) => this.props.handleManualCheckbox(e, "isTrainerManual")}
                                                        value={this.props.selectedManual.isTrainerManual}
                                                        color="primary"
                                                    />}
                                                label="Only available for trainers"
                                            />
                                        </div>
                                        <div className="col-6 padding-15">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.props.selectedManual.isPublicTrainerManual}
                                                        onChange={(e) => this.props.handleManualCheckbox(e, "isPublicTrainerManual")}
                                                        value={this.props.selectedManual.isPublicTrainerManual}
                                                        color="primary"
                                                    />}
                                                label="Free manual for all trainers"
                                            />
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button variant="contained" color="primary"
                                            onClick={() => {
                                                this.setState({showEditManualModal: false});
                                                this.props.getManualSource(this.props.selectedManual.urls[0], true)
                                            }}>Save</Button>
                                    <Button variant="contained" color="primary"
                                            onClick={() => this.setState({showEditManualModal: false})}>Cancel</Button>
                                </ModalFooter>
                            </Modal>
                            <Modal isOpen={this.state.showPreviewManualModal} className="modal-dialog">
                                <ModalHeader>
                                    {this.props.selectedManual.title} preview
                                    <div className="close-button">
                                        <Chip color="primary" size="small" label="X"
                                              onClick={() => this.setState({showPreviewManualModal: false})}/>
                                    </div>
                                </ModalHeader>

                                <iframe className="manual" srcDoc={this.props.selectedManual.sources[0]}
                                        title="manual-preview"></iframe>

                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export class AdminManualTransactionsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showHeader: true,
            yearFilter: "",
            yearButtonColors: {"twenty": "default", "twentyone": "default", "all": "primary"},
            showEditTransactionModal: false,
            tablePagination: true,
            pageSize: 10,
            columnsFilter: true,
            selectedTransaction: {
                id: "",
                adminComment: "",
                invoicedAt: "",
                paidAt: "",
                isDeleted: false
            }
        };
    }

    handleTransactionFieldChange = (event, field) => {
        if (field==="isDeleted"){
            this.selectedTransaction = this.state.selectedTransaction;
            this.selectedTransaction[field] = event.target.checked;
            this.setState({selectedTransaction: this.selectedTransaction});
        }
        else {
            this.selectedTransaction = this.state.selectedTransaction;
            this.selectedTransaction[field] = event.target.value;
            this.setState({selectedTransaction: this.selectedTransaction});
        }
    };

    render() {
        return (
            <div>
                <div className="card-header">
                    <h1>Sold Manuals by all Centers</h1>
                </div>
                <div className="card-body">
                    <div className="row">

                        <div className="col-12">
                            <MaterialTable columns={[
                                {title: 'Serial', field: 'id', filtering: false},
                                {title: 'Title', field: 'manual.title', filtering: false},
                                {title: 'Price', field: 'manual.price', filterCellStyle: {display: "none"}},
                                {
                                    title: 'Center ID', field: 'center.id', filterCellStyle: {display: "none"},
                                    headerStyle: {display: "none"}, cellStyle: {display: "none"}
                                },
                                {
                                    title: 'Sold by', field: 'center.name', filtering: false, render: rowData =>
                                        <div>
                                            <div>{rowData.center.name}</div>
                                            <div>ID: {rowData.center.id}</div>
                                        </div>
                                },
                                {
                                    title: 'Student ID', field: 'student.id', filterCellStyle: {display: "none"},
                                    headerStyle: {display: "none"}, cellStyle: {display: "none"}
                                },
                                {
                                    title: 'Student first',
                                    field: 'student.firstName',
                                    filterCellStyle: {display: "none"},
                                    headerStyle: {display: "none"},
                                    cellStyle: {display: "none"}
                                },
                                {
                                    title: 'Sold to', field: 'student.lastName', filtering: false, render: rowData =>
                                        <div>
                                            <div>{rowData.student.lastName}, {rowData.student.firstName}</div>
                                            <div>ID: {rowData.student.id}</div>
                                        </div>
                                },
                                {
                                    title: 'Sold at',
                                    field: 'createdAt',
                                    filtering: false,
                                    defaultFilter: this.state.yearFilter,
                                    render: rowData =>
                                        <Moment format="YYYY/MM/DD">
                                            {rowData.createdAt}
                                        </Moment>
                                },
                                {
                                    title: 'Invoiced at',
                                    field: 'invoicedAt',
                                    filtering: false,
                                    render: rowData =>
                                        rowData.invoicedAt ? <Moment format="YYYY/MM/DD">{rowData.invoicedAt}</Moment> :
                                            <p>---</p>
                                },
                                {
                                    title: 'Paid at',
                                    field: 'paidAt',
                                    filtering: false,
                                    render: rowData =>
                                        rowData.paidAt ? <Moment format="YYYY/MM/DD">{rowData.paidAt}</Moment> :
                                            <p>---</p>
                                },
                                {
                                    title: 'Deleted',
                                    field: 'isDeleted',
                                    render: rowData =>
                                    rowData.isDeleted ? <DeleteIcon/> :
                                            <div></div>
                                }
                            ]}
                                           data={this.props.allManualTransactions}
                                           icons={{
                                               Filter: () => (<SearchIcon style={{fontSize: 12}} color="primary"/>)
                                           }}
                                           title="Manual transactions list"
                                           options={{
                                               header: this.state.showHeader,
                                               filtering: false,
                                               search: true,
                                               paging: true,
                                               pageSize: this.state.pageSize,
                                               pageSizeOptions: [5, 10, 200],
                                               emptyRowsWhenPaging: false,
                                               actionsColumnIndex: 0,
                                               searchFieldAlignment: "left",
                                               showTitle: false,
                                               rowStyle: rowData => ({
                                                   backgroundColor: (rowData.paidAt != null) ? '#e0ffe0' :
                                                       ((rowData.invoicedAt != null) ? '#ffffe0' : '#ffe0e0')
                                               })
                                           }}
                                           components={{
                                               GroupRow: props => (
                                                   <tr style={{display: "block", padding: "10px"}}>
                                                       <td style={{display: "inline-block", width: "400px"}}>
                                                           <strong>{props.groups[0].title}: </strong>
                                                           {this.props.brevetTypes[props.groupData.value]
                                                               ? this.props.brevetTypes[props.groupData.value].label
                                                               : props.groupData.value}
                                                       </td>
                                                       <td style={{display: "inline-block", width: "100px"}}>
                                                           <strong>Issued:</strong> {props.groupData.data.length}</td>
                                                   </tr>
                                               )/*,
                                                   Pagination: props =>(
                                                       <MTablePagination {...props}>{console.log(props)}
                                                       </MTablePagination>
                                                   )*/
                                           }}
                                           actions={[
                                               {
                                                   icon: 'announcement',
                                                   tooltip: 'Comment',
                                                   onClick: (event, rowData) => {
                                                       this.setState({
                                                           selectedTransaction: {
                                                               id: rowData.id,
                                                               adminComment: rowData.adminComment,
                                                               invoicedAt: rowData.invoicedAt,
                                                               paidAt: rowData.paidAt,
                                                               isDeleted:rowData.isDeleted
                                                           },
                                                           showEditTransactionModal: true
                                                       });
                                                   }

                                               },
                                               {
                                                   icon: () => <Chip color={this.state.yearButtonColors.twenty}
                                                                     label="2020"/>,
                                                   tooltip: 'Show only transactions of 2020',
                                                   onClick: () => {
                                                       this.setState({
                                                           yearFilter: "2020",
                                                           yearButtonColors: {
                                                               "twenty": "primary",
                                                               "twentyone": "default",
                                                               "all": "default"
                                                           }
                                                       })
                                                   },
                                                   isFreeAction: true
                                               },
                                               {
                                                   icon: () => <Chip color={this.state.yearButtonColors.twentyone}
                                                                     label="2021"/>,
                                                   tooltip: 'Show only transactions of 2021',
                                                   onClick: () => {
                                                       this.setState({
                                                           yearFilter: "2021",
                                                           yearButtonColors: {
                                                               "twenty": "default",
                                                               "twentyone": "primary",
                                                               "all": "default"
                                                           }
                                                       })
                                                   },
                                                   isFreeAction: true
                                               },
                                               {
                                                   icon: () => <Chip color={this.state.yearButtonColors.all}
                                                                     label="All"/>,
                                                   tooltip: 'Show all transactions',
                                                   onClick: () => {
                                                       this.setState({
                                                           yearFilter: "",
                                                           yearButtonColors: {
                                                               "twenty": "default",
                                                               "twentyone": "default",
                                                               "all": "primary"
                                                           }
                                                       })
                                                   },
                                                   isFreeAction: true
                                               }]}
                                           localization={{header: {actions: "Options"}}}/>
                        </div>
                    </div>

                    <Modal isOpen={this.state.showEditTransactionModal}>
                        <ModalHeader>
                            <h2>Comment, invoice, mark as payed, delete</h2>
                        </ModalHeader>
                        <ModalBody>

                            <TextField
                                label="Comments"
                                multiline
                                rows="4"
                                variant="outlined"
                                value={(this.state.selectedTransaction.adminComment)}
                                onChange={(e) => this.handleTransactionFieldChange(e, "adminComment")}
                            />

                            <form noValidate>
                                <TextField
                                    label="Invoiced at"
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={(this.state.selectedTransaction.invoicedAt)}
                                    onChange={(e) => this.handleTransactionFieldChange(e, "invoicedAt")}/>
                            </form>

                            <form noValidate>
                                <TextField
                                    label="Paid at"
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={(this.state.selectedTransaction.paidAt)}
                                    onChange={(e) => this.handleTransactionFieldChange(e, "paidAt")}/>
                            </form>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.selectedTransaction.isDeleted}
                                        onChange={(e) => this.handleTransactionFieldChange(e, "isDeleted")}
                                        value={this.state.selectedTransaction.isDeleted}
                                        color="primary"
                                    />}
                                label="Remove manual from user"
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button style={{display: this.state.brevetCreateButton}} variant="contained" color="primary"
                                    onClick={() => {
                                        this.props.saveTransaction(this.state.selectedTransaction.id,
                                            this.state.selectedTransaction.adminComment,
                                            this.state.selectedTransaction.invoicedAt,
                                            this.state.selectedTransaction.paidAt,
                                            this.state.selectedTransaction.isDeleted);
                                        this.setState({
                                            showEditTransactionModal: false,
                                            selectedTransaction: {
                                                id: "",
                                                adminComment: "",
                                                invoicedAt: "",
                                                paidAt: "",
                                                isDeleted: false
                                            }
                                        })
                                    }}>Save</Button>
                            <Button variant="contained" color="primary"
                                    onClick={() => {
                                        this.setState({
                                            showEditTransactionModal: false,
                                            selectedTransaction: {
                                                id: "",
                                                adminComment: "",
                                                invoicedAt: "",
                                                paidAt: ""
                                            }
                                        })
                                    }}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        );
    }
}
