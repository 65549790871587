import React from 'react';
import {BrevetDetailView} from '../brevet/BrevetView';
import {CenterProfileView} from '../center/CenterView';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Moment from "react-moment";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/es/MenuItem";
import countryList from 'react-select-country-list';
import Chip from "@material-ui/core/Chip";

var fieldsToRender = [
    {"label": "User Name (for login)", "key": "userName"},
    {"label": "E-Mail", "key": "email"},
    {"label": "First Name", "key": "firstName"},
    {"label": "Last Name", "key": "lastName"},
    {"label": "Phone Number", "key": "phoneNumber"},
    {"label": "Street & Number", "key": "address", "subkey": "street"},
    {"label": "ZIP", "key": "address", "subkey": "zipCode"},
    {"label": "City", "key": "address", "subkey": "city"},
    {"label": "Country", "key": "address", "subkey": "country"},
    {"label": "Birth Date", "key": "birthDate"}];
var countrySelectOptions = countryList().getData();

export class UserProfileView extends React.Component {
    render() {
        return (
            <div>
                <div className="card-header">
                    <div className="row">
                        <div className="col-12">
                            <h2>User Profile of {this.props.user.firstName} {this.props.user.lastName}</h2>
                        </div>
                        <div className="col-12">
                            NSC ID: {this.props.user.id}
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-8 col-sm-12">
                            <div className="row">
                                {
                                    fieldsToRender.map((field) => {
                                        if ((!field.subkey)) {
                                            return (
                                                <div key={field.label} className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="caption-label">{field.label}</div>
                                                    <div className="data-field">{this.props.user[field.key]}</div>
                                                </div>)
                                        } else {
                                            return (
                                                <div key={field.label} className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="caption-label">{field.label}</div>
                                                    <div
                                                        className="data-field">{this.props.user[field.key][field.subkey]}</div>
                                                </div>)
                                        }
                                    })
                                }
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="row">

                                <div className="image-container">
                                    <img src={this.props.userImage} alt="Please upload."
                                         id="user-image" className="user-image"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export class UserProfileEditView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profileSaveButtonStatus: false
        }
    }

    render() {
        return (
            <div>
                <div className="card-header">
                    <h1>Edit Profile | NSC ID: {this.props.user.id}</h1>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-8 col-sm-12">
                                    <div className="row">
                                        {
                                            fieldsToRender.map((field) => {
                                                if ((!field.subkey)) {
                                                    //var fieldType;
                                                    var fieldProps;
                                                    if (field.key === "birthDate") {
                                                        //fieldType = "date";
                                                        fieldProps = {shrink: true}
                                                    }
                                                    return (
                                                        <div key={field.label} className="col-md-6 col-xs-12">
                                                            <TextField InputLabelProps={fieldProps}
                                                                       id={field.label}
                                                                       label={field.label}
                                                                       fullWidth
                                                                       value={this.props.user[field.key]}
                                                                       margin="dense"
                                                                       onChange={(e) => {
                                                                           this.props.handleUserFieldChange(e, field.key, null,);
                                                                           this.setState({profileSaveButtonStatus: true})
                                                                       }}/>
                                                        </div>)
                                                } else {
                                                    if (field.subkey !== "country") {
                                                        return (
                                                            <div key={field.label} className="col-md-6 col-xs-12">
                                                                <TextField
                                                                    id={field.label}
                                                                    label={field.label}
                                                                    fullWidth
                                                                    value={this.props.user[field.key][field.subkey]}
                                                                    margin="dense"
                                                                    onChange={(e) => this.props.handleUserFieldChange(e, field.key, field.subkey)}
                                                                />
                                                            </div>)
                                                    } else return (
                                                        <div key={field.label} className="col-md-6 col-xs-12">
                                                            <InputLabel shrink htmlFor="country">Country</InputLabel>
                                                            <Select
                                                                id={field.label}
                                                                label={field.label}
                                                                fullWidth
                                                                value={this.props.user[field.key][field.subkey]}
                                                                margin="dense"
                                                                onChange={(e) => this.props.handleUserFieldChange(e, field.key, field.subkey)}
                                                            >
                                                                {countrySelectOptions.map((country) => {
                                                                    return (
                                                                        <MenuItem key={country.value}
                                                                                  value={country.value}>{country.label}</MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </div>)
                                                }
                                            })}
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div>
                                        <div className="row">
                                            <div className="image-container">
                                                <img src={this.props.userImage} alt="Please upload."
                                                     id="user-image" className="user-image"/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <label htmlFor="image-upload" className="file-upload-button">
                                                Upload Image
                                            </label>
                                            <input type='file' id='image-upload'
                                                   onChange={(e) => {
                                                       this.props.handleSelectedFile(e);
                                                       this.setState({
                                                           profileSaveButtonStatus: true
                                                       })
                                                   }}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export class UserPasswordView extends React.Component {
    render() {
        return (
            <div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6 col-xs-12">
                            <TextField
                                id="password"
                                label="Password"
                                type="password"
                                fullWidth
                                value={this.props.newUserPassword}
                                margin="dense"
                                onChange={(e) => {
                                    this.props.handlePasswordFieldChange(e, "first");
                                }}
                            />
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <TextField
                                id="password2"
                                label="Repeat Password"
                                type="password"
                                fullWidth
                                value={this.props.newUserPasswordRepeat}
                                margin="dense"
                                onChange={(e) => {
                                    this.props.handlePasswordFieldChange(e, "second");
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export class UserCenterView extends React.Component {
    render() {
        return (
            <div>
                <div className="card-header">
                    <h1>My Center</h1>
                </div>
                <CenterProfileView
                    center={this.props.center}/>
                <div className="card-footer">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <Button variant="contained" color="primary"
                                    onClick={this.props.toggleShowChooseCenterModal}>
                                Change Center
                            </Button>
                        </div>
                    </div>
                </div>

                <Modal isOpen={this.props.showChooseCenterModal}>
                    <ModalHeader>
                        <div>Choose your new center by its NSC ID</div>
                    </ModalHeader>
                    <ModalBody>
                        <TextField
                            id="centerIdChooser"
                            label="Center ID"
                            fullWidth
                            margin="dense"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="contained" color="primary"
                                onClick={() => {
                                    this.props.saveUserCenter(document.getElementById("centerIdChooser").value)
                                }}>Change to this center</Button>
                        <Button variant="contained" color="primary"
                                onClick={() => {
                                    this.props.toggleShowChooseCenterModal()
                                }}>Cancel</Button>
                    </ModalFooter>
                </Modal>

            </div>
        )
    }
}

export class UserBrevetsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showBrevetModal: false,
            selectedBrevet: {}
        };
    }

    render() {
        return (
            <div>
                <div className="card-header">
                    <h1>My Brevets | NSC ID: {this.props.user.id}</h1>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <MaterialTable columns={[
                                {title: 'Serial', field: 'id'},
                                {title: 'Level', field: 'level'},
                                {title: 'Trainer First Name', field: 'trainer.firstName'},
                                {title: 'Trainer Last Name', field: 'trainer.lastName'},
                                {title: 'Center', field: 'center.name'},
                                {
                                    title: 'Created', field: 'createdAt', render: rowData =>
                                        <Moment format="YYYY/MM/DD">
                                            {rowData.createdAt}
                                        </Moment>
                                }]}
                                           data={this.props.brevets}
                                           title="Brevetliste"
                                           options={{
                                               search: true,
                                               paging: false,
                                               actionsColumnIndex: 0,
                                               searchFieldAlignment: "left",
                                               showTitle: false
                                           }}
                                           actions={[
                                               {
                                                   icon: 'info',
                                                   tooltip: 'Show info',
                                                   onClick: (event, rowData) => {
                                                       this.setState({
                                                           showBrevetModal: !this.state.showBrevetModal,
                                                           selectedBrevet: rowData
                                                       })
                                                   }
                                               }]}
                                           localization={{header: {actions: "Options"}}}/>
                        </div>
                    </div>
                    <Modal isOpen={this.state.showBrevetModal}>
                        <ModalBody>
                            <BrevetDetailView brevet={this.state.selectedBrevet}/>

                        </ModalBody>
                        <ModalFooter>
                            <Button variant="contained" color="primary"
                                    onClick={() => this.setState({showBrevetModal: false})}>Close</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        );
    }
}

export class UserManualsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showManualModal: false
        };
    }

    render() {
        return (
            <div>
                <div className="card-header">
                    <h1>My Manuals | NSC ID: {this.props.user.id}</h1>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <MaterialTable columns={[
                                {title: 'Manual', field: 'title'},
                                {
                                    title: 'Preview', field: 'previewImageUrl', filtering: false, render: rowData =>
                                        <div>
                                            <img src={rowData.previewImageUrl}
                                            width="100px" alt="Preview not available"/>
                                        </div>
                                },]}
                                           data={this.props.manuals}
                                           title="List of your available manauls"
                                           options={{
                                               search: true,
                                               paging: false,
                                               actionsColumnIndex: 2,
                                               searchFieldAlignment: "left",
                                               showTitle: false
                                           }}
                                           actions={[
                                               {
                                                   icon: 'menu_book',
                                                   tooltip: 'Read manual',
                                                   onClick: (event, rowData) => {
                                                       this.props.getManual(rowData.id);
                                                       this.setState({
                                                           showManualModal: !this.state.showManualModal,
                                                       })
                                                   }
                                               }]}
                                           localization={{header: {actions: "Options"}}}/>
                        </div>
                    </div>
                    <Modal isOpen={this.state.showManualModal} className="modal-dialog">
                        <ModalHeader>
                            {this.props.selectedManual.title} preview
                            <div className="close-button">
                                <Chip color="primary" size="small" label="X"
                                      onClick={() => this.setState({showManualModal: !this.state.showManualModal})}/>
                            </div>
                        </ModalHeader>

                        <iframe className="manual" srcDoc={this.props.selectedManual.sources[0]}  title="manual-preview"></iframe>

                    </Modal>
                </div>
            </div>
        );
    }
}