import React from 'react';
import MaterialTable from 'material-table';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {UserProfileView} from "../user/UserView";
import {BrevetDetailView} from '../brevet/BrevetView';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Moment from "react-moment";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/es/MenuItem";
import countryList from 'react-select-country-list'
import Chip from "@material-ui/core/Chip";
import SearchIcon from '@material-ui/icons/Search';

var userFieldsToRender = [
    {"label": "User Name (for login)", "key": "userName"},
    {"label": "E-Mail", "key": "email"},
    {"label": "First Name", "key": "firstName"},
    {"label": "Last Name", "key": "lastName"},
    {"label": "Phone Number", "key": "phoneNumber"},
    {"label": "Street & Number", "key": "address", "subkey": "street"},
    {"label": "ZIP", "key": "address", "subkey": "zipCode"},
    {"label": "City", "key": "address", "subkey": "city"},
    {"label": "Country", "key": "address", "subkey": "country"},
    {"label": "Birth Date", "key": "birthDate"}];
var centerFieldsToRender = [
    {"label": "Name", "key": "name"},
    {"label": "Owner", "key": "owner"},
    {"label": "Phone", "key": "phoneNumber"},
    {"label": "E-Mail", "key": "email"},
    {"label": "Street & No.", "key": "address", "subkey": "street"},
    {"label": "Add. Info", "key": "address", "subkey": "additional"},
    {"label": "ZIP", "key": "address", "subkey": "zipCode"},
    {"label": "City", "key": "address", "subkey": "city"},
    {"label": "State", "key": "address", "subkey": "state"},
    {"label": "Country", "key": "address", "subkey": "country"},];
var countrySelectOptions= countryList().getData();

export class CenterProfileView extends React.Component {
    render() {
        return (
            <div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>{this.props.center.name}</h2>
                            <div hidden={this.props.center.level==="FREELANCER"}>
                                ID:{this.props.center.id} | Level: {this.props.center.level}
                            </div>
                            <div hidden={this.props.center.level!=="FREELANCER"}>
                                ID:{this.props.center.id}
                            </div>
                            <hr/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                {
                                    centerFieldsToRender.map((field) => {
                                        if (!field.subkey) {
                                            return (
                                                <div key={field.label} className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="caption-label">{field.label}</div>
                                                    <div className="data-field">{this.props.center[field.key]}</div>
                                                </div>)
                                        } else {
                                            return (
                                                <div key={field.label} className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="caption-label">{field.label}</div>
                                                    <div
                                                        className="data-field">{this.props.center[field.key][field.subkey]}</div>
                                                </div>)
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export class CenterProfileEditView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showProfileModal: false,
            showKickModal: false,
            showCreateManagerModal:false,
            selectedUser: {}
        };
    }

    render() {
        return (
            <div>
                <div className="card-header">
                    <h1>Centerprofile</h1>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>{this.props.center.name}</h2>
                            ID:{this.props.center.id} | Level: {this.props.center.level}
                            <hr/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                {
                                    centerFieldsToRender.map((field) => {
                                        if ((!field.subkey)) {
                                            return (
                                                <div key={field.label} className="col-md-6 col-xs-12">
                                                    <TextField
                                                        id={field.label}
                                                        label={field.label}
                                                        fullWidth
                                                        value={(this.props.center[field.key]) ? this.props.center[field.key] : ""}
                                                        margin="dense"
                                                        onChange={(e) => this.props.handleCenterFieldChange(e, field.key, null)}
                                                    />
                                                </div>)
                                        } else {
                                            return (
                                                <div key={field.label} className="col-md-6 col-xs-12">
                                                    <TextField
                                                        id={field.label}
                                                        label={field.label}
                                                        fullWidth
                                                        value={(this.props.center[field.key][field.subkey])
                                                            ? this.props.center[field.key][field.subkey] : ""}
                                                        margin="dense"
                                                        onChange={(e) => this.props.handleCenterFieldChange(e, field.key, field.subkey)}
                                                    />
                                                </div>)
                                        }
                                    })
                                }
                                <hr/>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <MaterialTable columns={[
                                                {title: 'ID', field: 'id'},
                                                {title: 'First', field: 'firstName'},
                                                {title: 'Last', field: 'lastName'}]}
                                                           data={this.props.managers}
                                                           title="Center Managers"
                                                           options={{
                                                               filtering: false,
                                                               search: false,
                                                               toolbar: true,
                                                               actionsColumnIndex: 0
                                                           }}
                                                           actions={[
                                                               {
                                                                   icon: 'cancel',
                                                                   tooltip: 'Remove manager role',
                                                                   onClick: (event, rowData) => {
                                                                       this.setState({
                                                                           showKickModal: true,
                                                                           selectedUser: rowData
                                                                       })

                                                                   }
                                                               },
                                                               {
                                                                   icon: 'add_circle',
                                                                   tooltip: 'Add Manager',
                                                                   onClick: () => {
                                                                       this.setState({showCreateManagerModal:true})
                                                                   },
                                                                   isFreeAction: true
                                                               }
                                                           ]}
                                                           localization={{header: {actions: "Options"}}}/>
                                        </div>
                                    </div>
                                    <Modal isOpen={this.state.showKickModal}>
                                        <ModalBody>
                                            Do you really want to
                                            remove {this.state.selectedUser.firstName} {this.state.selectedUser.lastName} as
                                            manager from this center?
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button variant="contained" color="primary"
                                                    onClick={() => this.setState({showKickModal: false})}>Cancel
                                            </Button>
                                            <Button variant="contained" color="primary" onClick={() => {
                                                this.props.kickManager(this.props.center.id, this.state.selectedUser.id);
                                                this.setState({showKickModal: false})
                                            }}>Confirm
                                            </Button>

                                        </ModalFooter>
                                    </Modal>
                                    <Modal isOpen={this.state.showCreateManagerModal}>
                                        <ModalBody>
                                            <div className="row">
                                                <div className="col-12">
                                                    You can choose a user by ID to make them a manager of this center.
                                                </div>
                                                <div className="col-12">
                                                    <TextField
                                                        id="userIdChooser"
                                                        label="User ID"
                                                        margin="dense"/>
                                                </div>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button variant="contained" color="primary"
                                                    onClick={() => this.setState({showCreateManagerModal: false})}>Cancel
                                            </Button>
                                            <Button variant="contained" color="primary"
                                                    onClick={() => {
                                                        this.props.saveUserAsManager(document.getElementById("userIdChooser").value);
                                                        this.setState({showCreateManagerModal: false});
                                                    }}>Make user to center manager</Button>
                                        </ModalFooter>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export class CenterStudentsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showProfileModal: false,
            selectNewBrevetType: "OWD",
            selectedUser: {}
        };
    }

    render() {
        return (
            <div>
                <div className="card-header">
                    <h1>Students of {this.props.center.name}</h1>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <MaterialTable columns={[
                                {title: 'ID', field: 'id'},
                                {title: 'First', field: 'firstName'},
                                {title: 'Last', field: 'lastName'}]}
                                           data={this.props.students}
                                           title="Students"
                                           options={{
                                               search: true,
                                               paging: false,
                                               actionsColumnIndex: 0,
                                               searchFieldAlignment: "left",
                                               showTitle: false
                                           }}
                                           actions={[
                                               {
                                                   icon: 'info',
                                                   tooltip: 'Show info',
                                                   onClick: (event, rowData) => {
                                                       this.props.getUserImage(rowData.id);
                                                       this.setState({
                                                           showProfileModal: !this.state.showProfileModal,
                                                           selectedUser: rowData
                                                       })
                                                   }
                                               },
                                               {
                                                   icon: 'import_contacts',
                                                   tooltip: 'Sell Manual',
                                                   onClick: (event, rowData) => {
                                                       this.props.getUserImage(rowData.id);
                                                       this.setState({
                                                           selectedUser: rowData
                                                       });
                                                       this.props.toggleManualSalesModal();
                                                   }
                                               },
                                               {
                                                   icon: 'add_circle',
                                                   tooltip: 'Add User',
                                                   onClick: () => {
                                                       this.props.toggleCreateUserModal()
                                                   },
                                                   isFreeAction: true
                                               }]}
                                           localization={{header: {actions: "Options"}}}/>
                        </div>
                    </div>

                    <Modal isOpen={this.state.showProfileModal}>
                        <ModalBody>
                            <UserProfileView user={this.state.selectedUser}
                                             userImage={this.props.userImage}/>

                        </ModalBody>
                        <ModalFooter>
                            <Button variant="contained" color="primary"
                                    onClick={() => window.location.href = "mailto:" + this.state.selectedUser.email}>Send
                                E-Mail</Button>
                            <Button variant="contained" color="primary"
                                    onClick={() => this.setState({showProfileModal: false})}>Close</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.props.showCreateUserModal}>
                        <ModalBody>
                            <CenterUserCreateModal constants={this.props.constants}
                                                   newUser={this.props.newUser}
                                                   handleUserFieldChange={this.props.handleUserFieldChange}
                                                   handleSelectedFile={this.props.handleSelectedFile}
                                                   userImage={this.props.userImage}
                                                   uploadUserImage={this.props.uploadUserImage}/>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="contained" color="primary"
                                    onClick={() => {
                                        this.props.saveNewCenterStudent(this.props.newUser)
                                    }}>Save
                            </Button>
                            <Button variant="contained" color="primary"
                                    onClick={() => {
                                        this.props.toggleCreateUserModal();
                                        this.props.clearUserImage()
                                    }}>Close</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.props.showManualSalesModal}>
                        <CenterManualSalesView  manuals={this.props.manuals}
                                                selectedUser={this.state.selectedUser}
                                                toggleManualSalesModal={this.props.toggleManualSalesModal}
                                                saveManualTransaction={this.props.saveManualTransaction}/>
                    </Modal>
                </div>
            </div>)
    }
}
export class CenterUserCreateModal extends React.Component {
    render() {
        return (
            <div>
                <div className="card-header">
                    <h2>Create User</h2>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-8 col-sm-12">
                            <div className="row">
                                {
                                    userFieldsToRender.map((field) => {
                                        if ((!field.subkey)) {
                                            //var fieldType;
                                            var fieldProps;
                                            if (field.key === "birthDate") {
                                                //fieldType = "date";
                                                fieldProps = {shrink: true}
                                            }
                                            return (
                                                <div key={field.label} className="col-md-6 col-xs-12">
                                                    <TextField InputLabelProps={fieldProps}
                                                               id={field.label}
                                                               label={field.label}
                                                               fullWidth
                                                               value={this.props.newUser[field.key]}
                                                               margin="dense"
                                                               onChange={(e) => this.props.handleUserFieldChange(e, field.key, null)}
                                                    />
                                                </div>)
                                        } else {
                                            if (field.subkey!=="country") {
                                                return (
                                                    <div key={field.label} className="col-md-6 col-xs-12">
                                                        <TextField
                                                            id={field.label}
                                                            label={field.label}
                                                            fullWidth
                                                            value={this.props.newUser[field.key][field.subkey]}
                                                            margin="dense"
                                                            onChange={(e) => this.props.handleUserFieldChange(e, field.key, field.subkey)}
                                                        />
                                                    </div>)
                                            }
                                            else return (
                                                <div key={field.label} className="col-md-6 col-xs-12">
                                                    <InputLabel shrink htmlFor="country">Country</InputLabel>
                                                    <Select
                                                        id={field.label}
                                                        label={field.label}
                                                        fullWidth
                                                        value={this.props.newUser[field.key][field.subkey]}
                                                        margin="dense"
                                                        onChange={(e) => this.props.handleUserFieldChange(e, field.key, field.subkey)}
                                                    >
                                                        {countrySelectOptions.map((country) => {
                                                            return (
                                                                <MenuItem value={country.value}>{country.label}</MenuItem>
                                                            )})}
                                                    </Select>
                                                </div>)
                                        }
                                    })}
                                <div key="password" className="col-md-6 col-xs-12">
                                    <TextField
                                        id="password"
                                        label="Password"
                                        type="password"
                                        fullWidth
                                        value={this.props.newUser.password}
                                        margin="dense"
                                        onChange={(e) => this.props.handleUserFieldChange(e, "password", null)}
                                    />
                                </div>
                                <div key="passwordRepeat" className="col-md-6 col-xs-12">
                                    <TextField
                                        id="passwordRepeat"
                                        label="Repeat Password"
                                        type="password"
                                        fullWidth
                                        value={this.props.newUser.passwordRepeat}
                                        margin="dense"
                                        onChange={(e) => this.props.handleUserFieldChange(e, "passwordRepeat", null)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div>
                                <div className="row">
                                    <div className="image-container">
                                        <img src={this.props.userImage} alt="Please upload."
                                             id="user-image" className="user-image"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <label htmlFor="image-upload" className="file-upload-button">
                                        Upload Image
                                    </label>
                                    <input type='file' id='image-upload'
                                           onChange={(e) => {
                                               this.props.handleSelectedFile(e);
                                           }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export class CenterTrainersView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCreateTrainerModal: false,
            showKickTrainerModal:false,
            selectedUser: {}
        };
    }

    render() {
        return (
            <div>
                <div className="card-header">
                    <h1>Trainers of {this.props.center.name}</h1>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h2>Pending Trainers</h2>
                            <hr/>
                        </div>
                        <div className="col-12">
                            <MaterialTable columns={[
                                {title: 'ID', field: 'id'},
                                {title: 'First', field: 'firstName'},
                                {title: 'Last', field: 'lastName'}]}
                                           data={this.props.aspirants}
                                           title="Trainer Aspirants"
                                           options={{
                                               toolbar: false,
                                               search: false,
                                               paging: false,
                                               actionsColumnIndex: 0,
                                               searchFieldAlignment: "left",
                                               showTitle: false
                                           }}
                                           actions={[
                                               {
                                                   icon: 'check_circle',
                                                   tooltip: '',
                                                   onClick: (event, rowData) => {
                                                       this.props.acceptAspirant(this.props.center.id, rowData.id)
                                                   }
                                               }]}
                                           localization={{header: {actions: "Options"}}}/>
                        </div>
                        <div className="col-12"><br/></div>
                        <div className="col-12">
                            <h2>Practicing Trainers</h2>
                            <hr/>
                        </div>
                        <div className="col-12">
                            <MaterialTable columns={[
                                {title: 'ID', field: 'id'},
                                {title: 'First', field: 'firstName'},
                                {title: 'Last', field: 'lastName'}]}
                                           data={this.props.trainers}
                                           title="Practicing Trainers"
                                           options={{
                                               search: true,
                                               paging: false,
                                               actionsColumnIndex: 0,
                                               searchFieldAlignment: "left",
                                               showTitle: false
                                           }}
                                           actions={[
                                               {
                                                   icon: 'cancel',
                                                   tooltip: 'Remove as trainer',
                                                   onClick: (event, rowData) => {
                                                       this.setState({selectedUser:rowData,
                                                           showKickTrainerModal:true});
                                                   }
                                               }]}
                                           localization={{header: {actions: "Options"}}}/>
                        </div>
                    </div>
                    <Modal isOpen={this.state.showCreateTrainerModal}>
                        <ModalBody>
                            <CenterUserCreateModal constants={this.props.constants}
                                                   newUser={this.props.newUser}
                                                   handleUserFieldChange={this.props.handleUserFieldChange}/>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="contained" color="primary"
                                    onClick={() => {
                                        this.props.saveNewCenterTrainer(this.props.newUser);
                                        this.setState({showCreateTrainerModal: false})
                                    }}>Save
                            </Button>
                            <Button variant="contained" color="primary"
                                    onClick={() => this.setState({showCreateTrainerModal: false})}>Close</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.showKickTrainerModal}>
                        <ModalBody>
                            <div>Do you really want to kick {this.state.selectedUser.firstName} {this.state.selectedUser.lastName} as a trainer from this center? </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="contained" color="primary"
                                    onClick={() => {
                                        this.props.kickTrainer(this.props.center.id, this.state.selectedUser.id);
                                        this.setState({showKickTrainerModal: false})
                                    }}>Kick
                            </Button>
                            <Button variant="contained" color="primary"
                                    onClick={() => this.setState({showKickTrainerModal: false})}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        )
    }
}

export class CenterBrevetsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showBrevetModal: false,
            showValidationModal: false,
            selectedBrevet: {},
            selectedVoucher: "",
            brevetLevelGrouping: {title: 'Brevet level', field: 'level', filtering: false, render: rowData =>
                    <div>
                        {this.props.brevetTypes[rowData.level] ? this.props.brevetTypes[rowData.level].label : "loading"}
                    </div>
            },
            showHeader: true,
            yearFilter:"",
            yearButtonColors: {"nineteen": "default", "twenty": "default", "all": "primary"},
            groupButtonColor: "default",
            tablePagination:true,
            pageSize:10,
            columnsFilter:true
        };
    }

    getBackSideURL(brevetID) {
        var url = this.props.constants.baseFrontURL+"backsides/"+brevetID+".pdf";
        var http = new XMLHttpRequest();
        http.open('HEAD', url, false);
        http.send();
        if (http.status === 200) {
            return url;
        } else {
            return this.props.constants.baseFrontURL+"backsides/_FALLBACK.pdf";
        }
    }

    render() {
        return (
            <div>
                <div className="card-header">
                    <h1>Issued Brevets of {this.props.center.name}</h1>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12" hidden={!this.props.unapprovedBrevets.length}>
                            <h2>Unvalidated Brevets (fee will be charged)</h2>
                            <hr/>
                        </div>
                        <div className="col-12" hidden={!this.props.unapprovedBrevets.length}>
                            <MaterialTable columns={[
                                {title: 'Serial', field: 'id'},
                                {title: 'Brevet level', field: 'level', render: rowData =>
                                        <div>
                                            {this.props.brevetTypes[rowData.level] ? this.props.brevetTypes[rowData.level].label : "loading"}
                                        </div>
                                },
                                {
                                    title: 'Created', field: 'createdAt', render: rowData =>
                                        <Moment format="YYYY/MM/DD">
                                            {rowData.createdAt}
                                        </Moment>
                                },
                                {title: 'Student first', field: 'student.firstName',
                                    headerStyle:{display:"none"}, cellStyle:{display:"none"}},
                                {title: 'Student', field: 'student.lastName', render: rowData =>
                                        <div>
                                            <div>{rowData.student.lastName}, {rowData.student.firstName}</div>
                                            <div>ID: {rowData.student.id}</div>
                                        </div>},
                                {title: 'Student ID', field: 'student.id',
                                    headerStyle:{display:"none"}, cellStyle:{display:"none"}},
                                {title: 'Trainer first', field: 'trainer.firstName',
                                    headerStyle:{display:"none"}, cellStyle:{display:"none"}},
                                {title: 'Trainer', field: 'trainer.lastName', render: rowData =>
                                        <div>
                                            <div>{rowData.trainer.lastName}, {rowData.trainer.firstName}</div>
                                            <div>ID: {rowData.trainer.id}</div>
                                        </div>},
                                {title: 'Trainer ID', field: 'trainer.id',
                                    headerStyle:{display:"none"}, cellStyle:{display:"none"}}
                            ]}
                                           data={this.props.unapprovedBrevets}
                                           title="Brevet list"
                                           options={{
                                               toolbar: false,
                                               paging: false,
                                               actionsColumnIndex: 0
                                           }}
                                           actions={[
                                               {
                                                   icon: 'grade',
                                                   tooltip: 'Validate brevet',
                                                   onClick: (event, rowData) => {
                                                       this.setState({
                                                           showValidationModal: !this.state.showValidationModal,
                                                           selectedBrevet: rowData,
                                                           selectedVoucher: this.props.voucherTypes[this.props.brevetTypes[rowData.level].requiredVoucherType]
                                                       })
                                                   }
                                               },
                                               {
                                                   icon: 'cancel',
                                                   tooltip: 'Delete brevet',
                                                   onClick: (event, rowData) => {
                                                       this.props.deleteUnapprovedBrevet(rowData);
                                                   }
                                               }]}
                                           localization={{header: {actions: "Options"}}}/>
                        </div>
                        <div className="col-12"><br/></div>
                        <div className="col-12">
                            <h2>Validated Brevets</h2>
                            <div style={{fontSize: 12, color: "gray"}}>To get a specific report you must filter by trainer and then click on "Report".
                                <br/>If you don't filter, you will get a report for all brevets.
                                <br/>Additionally, you can use the search field in the left corner and the buttons in the right corner to filter by year. </div>
                            <hr/>
                        </div>
                        <div className="col-12">
                            <MaterialTable columns={[
                                {title: 'Serial', field: 'id', filtering: false},
                                this.state.brevetLevelGrouping,
                                {
                                    title: 'Created', field: 'createdAt',filtering: false, defaultFilter: this.state.yearFilter, render: rowData =>
                                        <Moment format="YYYY/MM/DD">
                                            {rowData.createdAt}
                                        </Moment>
                                },
                                {title: 'Student ID', field: 'student.id',filterCellStyle: {display: "none"},
                                    headerStyle:{display:"none"}, cellStyle:{display:"none"}},
                                {title: 'Student first', field: 'student.firstName',filterCellStyle: {display: "none"},
                                    headerStyle:{display:"none"}, cellStyle:{display:"none"}},
                                {title: 'Student', field: 'student.lastName', filtering: false, render: rowData =>
                                        <div>
                                            <div>{rowData.student.lastName}, {rowData.student.firstName}</div>
                                            <div>ID: {rowData.student.id}</div>
                                        </div>},
                                {title: 'Trainer ID', field: 'trainer.id',
                                    headerStyle:{display:"none"}, cellStyle:{display:"none"}},
                                {title: 'Trainer first', field: 'trainer.firstName',filterCellStyle: {display: "none"},
                                    headerStyle:{display:"none"}, cellStyle:{display:"none"}},
                                {title: 'Trainer (filter by ID)', field: 'trainer.lastName', filtering:false,filterCellStyle: {display: "none"},render: rowData =>
                                        <div>
                                            <div>{rowData.trainer.lastName}, {rowData.trainer.firstName}</div>
                                            <div>ID: {rowData.trainer.id}</div>
                                        </div>}
                            ]}
                                           data={this.props.brevets}
                                           icons={{
                                               Filter: () => (<SearchIcon style={{ fontSize: 12 }} color="primary"/>)
                                           }}
                                           title="Brevet list"
                                           options={{
                                               header: this.state.showHeader,
                                               filtering: this.state.columnsFilter,
                                               search: true,
                                               paging: true,
                                               pageSize: this.state.pageSize,
                                               pageSizeOptions:[5,10,200],
                                               emptyRowsWhenPaging:false,
                                               actionsColumnIndex: 0,
                                               searchFieldAlignment: "left",
                                               showTitle: false
                                           }}
                                           components={{
                                               GroupRow: props => (
                                                   <tr style={{display:"block", padding:"10px"}}>
                                                       <td style={{display:"inline-block", width:"400px"}}>
                                                           <strong>{props.groups[0].title}: </strong>
                                                           {this.props.brevetTypes[props.groupData.value]
                                                               ? this.props.brevetTypes[props.groupData.value].label
                                                               : props.groupData.value}
                                                       </td>
                                                       <td style={{display:"inline-block", width:"100px"}}>
                                                           <strong>Issued:</strong> {props.groupData.data.length}</td>
                                                   </tr>
                                               )/*,
                                                   Pagination: props =>(
                                                       <MTablePagination {...props}>{console.log(props)}
                                                       </MTablePagination>
                                                   )*/
                                           }}
                                           actions={[
                                               {
                                                   icon: 'info',
                                                   tooltip: 'Show info',
                                                   onClick: (event, rowData) => {
                                                       this.setState({
                                                           showBrevetModal: !this.state.showBrevetModal,
                                                           selectedBrevet: rowData
                                                       })
                                                   }
                                               },
                                               {
                                                   icon: 'credit_card',
                                                   tooltip: 'Download front side',
                                                   onClick: (event, rowData) => {
                                                       window.open(this.props.constants.baseAPIURL + "center/" + rowData.center.id + "/brevet/" + rowData.id + "/pdf")
                                                   }
                                               },
                                               {
                                                   icon: 'panorama',
                                                   tooltip: 'Download back side',
                                                   onClick: (event, rowData) => {
                                                       const download = document.createElement('a');
                                                       download.href = this.getBackSideURL(rowData.level);
                                                       download.target = "_blank";
                                                       document.body.appendChild(download);
                                                       download.click();
                                                       document.body.removeChild(download);
                                                   }
                                               },
                                               {
                                                   icon: () => <Chip color={this.state.yearButtonColors.nineteen} label="2019"/>,
                                                   tooltip: 'Show only brevets of 2019',
                                                   onClick: () => {
                                                       this.setState({yearFilter: "2019",
                                                           yearButtonColors: {"nineteen": "primary", "twenty": "default", "all": "default"}});
                                                   },
                                                   isFreeAction: true
                                               },
                                               {
                                                   icon: () => <Chip color={this.state.yearButtonColors.twenty} label="2020"/>,
                                                   tooltip: 'Show only brevets of 2020',
                                                   onClick: () => {
                                                       this.setState({yearFilter: "2020",
                                                           yearButtonColors: {"nineteen": "default", "twenty": "primary", "all": "default"}})
                                                   },
                                                   isFreeAction: true
                                               },
                                               {
                                                   icon: () => <Chip color={this.state.yearButtonColors.all} label="All"/>,
                                                   tooltip: 'Show all years',
                                                   onClick: () => {
                                                       this.setState({yearFilter: "",
                                                           yearButtonColors: {"nineteen": "default", "twenty": "default", "all": "primary"}})
                                                   },
                                                   isFreeAction: true
                                               },
                                               {
                                                   icon: () => <Chip color="secondary" variant="outlined"
                                                                     label={this.state.groupButtonColor==="default" ? "Report" : "List"}/>,
                                                   tooltip: 'Group brevets by level',
                                                   onClick: () => {
                                                       this.setState({tablePagesSize:200});
                                                       if(this.state.brevetLevelGrouping.defaultGroupOrder) {
                                                           this.setState({
                                                               brevetLevelGrouping: {title: 'Brevet level', field: 'level', filtering: false, render: rowData =>
                                                                       <div>
                                                                           {this.props.brevetTypes[rowData.level] ? this.props.brevetTypes[rowData.level].label : "loading"}
                                                                       </div>
                                                               },
                                                               showHeader:true,
                                                               groupButtonColor:"default",
                                                               tablePagination:true,
                                                               pageSize:10,
                                                               columnsFilter:true
                                                           });
                                                       }
                                                       else {
                                                           this.setState({
                                                               brevetLevelGrouping: {title: 'Brevet level', field: 'level', filtering: false, defaultGroupOrder: 1, render: rowData =>
                                                                       <div>
                                                                           {this.props.brevetTypes[rowData.level] ? this.props.brevetTypes[rowData.level].label : "loading"}
                                                                       </div>
                                                               },
                                                               showHeader:false,
                                                               groupButtonColor:"primary",
                                                               tablePagination:false,
                                                               pageSize:50,
                                                               columnsFilter:false
                                                           });
                                                       }
                                                   },
                                                   isFreeAction: true
                                               }]}
                                           localization={{header: {actions: "Options"}}}/>
                        </div>
                    </div>

                    <Modal isOpen={this.state.showBrevetModal}>
                        <ModalBody>
                            <BrevetDetailView brevet={this.state.selectedBrevet}/>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="contained" color="primary"
                                    onClick={() => this.setState({showBrevetModal: false})}>Close</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.showValidationModal}>
                        <ModalBody>
                            <BrevetDetailView brevet={this.state.selectedBrevet}/>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Print & Shipping Service</FormLabel>
                                            <RadioGroup
                                                aria-label="Print Service"
                                                name="printService"
                                                value={this.props.newBrevetSendTo}
                                                onChange={(e) => {
                                                    this.props.handleBrevetSendToChange(e.target.value)
                                                }}
                                            >
                                                <FormControlLabel value="NONE" control={<Radio/>}
                                                                  label="No print service - center has a printer"/>
                                                <FormControlLabel value="CENTER" control={<Radio/>}
                                                                  label="Print and ship to center. Fee will be charged."/>
                                                <FormControlLabel value="STUDENT" control={<Radio/>}
                                                                  label="Print and ship to student. Fee will be charged."/>
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <div className="col-12">
                                        <div className="attention">ATTENTION!</div>
                                        <div>You will be charged by NSC for the brevet code.</div>
                                        <div>If you choose the brevet to be shipped, additional fees will be charged.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="contained" color="primary"
                                    onClick={() => {
                                        this.setState({showValidationModal: false});
                                        this.props.approveBrevet(this.state.selectedBrevet.id)
                                    }}>Validate</Button>
                            <Button variant="contained" color="primary"
                                    onClick={() => {
                                        this.setState({showValidationModal: false});
                                        this.props.getCenterPrintDefault(this.props.center.hasPrinter)
                                    }}>Close</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        );
    }
}

export class CenterManualTransactionsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showHeader: true,
            yearFilter: "",
            yearButtonColors: {"twenty": "default", "twentyone": "default", "all": "primary"},
            tablePagination: true,
            pageSize:10,
            columnsFilter: true
        };
    }

    render() {
        return (
            <div>
                <div className="card-header">
                    <h1>Sold Manuals of {this.props.center.name}</h1>
                </div>
                <div className="card-body">
                    <div className="row">

                        <div className="col-12">
                            <MaterialTable columns={[
                                {title: 'Serial', field: 'id', filtering: false},
                                {title: 'Title', field: 'manual.title', filtering: false},
                                {
                                    title: 'Student first',
                                    field: 'student.firstName',
                                    filterCellStyle: {display: "none"},
                                    headerStyle: {display: "none"},
                                    cellStyle: {display: "none"}
                                },
                                {
                                    title: 'Student ID',
                                    field: 'student.id',
                                    filterCellStyle: {display: "none"},
                                    headerStyle: {display: "none"},
                                    cellStyle: {display: "none"}
                                },
                                {
                                    title: 'Student', field: 'student.lastName', filtering: false, render: rowData =>
                                        <div>
                                            <div>{rowData.student.lastName}, {rowData.student.firstName}</div>
                                            <div>ID: {rowData.student.id}</div>
                                        </div>
                                },
                                {
                                    title: 'Sold at',
                                    field: 'createdAt',
                                    filtering: false,
                                    defaultFilter: this.state.yearFilter,
                                    render: rowData =>
                                        <Moment format="YYYY/MM/DD">
                                            {rowData.createdAt}
                                        </Moment>
                                },
                                {
                                    title: 'Invoiced at',
                                    field: 'invoicedAt',
                                    filtering: false,
                                    render: rowData =>
                                        rowData.invoicedAt ? <Moment format="YYYY/MM/DD">{rowData.invoicedAt}</Moment> :
                                            <p>---</p>
                                },
                                {
                                    title: 'Paid at',
                                    field: 'paidAt',
                                    filtering: false,
                                    render: rowData =>
                                            rowData.paidAt ? <Moment format="YYYY/MM/DD">{rowData.paidAt}</Moment> : <p>---</p>
                                }
                            ]}
                                           data={this.props.manualTransactions}
                                           icons={{
                                               Filter: () => (<SearchIcon style={{fontSize: 12}} color="primary"/>)
                                           }}
                                           title="Brevet list"
                                           options={{
                                               header: this.state.showHeader,
                                               filtering: false,
                                               search: true,
                                               paging: true,
                                               pageSize: this.state.pageSize,
                                               pageSizeOptions: [5,10,200],
                                               emptyRowsWhenPaging: false,
                                               actionsColumnIndex: 0,
                                               searchFieldAlignment: "left",
                                               showTitle: false
                                           }}
                                           components={{
                                               GroupRow: props => (
                                                   <tr style={{display: "block", padding: "10px"}}>
                                                       <td style={{display: "inline-block", width: "400px"}}>
                                                           <strong>{props.groups[0].title}: </strong>
                                                           {this.props.brevetTypes[props.groupData.value]
                                                               ? this.props.brevetTypes[props.groupData.value].label
                                                               : props.groupData.value}
                                                       </td>
                                                       <td style={{display: "inline-block", width: "100px"}}>
                                                           <strong>Issued:</strong> {props.groupData.data.length}</td>
                                                   </tr>
                                               )/*,
                                                   Pagination: props =>(
                                                       <MTablePagination {...props}>{console.log(props)}
                                                       </MTablePagination>
                                                   )*/
                                           }}
                                           actions={[
                                               {
                                                   icon: () => <Chip color={this.state.yearButtonColors.twenty}
                                                                     label="2020"/>,
                                                   tooltip: 'Show only transactions of 2020',
                                                   onClick: () => {
                                                       this.setState({
                                                           yearFilter: "2020",
                                                           yearButtonColors: {
                                                               "twenty": "primary",
                                                               "twentyone":"default",
                                                               "all": "default"
                                                           }
                                                       })
                                                   },
                                                   isFreeAction: true
                                               },
                                               {
                                                   icon: () => <Chip color={this.state.yearButtonColors.twentyone}
                                                                     label="2021"/>,
                                                   tooltip: 'Show only transactions of 2021',
                                                   onClick: () => {
                                                       this.setState({
                                                           yearFilter: "2021",
                                                           yearButtonColors: {
                                                               "twenty": "default",
                                                               "twentyone":"primary",
                                                               "all": "default"
                                                           }
                                                       })
                                                   },
                                                   isFreeAction: true
                                               },
                                               {
                                                   icon: () => <Chip color={this.state.yearButtonColors.all}
                                                                     label="All"/>,
                                                   tooltip: 'Show all transactions',
                                                   onClick: () => {
                                                       this.setState({
                                                           yearFilter: "",
                                                           yearButtonColors: {
                                                               "twenty": "default",
                                                               "twentyone":"default",
                                                               "all": "primary"
                                                           }
                                                       })
                                                   },
                                                   isFreeAction: true
                                               }]}
                                           localization={{header: {actions: "Options"}}}/>
                        </div>
                    </div>

                    <Modal isOpen={this.state.showBrevetModal}>
                        <ModalBody>
                            <BrevetDetailView brevet={this.state.selectedBrevet}/>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="contained" color="primary"
                                    onClick={() => this.setState({showBrevetModal: false})}>Close</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.showValidationModal}>
                        <ModalBody>
                            <BrevetDetailView brevet={this.state.selectedBrevet}/>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Print & Shipping Service</FormLabel>
                                            <RadioGroup
                                                aria-label="Print Service"
                                                name="printService"
                                                value={this.props.newBrevetSendTo}
                                                onChange={(e) => {
                                                    this.props.handleBrevetSendToChange(e.target.value)
                                                }}
                                            >
                                                <FormControlLabel value="NONE" control={<Radio/>}
                                                                  label="No print service - center has a printer"/>
                                                <FormControlLabel value="CENTER" control={<Radio/>}
                                                                  label="Print and ship to center. Fee will be charged."/>
                                                <FormControlLabel value="STUDENT" control={<Radio/>}
                                                                  label="Print and ship to student. Fee will be charged."/>
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <div className="col-12">
                                        <div className="attention">ATTENTION!</div>
                                        <div>You will be charged by NSC for the brevet code.</div>
                                        <div>If you choose the brevet to be shipped, additional fees will be charged.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="contained" color="primary"
                                    onClick={() => {
                                        this.setState({showValidationModal: false});
                                        this.props.approveBrevet(this.state.selectedBrevet.id)
                                    }}>Validate</Button>
                            <Button variant="contained" color="primary"
                                    onClick={() => {
                                        this.setState({showValidationModal: false});
                                        this.props.getCenterPrintDefault(this.props.center.hasPrinter)
                                    }}>Close</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        );
    }
}
export class CenterManualSalesView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            manualModalStep: ["block", "none"],
            selectedManual: "",
            selectedUser: {}
        };
    }

    render() {
        return (
            <div>
                <ModalHeader>
                    <h1>Sell Manual</h1>
                </ModalHeader>
                <ModalBody>
                    <div style={{display: this.state.manualModalStep[0]}}>
                        <div className="row">
                            <div className="col-12">
                                Sell Manual
                                to {this.props.selectedUser.firstName} {this.props.selectedUser.lastName}:
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <MaterialTable columns={[
                                    {title: 'Title', field: 'title'},
                                    {title: 'Only Trainer', field: 'isTrainerManual',render: rowData =>
                                            <div>
                                                {rowData.isTrainerManual ? "Only trainer" : ""}
                                            </div>},
                                    {title: 'Price', field: 'price'},]}
                                               data={this.props.manuals}
                                               title="Select Manual"
                                               options={{
                                                   showTitle: false,
                                                   search: true,
                                                   actionsColumnIndex: -1,
                                                   searchFieldAlignment: "left"
                                               }}
                                               actions={[
                                                   {
                                                       icon: 'check_circle_outline',
                                                       tooltip: 'Select Manual',
                                                       onClick: (event, rowData) => {
                                                           this.setState({selectedManual: rowData});
                                                           this.setState({
                                                               manualModalButtons: "block",
                                                               manualModalStep: [this.state.manualModalStep[1],
                                                                   this.state.manualModalStep[0]]
                                                           });
                                                       }
                                                   }
                                               ]}
                                               localization={{header: {actions: "Options"}}}/>
                            </div>
                        </div>
                    </div>
                    <div style={{display: this.state.manualModalStep[1]}}>

                        Do you really want to sell the digital manual "{this.state.selectedManual.title}"
                        to {this.props.selectedUser.firstName} {this.props.selectedUser.lastName}?

                        The manual will be available for the student in their OBS account.
                        With your next invoice, Dive-NSC will charge you {this.state.selectedManual.price}

                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button style={{display: this.state.manualModalStep[1]}} variant="contained" color="primary"
                            onClick={() => {
                                this.props.saveManualTransaction(this.props.selectedUser.id, this.state.selectedManual.id);
                                this.props.toggleManualSalesModal();
                                this.setState({
                                    manualModalStep: ["block", "none"],
                                    selectedManual: "",
                                    selectedUser: {}
                                })
                            }}>Confirm</Button>
                    <Button variant="contained" color="primary" onClick={() => {
                        this.props.toggleManualSalesModal();
                        this.setState({
                            manualModalStep: ["block", "none"],
                            selectedManual: "",
                            selectedUser: {}
                        })
                    }}>Cancel</Button>
                </ModalFooter>
            </div>
        )
    }
}