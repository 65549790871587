import React from 'react';

export class BrevetDetailView extends React.Component {


    constructor(props) {
        super(props);
        if (this.props.brevet.center !== undefined) {
            this.state = {brevetCenter: this.props.brevet.center};
        } else this.state = {brevetCenter: "not validated yet"};
    }

    render() {
        return (
            <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div
                            className="col-6"> {this.props.brevet.student.firstName} {this.props.brevet.student.lastName}</div>
                        <div className="col-6"> Brevet #{this.props.brevet.id}</div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <h5 className="card-title">{this.props.brevet.level}</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <label> Issued on: </label>
                        </div>
                        <div className="col-6">
                            {this.props.brevet.certifiedAt}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <label> Issued by trainer: </label>
                        </div>
                        <div className="col-6">
                            {this.props.brevet.trainer.firstName} {this.props.brevet.trainer.lastName}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <label> Validated by center: </label>
                        </div>
                        <div className="col-6">
                            {this.state.brevetCenter.name}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <label> Comments: </label>
                        </div>
                        <div className="col-6">
                            {this.props.brevet.comments}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}