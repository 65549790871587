import React from 'react';
import {
    CenterStudentsView,
    CenterTrainersView,
    CenterBrevetsView,
    CenterProfileEditView,
    CenterManualTransactionsView
} from './CenterView.js';
import centerData from '../models/CenterModel';
import userData from "../models/UserModel";
import brevetData from "../models/BrevetModel";
import axios from 'axios';
import {Route} from "react-router-dom";
import Button from "@material-ui/core/Button";
import loadImage from "blueimp-load-image";

var userImageBlobData;

export default class Center extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            center: JSON.parse(JSON.stringify(centerData)),
            students: [JSON.parse(JSON.stringify(userData))],
            trainers: [JSON.parse(JSON.stringify(userData))],
            managers: [JSON.parse(JSON.stringify(userData))],
            brevets: [JSON.parse(JSON.stringify(brevetData))],
            manuals: [],
            manualTransactions: [],
            unapprovedBrevets: [],
            profileSaveButtonStatus: false,
            brevetTypes: {"OWD": {label: "OWD"}},
            voucherTypes: null,
            newUser: JSON.parse(JSON.stringify(userData)),
            showCreateUserModal: false,
            showManualModal: false,
            showManualSalesModal: false,
            newBrevetSendTo: "CENTER",
            userImage: this.props.constants.placeholderImage
        };
        this.getCenter(props.match.params.id);
        this.getBrevetAndVoucherTypes();
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.getCenter(this.props.match.params.id);
        }
    }

    getBrevetAndVoucherTypes = () => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'brevet/definitions',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (Center.validate(res.data)) {
                        this.setState({
                            brevetTypes: res.data.brevetTypes,
                            voucherTypes: res.data.voucherTypes
                        });
                    } else {
                        console.log("fail")
                    }
                }
            )
    };

    getCenter = (centerId) => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'center/' + centerId,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (Center.validate(res.data)) {
                        this.setState({center: res.data});
                        this.getCenterUsers(centerId);
                        this.getCenterTrainers(centerId);
                        this.getCenterAspirants(centerId);
                        this.getCenterManagers(centerId);
                        this.getApprovedCenterBrevets(centerId);
                        this.getUnapprovedCenterBrevets(centerId);
                        this.getCenterPrintDefault(res.data.hasPrinter);
                        this.getManuals(centerId);
                        this.getManualTransactions(centerId);
                    } else {
                        console.log("fail")
                    }
                }
            )
    };
    getCenterUsers = (centerId) => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'center/' + centerId + '/students',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (Center.validate(res.data)) {
                        this.setState({students: res.data});
                    } else {
                        console.log("fail")
                    }
                }
            );
    }
    getCenterPrintDefault = (hasPrinter) => {
        if (hasPrinter) {
            this.setState({newBrevetSendTo: "NONE"});
        } else this.setState({newBrevetSendTo: "CENTER"});
    };
    getCenterTrainers = (centerId) => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'center/' + centerId + '/trainers',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (Center.validate(res.data)) {
                        this.setState({trainers: res.data});
                    } else {
                        console.log("fail")
                    }
                }
            );
    }
    getCenterAspirants = (centerId) => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'center/' + centerId + '/aspirants',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (Center.validate(res.data)) {
                        this.setState({aspirants: res.data});
                    } else {
                        console.log("fail")
                    }
                }
            );
    }
    getCenterManagers = (centerId) => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'center/' + centerId + '/managers',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (Center.validate(res.data)) {
                        this.setState({managers: res.data});
                    } else {
                        console.log("fail")
                    }
                }
            );
    }
    getApprovedCenterBrevets = (centerId) => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'center/' + centerId + '/brevets',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (Center.validate(res.data)) {
                        this.setState({brevets: res.data});
                    } else {
                        console.log("fail")
                    }
                }
            );
    }
    getUnapprovedCenterBrevets = (centerId) => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'center/' + centerId + '/unapprovedBrevets',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (Center.validate(res.data)) {
                        this.setState({unapprovedBrevets: res.data});
                    } else {
                        console.log("fail")
                    }
                }
            );
    }
    getManuals = (centerId) => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'center/' + centerId + '/manuals',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (Center.validate(res.data)) {
                        this.setState({
                            manuals: res.data
                        });
                    } else {
                        console.log("fail")
                    }
                }
            )
    };
    getManualTransactions = (centerId) => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'center/' + centerId + '/manual-transactions',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (Center.validate(res.data)) {
                        this.setState({
                            manualTransactions: res.data
                        });
                    } else {
                        console.log("fail")
                    }
                }
            )
    };

    handleCenterFieldChange = (event, field, subfield) => {
        this.setState({profileSaveButtonStatus: true});
        this.center = this.state.center;
        if (subfield === null) {
            this.center[field] = event.target.value;
        } else {
            this.center[field][subfield] = event.target.value;
        }
        this.setState({center: this.center})
    };
    saveCenter = (center) => {
        axios({
            method: 'put',
            url: this.props.constants.baseAPIURL + 'center/' + this.state.center.id,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
            data: center

        })
            .then(res => {
                    if (Center.validate(res.data)) {
                        this.getCenter(this.state.center.id);
                        this.setState({profileSaveButtonStatus: false});
                    } else {
                        console.log("fail")
                    }
                }
            );
    };

    acceptAspirant = (centerId, userId) => {
        axios({
            method: 'post',
            url: this.props.constants.baseAPIURL + 'center/' + centerId + '/trainer/' + userId,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (Center.validate(res.data)) {
                        this.getCenterTrainers(centerId);
                        this.getCenterAspirants(centerId);
                    } else {
                        console.log("fail")
                    }
                }
            )
    };
    kickTrainer = (centerId, userId) => {
        axios({
            method: 'delete',
            url: this.props.constants.baseAPIURL + 'center/' + centerId + '/trainer/' + userId,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (Center.validate(res.data)) {
                        this.getCenterTrainers(centerId);
                    } else {
                        console.log("fail")
                    }
                }
            )
    };

    saveUserAsManager = (userId) => {
        axios({
            method: 'post',
            url: this.props.constants.baseAPIURL + 'center/' + this.state.center.id + '/manager/' + userId,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (Center.validate(res.data)) {
                        alert("User is now manager of this center.");
                        this.getCenterManagers(this.state.center.id);
                    } else {
                        console.log("fail")
                    }
                }
            ).catch(() => {
            alert("Couldn't add user with ID " + userId + " to the managers of this center.");
        })
    };
    kickManager = (centerId, userId) => {
        axios({
            method: 'delete',
            url: this.props.constants.baseAPIURL + 'center/' + centerId + '/manager/' + userId,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (Center.validate(res.data)) {
                        this.getCenterManagers(centerId);
                    } else {
                        console.log("fail")
                    }
                }
            )
    };

    handleSelectedFile = (event) => {
        loadImage(  //for saving
            event.target.files[0],
            function (canvas) {
                canvas.toBlob(function (blob) {
                    userImageBlobData = blob
                });
            },
            {
                maxWidth: 600,
                maxHeight: 800,
                orientation: true,
                crop: true
            }
        );

        loadImage( //for showing on canvas
            event.target.files[0],
            function (canvas) {
                canvas.id = "user-image";

                var img1 = document.getElementById("user-image");
                var parentDiv = img1.parentNode;
                parentDiv.replaceChild(canvas, img1);
            },
            {
                maxWidth: 150,
                maxHeight: 200,
                orientation: true,
                crop: true
            }
        );
    };
    uploadUserImage = (userId) => {
        if (userImageBlobData) {

            var bodyFormData = new FormData();
            bodyFormData.set('file', userImageBlobData);

            axios({
                method: 'post',
                url: this.props.constants.baseAPIURL + 'center/' + this.state.center.id + '/student/' + userId + '/pic',
                withCredentials: true,
                headers: {'Content-Type': 'multipart/form-data'},
                data: bodyFormData,
            })
                .then(() => {
                        this.setState({
                            userImage: null
                        });
                        this.setState({selectedUser: JSON.parse(JSON.stringify(userData))});
                        this.getUserImage();
                        userImageBlobData = null;
                    }
                )
        } else {
        }
    };
    getUserImage = (userId) => {
        this.setState({userImage: this.props.constants.loadingImage});
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'center/' + this.state.center.id + '/student/' + userId + '/pic',
            withCredentials: true,
            responseType: "arraybuffer"
        })
            .then(res => {
                    res = new Buffer(res.data, 'binary').toString('base64');
                    this.setState({userImage: "data:image/png;base64," + res})
                }
            )
            .catch(() => {
                this.setState({userImage: this.props.constants.placeholderImage});
            })
    };
    clearUserImage = () => {
        this.setState({
            userImage: this.props.constants.placeholderImage
        });
        userImageBlobData = null;
    };

    createBrevet = (student, trainer, center, level) => {
        axios({
            method: 'post',
            url: this.props.constants.baseAPIURL + 'brevet',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                "student": student,
                "trainer": trainer,
                "center": center,
                "level": level,
            }

        })
            .then(res => {
                    if (Center.validate(res.data)) {
                        this.getApprovedCenterBrevets(this.state.center.id);
                    } else {
                        console.log("fail")
                    }
                }
            )
    };
    handleBrevetSendToChange = (value) => {
        this.setState({newBrevetSendTo: value});
    };
    approveBrevet = (brevetId) => {
        axios({
            method: 'post',
            url: this.props.constants.baseAPIURL + 'center/' + this.state.center.id + '/brevet/' + brevetId + '/approve',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
            data: {"sendTo": this.state.newBrevetSendTo}
        })
            .then(res => {
                    if (Center.validate(res.data)) {
                        this.getApprovedCenterBrevets(this.state.center.id);
                        this.getUnapprovedCenterBrevets(this.state.center.id);
                        this.getCenterPrintDefault(this.state.center.hasPrinter);
                    } else {
                        console.log("fail")
                    }
                }
            )
    };

    deleteUnapprovedBrevet = (brevet) => {
        axios({
            method: 'delete',
            url: this.props.constants.baseAPIURL + 'center/' + this.state.center.id + '/brevet/' + brevet.id,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(() => {
                    alert("Unvalidated brevet has been deleted.");
                    this.getUnapprovedCenterBrevets(this.state.center.id);
                }
            )
            .catch(() => {
                    alert("Could not delete brevet.")
                }
            );
    };

    handleUserFieldChange = (event, field, subfield) => {
        this.newUser = this.state.newUser;
        if (subfield === null) {
            this.newUser[field] = event.target.value;
        } else {
            this.newUser[field][subfield] = event.target.value;
        }
        this.setState({newUser: this.newUser});
    };
    saveNewCenterStudent = (user) => {
        if (user.password === "") {
            alert("Please set a password!")
        } else if (user.password === user.passwordRepeat) {
            axios({
                method: 'post',
                url: this.props.constants.baseAPIURL + 'center/' + this.state.center.id + '/student',
                withCredentials: true,
                headers: {'Content-Type': 'application/json'},
                data: user
            })
                .then((res) => {
                        if (Center.validate) {
                            this.setState({newUser: JSON.parse(JSON.stringify(userData))});
                            if (userImageBlobData !== null) {
                                this.uploadUserImage(res.data.id);
                            }
                            this.getCenterUsers(this.state.center.id);
                            this.toggleCreateUserModal();
                        } else {
                            console.log("fail")
                        }
                    }
                )
                .catch(() => {
                        alert("Could not create user - please check user data!")
                    }
                );
        } else alert("Passwords don't match!")
    };
    saveNewCenterTrainer = (user) => {
        axios({
            method: 'post',
            url: this.props.constants.baseAPIURL + 'user',
            withCredentials: true,
            headers: {'Content-Type': 'application/json'},
            data: user
        })
            .then(() => {
                    if (Center.validate) {
                        this.setState({newUser: userData});
                        this.getCenterTrainers(this.state.center.id);
                    } else {
                        console.log("fail")
                    }
                }
            );
    };

    toggleCreateUserModal = () => {
        this.setState({showCreateUserModal: !this.state.showCreateUserModal});
    };
    toggleManualSalesModal = () => {
        this.setState({showManualSalesModal: !this.state.showManualSalesModal});
    };

    saveManualTransaction = (student_id, manual_id) => {
        axios({
            method: 'post',
            url: this.props.constants.baseAPIURL + 'center/' + this.state.center.id + '/manual-transactions',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                "studentId": student_id,
                "manualId": manual_id,
            }
        })
            .then(() => {
                alert("The Manual is now available for the student. Dive-NSC will send you an invoice.")
            }).catch(error => {
            alert(error.response.data.message);
        })
    };

    static validate() {
        return true;
    }

    render() {
        return (
            <div className="row">
                <div className="col-12 main-container">
                    <Route exact path='/center/:id/profile' render={(props) =>
                        <div>
                            <CenterProfileEditView {...props}
                                                   center={this.state.center}
                                                   handleCenterFieldChange={this.handleCenterFieldChange}
                                                   saveCenter={this.saveCenter}
                                                   managers={this.state.managers}
                                                   kickManager={this.kickManager}
                                                   saveUserAsManager={this.saveUserAsManager}
                                                   profileSaveButtonStatus={this.state.profileSaveButtonStatus}/>
                            <div className="card-footer">
                                <div className="row">
                                    <div className="col-12">
                                        <Button variant="contained" color="primary"
                                                disabled={!this.state.profileSaveButtonStatus}
                                                onClick={() => this.saveCenter(this.state.center)}>Save
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>}/>
                    <Route exact path='/center/:id/students' render={(props) =>
                        <CenterStudentsView {...props}
                                            constants={this.props.constants}
                                            center={this.state.center}
                                            students={this.state.students}
                                            manuals={this.state.manuals}
                                            newUser={this.state.newUser}
                                            userImage={this.state.userImage}
                                            uploadUserImage={this.uploadUserImage}
                                            getUserImage={this.getUserImage}
                                            handleSelectedFile={this.handleSelectedFile}
                                            clearUserImage={this.clearUserImage}
                                            showCreateUserModal={this.state.showCreateUserModal}
                                            toggleCreateUserModal={this.toggleCreateUserModal}
                                            showManualSalesModal={this.state.showManualSalesModal}
                                            toggleManualSalesModal={this.toggleManualSalesModal}
                                            handleUserFieldChange={this.handleUserFieldChange}
                                            saveNewCenterStudent={this.saveNewCenterStudent}
                                            saveManualTransaction={this.saveManualTransaction}/>}/>
                    <Route exact path='/center/:id/trainers' render={(props) =>
                        <CenterTrainersView {...props}
                                            center={this.state.center}
                                            trainers={this.state.trainers}
                                            aspirants={this.state.aspirants}
                                            acceptAspirant={this.acceptAspirant}
                                            kickTrainer={this.kickTrainer}
                                            newUser={this.state.newUser}
                                            handleUserFieldChange={this.handleUserFieldChange}
                                            saveNewCenterTrainer={this.saveNewCenterTrainer}/>}/>
                    <Route exact path='/center/:id/brevets' render={(props) =>
                        <CenterBrevetsView {...props}
                                           center={this.state.center}
                                           brevets={this.state.brevets}
                                           unapprovedBrevets={this.state.unapprovedBrevets}
                                           brevetTypes={this.state.brevetTypes}
                                           voucherTypes={this.state.voucherTypes}
                                           newBrevetSendTo={this.state.newBrevetSendTo}
                                           handleBrevetSendToChange={this.handleBrevetSendToChange}
                                           getCenterPrintDefault={this.getCenterPrintDefault}
                                           approveBrevet={this.approveBrevet}
                                           deleteUnapprovedBrevet={this.deleteUnapprovedBrevet}
                                           getBrevetBackSide={this.getBrevetBackSide}
                                           constants={this.props.constants}/>}/>
                    <Route exact path='/center/:id/manuals' render={(props) =>
                        <CenterManualTransactionsView {...props}
                                                      center={this.state.center}
                                                      manuals={this.state.manuals}
                                                      manualTransactions={this.state.manualTransactions}
                                                      constants={this.props.constants}/>}/>
                    <Route exact path='/center/access-restricted' render={(props) =>
                        <iframe className="access-restricted-frame" src="https://dive-nsc-manuals.sandboxtools.de/center-access-restricted"  title="access-restricted"/>}/>
                </div>
            </div>
        )
    }
}