import React from 'react';
import {TrainerStudentsView, TrainerCenterView, TrainerBrevetsView} from './TrainerView.js';
import userData from '../models/UserModel';
import centerData from '../models/CenterModel'
import axios from 'axios';
import {Route} from "react-router-dom";

export default class User extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(JSON.stringify(userData)),
            trainerCenter: centerData,
            brevets: [],
            unapprovedBrevets: [],
            students: [JSON.parse(JSON.stringify(userData))],
            allCenters: [],
            selectedCenter: 1001,
            brevetTypes: [],
            brevetTypesByKey: {"OWD": {label: "OWD"}},
            userImage: this.props.constants.placeholderImage,
            showChooseCenterModal: false,
            showBrevetModal: false
        };
        this.getTrainerBrevets();
        this.getUnapprovedTrainerBrevets();
        this.getTrainerStudents();
        this.getTrainerCenter(this.props.constants.user.trainerOfCenter);
        this.getAllCenters();
        this.getBrevetTypes();
    }

    getTrainerCenter = (centerId) => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'center/' + centerId,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (User.validate(res.data)) {
                        this.setState({trainerCenter: res.data});
                    } else {
                        console.log("fail")
                    }
                }
            )
    }
    getTrainerStudents = () => {
        axios({
            method: 'get',
            //url: this.props.constants.baseAPIURL + 'trainer/students',
            url: this.props.constants.baseAPIURL + 'center/' + this.props.constants.user.trainerOfCenter + '/students',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (User.validate(res.data)) {
                        this.setState({students: res.data});
                    } else {
                        console.log("fail")
                    }
                }
            );
    }
    getTrainerBrevets = () => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'trainer/brevets/',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (User.validate(res.data)) {
                        this.setState({brevets: res.data});

                    } else {
                        console.log("fail")
                    }
                }
            )
    }
    getUnapprovedTrainerBrevets = () => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'trainer/unapprovedBrevets',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (User.validate(res.data)) {
                        this.setState({unapprovedBrevets: res.data});

                    } else {
                        console.log("fail")
                    }
                }
            )
    };

    getAllCenters = () => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'centers',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (User.validate(res.data)) {
                        this.setState({allCenters: res.data});
                    } else {
                        console.log("fail")
                    }
                }
            )
    };
    getBrevetTypes = () => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'brevet/definitions',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (User.validate(res.data)) {
                        var allBrevetTypes = [];
                        this.setState({brevetTypesByKey: res.data.brevetTypes});
                        for (var singleType in res.data.brevetTypes) {
                            allBrevetTypes.push({
                                key: singleType,
                                label: res.data.brevetTypes[singleType].label,
                                requiredVoucherType: res.data.brevetTypes[singleType].requiredVoucherType,
                            });

                        }
                        this.setState({brevetTypes: allBrevetTypes});
                    } else {
                        console.log("fail")
                    }
                }
            );
    };
    handleUserFieldChange = (event, field, subfield) => {
        this.user = this.props.constants.user;
        if (subfield === null) {
            this.user[field] = event.target.value;
        } else {
            this.user[field][subfield] = event.target.value;
        }
        this.setState({user: this.user});
    };
    handleCenterSelect = (event) => {
        this.setState({selectedCenter: event.target.value});
    };
    saveCenterAspirant = (centerId) => {
        axios({
            method: 'put',
            url: this.props.constants.baseAPIURL + 'user',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
            data: {"aspirantOfCenter": centerId}
        })
            .then(() => {
                if (User.validate()) {
                    this.getTrainerCenter(centerId);
                    alert("Your Application has been send. After the center has accepted you, you will be able to see it here");
                    this.toggleShowChooseCenterModal();
                } else {
                    console.log("fail")
                }
            })
            .catch(() => {
                alert("Center could not be changed - wrong ID?")
            })
    };
    createBrevet = (student, level, date, comments) => {
        axios({
            method: 'post',
            url: this.props.constants.baseAPIURL + 'trainer/brevet',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                "center": this.props.constants.user.trainerOfCenter,
                "trainer": this.props.constants.user.id,
                "student": student,
                "level": level,
                "certifiedAt": date,
                "comments": comments,
            }
        })
            .then(() => {
                this.toggleShowBrevetModal();
                this.getUnapprovedTrainerBrevets();
                alert("Brevet created. The dive center manager can validate it now.")
            })
    };
    deleteUnapprovedBrevet = (brevet) => {
        axios({
            method: 'delete',
            url: this.props.constants.baseAPIURL + 'trainer/brevet/' + brevet.id,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(() => {
                    alert("Unvalidated brevet has been deleted.");
                    this.getUnapprovedTrainerBrevets();
                }
            )
            .catch(() => {
                    alert("Could not delete brevet.")
                }
            );
    };

    getUserImage = (userId) => {
        this.setState({userImage: this.props.constants.loadingImage});
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'trainer/student/' + userId + '/pic',
            withCredentials: true,
            responseType: "arraybuffer"
        })
            .then(res => {
                    res = new Buffer(res.data, 'binary').toString('base64');
                    this.setState({userImage: "data:image/png;base64," + res});
                }
            )
            .catch(() => {
                this.setState({userImage: this.props.constants.placeholderImage});
            })
    };
    checkIfUserIsComplete = (user) => {
        axios({
            method: 'get',
            url: this.props.constants.baseAPIURL + 'trainer/student/' + user.id + '/pic',
            withCredentials: true,
            responseType: "arraybuffer"
        })
            .then(() => {
                    if (user.firstName === "" || user.lastName === "") {
                        alert("User has not uploaded all data yet. We need first name, last name and a profile image to create a valid brevet.")

                    } else this.toggleShowBrevetModal()
                }
            )
            .catch(() => {
                alert("User has not uploaded all data yet. We need first name, last and a profile image to create a valid brevet.")
            })
    };

    toggleShowBrevetModal = () => {
        this.setState({showBrevetModal: !this.state.showBrevetModal})
    };
    toggleShowChooseCenterModal = () => {
        this.setState({showChooseCenterModal: !this.state.showChooseCenterModal})
    };

    static validate() {
        return true;
    }

    render() {
        return (
            <div className="row">
                <div className="col-12 main-container">
                    <Route exact path='/trainer/center' render={(props) =>
                        <TrainerCenterView {...props}
                                           trainerCenter={this.state.trainerCenter}
                                           allCenters={this.state.allCenters}
                                           handleCenterSelect={this.handleCenterSelect}
                                           selectedCenter={this.state.selectedCenter}
                                           saveCenterAspirant={this.saveCenterAspirant}
                                           showChooseCenterModal={this.state.showChooseCenterModal}
                                           toggleShowChooseCenterModal={this.toggleShowChooseCenterModal}/>}/>
                    <Route exact path='/trainer/students' render={(props) =>
                        <TrainerStudentsView {...props}
                                             trainerCenter={this.state.trainerCenter}
                                             allCenters={this.state.allCenters}
                                             user={this.props.constants.user}
                                             students={this.state.students}
                                             getUserImage={this.getUserImage}
                                             userImage={this.state.userImage}
                                             brevetTypes={this.state.brevetTypes}
                                             createBrevet={this.createBrevet}
                                             showBrevetModal={this.state.showBrevetModal}
                                             toggleShowBrevetModal={this.toggleShowBrevetModal}
                                             checkIfUserIsComplete={this.checkIfUserIsComplete}/>}/>
                    <Route exact path='/trainer/brevets' render={(props) =>
                        <TrainerBrevetsView {...props}
                                            baseAPIURL={this.props.constants.baseAPIURL}
                                            trainerCenter={this.state.trainerCenter}
                                            allCenters={this.state.allCenters}
                                            brevetTypes={this.state.brevetTypes}
                                            brevetTypesByKey={this.state.brevetTypesByKey}
                                            brevets={this.state.brevets}
                                            unapprovedBrevets={this.state.unapprovedBrevets}
                                            deleteUnapprovedBrevet={this.deleteUnapprovedBrevet}
                                            user={this.props.constants.user}/>}/>
                    <Route exact path='/trainer/access-restricted' render={(props) =>
                        <iframe className="access-restricted-frame" src="https://dive-nsc-manuals.sandboxtools.de/trainer-access-restricted"  title="access-restricted"/>}/>
                </div>
            </div>
        )
    }
}