import React, {Component} from 'react';
import './styles/layout.css';
import {BrowserRouter as Router, Route, Link, Switch} from '../node_modules/react-router-dom';
import './App.css';
import User from "./components/user/UserController";
import userData from './components/models/UserModel';
import Center from "./components/center/CenterController";
import Trainer from "./components/trainer/TrainerController";
import Admin from "./components/admin/AdminController";
import "@material/react-drawer/dist/drawer.css";
import Drawer from '@material/react-drawer';
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import logoSmall from './assets/logo_small.png';
import logoBig from './assets/logo_big.png';
import placeholder from "./assets/profile_placeholder.png";
import loading from "./assets/loading.gif";
import Registration from "./components/registration/RegistrationController";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            baseAPIURL: 'https://obs.dive-nsc.com/api/',
            baseFrontURL: 'https://obs.dive-nsc.com/',
            //baseAPIURL: 'http://localhost:8080/api/',
            //baseFrontURL: 'http://localhost:3000/',
            user: JSON.parse(JSON.stringify(userData)),
            loadingImage: loading,
            placeholderImage: placeholder,
            accessInfo: {isActiveTrainer: false, paidCurrentFee: false, managerOfCenters: []}
        };
        if (App.getLoginCookie("loggedIn") === "true") {
            this.getUser();
        }

    }

    static getLoginCookie(name) {
        var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    }

    static setLoginCookie(name, value, days) {
        var d = new Date();
        d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
        document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
    }

    deleteCookie(name) {
        this.setLoginCookie(name, '', -1);
    }

    login(username, password) {
        axios({
            method: 'post',
            url: this.state.baseAPIURL + 'login',
            auth: {
                username: username,
                password: password
            },
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(() => {
                    this.getUser();
                    document.cookie = "loggedIn=true";
                }
            )
            .catch(() => {
                alert("Wrong login data.")
            });
    }

    logout() {
        App.setLoginCookie("loggedIn", "false", 1);
        this.setState({user: null});
        window.location.href = "https://obs.dive-nsc.com";
        axios({
            method: 'post',
            url: this.state.baseAPIURL + 'logout',
            withCredentials: true,
            headers: {'Content-Type': 'application/json'}
        })
            .then(() => {
                    this.setState({user: null});
                    this.setLoginCookie("loggedIn", "false", 1);
                    this.deleteCookie("loggedIn");
                }
            );
    }

    getUser() {
        axios({
            method: 'get',
            url: this.state.baseAPIURL + 'user',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (res.status === 200) {
                        this.setState({user: res.data});
                        this.getAccessInfo();
                    } else {
                        console.log("fail")
                    }
                }
            )
            .catch((error) => {
                console.log(error);
            })
    }

    getAccessInfo = () => {
        axios({
            method: 'get',
            url: this.state.baseAPIURL + 'user/accessInfo',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (User.validate(res.data)) {
                        this.setState({accessInfo: res.data});
                    } else {
                        console.log("fail")
                    }
                }
            )
    };

    checkIfProMember = (id) => {
        axios({
            method: 'get',
            url: this.state.baseAPIURL + 'ffa/istrainer/' + id,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                    if (res.data) {
                        alert("The user with NSC ID " + id + " is an active professional member.")
                    } else alert("There is no active professional member with the NSC ID " + id + ".")
                }
            )
            .catch(() => {
                alert("Something went wrong - couldn't check professional status. Please check your input.")
            })
    };

    render() {

        let adminMenu, superAdminMenu, userMenu, trainerMenu;
        let centerMenu = [];

        userMenu =
            <div>
                <div className="nav-section">User Area</div>
                <Link to='/profile'>
                    <div className="nav-link" onClick={() => this.setState({menuToggle: false})}>My Profile</div>
                </Link>
                <Link to='/profile/center'>
                    <div className="nav-link" onClick={() => this.setState({menuToggle: false})}>My Center</div>
                </Link>
                <Link to='/profile/brevets'>
                    <div className="nav-link" onClick={() => this.setState({menuToggle: false})}>My Brevets</div>
                </Link>
                <Link to='/profile/manuals'>
                    <div className="nav-link" onClick={() => this.setState({menuToggle: false})}>My Manuals</div>
                </Link>
            </div>;

        if (this.state.user.trainerOfCenter != null) {
            if (this.state.accessInfo.isActiveTrainer && this.state.accessInfo.paidCurrentFee) {
                trainerMenu =
                    <div>
                        <div className="nav-section">Trainer Area</div>
                        <Link to='/trainer/center'>
                            <div className="nav-link" onClick={() => this.setState({menuToggle: false})}>Center you work
                                in
                            </div>
                        </Link>
                        <Link to='/trainer/students'>
                            <div className="nav-link" onClick={() => this.setState({menuToggle: false})}>Students in
                                your
                                Center
                            </div>
                        </Link>
                        <Link to='/trainer/brevets'>
                            <div className="nav-link" onClick={() => this.setState({menuToggle: false})}>Brevets you
                                issued
                            </div>
                        </Link>
                    </div>
            } else {
                trainerMenu =
                    <div>
                        <div className="nav-section">Trainer Area</div>
                        <Link to='/trainer/access-restricted'>
                            <div className="nav-link alert-menu-link"
                                 onClick={() => this.setState({menuToggle: false})}>
                                Access restricted
                            </div>
                        </Link>
                        <Link to='/trainer/brevets'>
                            <div className="nav-link" onClick={() => this.setState({menuToggle: false})}>Brevets you
                                issued
                            </div>
                        </Link>
                    </div>
            }
        } else {
            trainerMenu = null
        }
        if (this.state.user.managerOfCenters.length > 0) {
            var singleCenterMenu
            for (var i = 0; i < this.state.user.managerOfCenters.length; i++) {
                var centerHasAccess;
                for (var j = 0; j < this.state.accessInfo.managerOfCenters.length; j++) {
                    if (this.state.accessInfo.managerOfCenters[j].id === this.state.user.managerOfCenters[i]
                        && this.state.accessInfo.managerOfCenters[j].isActiveCenter
                        && this.state.accessInfo.managerOfCenters[j].paidCurrentFee) {
                        centerHasAccess = true;
                    }
                }
                if (centerHasAccess) {
                    singleCenterMenu =
                        <div key={i}>
                            <div className="nav-section">Center {this.state.user.managerOfCenters[i]}</div>
                            <Link to={'/center/' + this.state.user.managerOfCenters[i] + '/profile'}>
                                <div className="nav-link" onClick={() => this.setState({menuToggle: false})}>Center
                                    Profile
                                </div>
                            </Link>
                            <Link to={'/center/' + this.state.user.managerOfCenters[i] + '/students'}>
                                <div className="nav-link" onClick={() => this.setState({menuToggle: false})}>Enlisted
                                    Students
                                </div>
                            </Link>
                            <Link to={'/center/' + this.state.user.managerOfCenters[i] + '/trainers'}>
                                <div className="nav-link" onClick={() => this.setState({menuToggle: false})}>Employed
                                    Trainers
                                </div>
                            </Link>
                            <Link to={'/center/' + this.state.user.managerOfCenters[i] + '/brevets'}>
                                <div className="nav-link" onClick={() => this.setState({menuToggle: false})}>Issued
                                    Brevets
                                </div>
                            </Link>
                            <Link to={'/center/' + this.state.user.managerOfCenters[i] + '/manuals'}>
                                <div className="nav-link" onClick={() => this.setState({menuToggle: false})}>Sold
                                    Manuals
                                </div>
                            </Link>
                        </div>;
                } else {
                    singleCenterMenu =
                        <div key={i}>
                            <div className="nav-section">Center {this.state.user.managerOfCenters[i]}</div>
                            <Link to='/center/access-restricted'>
                                <div className="nav-link alert-menu-link"
                                     onClick={() => this.setState({menuToggle: false})}>
                                    Access restricted
                                </div>
                            </Link>
                            <Link to={'/center/' + this.state.user.managerOfCenters[i] + '/brevets'}>
                                <div className="nav-link" onClick={() => this.setState({menuToggle: false})}>Issued
                                    Brevets
                                </div>
                            </Link>
                            <Link to={'/center/' + this.state.user.managerOfCenters[i] + '/manuals'}>
                                <div className="nav-link" onClick={() => this.setState({menuToggle: false})}>Sold
                                    Manuals
                                </div>
                            </Link>
                        </div>;

                }
                centerMenu.push(singleCenterMenu);
            }
        } else {
            centerMenu = null;
        }
        if (this.state.user.id === 100001) {
            superAdminMenu =
                <Link to='/admin/general'>
                    <div className="nav-link" onClick={() => this.setState({menuToggle: false})}>General Settings</div>
                </Link>
        }
        if (this.state.user.isAdmin) {
            adminMenu = <div>
                <div className="nav-section">Admin area</div>
                {superAdminMenu}
                <Link to='/admin/centers'>
                    <div className="nav-link" onClick={() => this.setState({menuToggle: false})}>Center List</div>
                </Link>
                <Link to='/admin/students'>
                    <div className="nav-link" onClick={() => this.setState({menuToggle: false})}>User List</div>
                </Link>
                <Link to='/admin/brevets'>
                    <div className="nav-link" onClick={() => this.setState({menuToggle: false})}>Brevet List</div>
                </Link>
                <Link to='/admin/manual-sales'>
                    <div className="nav-link" onClick={() => this.setState({menuToggle: false})}>Sold Manuals List</div>
                </Link>
                <Link to='/admin/brevet-levels'>
                    <div className="nav-link" onClick={() => this.setState({menuToggle: false})}>Edit Brevet Levels
                    </div>
                </Link>
                <Link to='/admin/manuals'>
                    <div className="nav-link" onClick={() => this.setState({menuToggle: false})}>Edit Manual Books</div>
                </Link>
            </div>;
        } else {
            adminMenu = null;
        }
        if (this.state.user.id === "0") {
            return (
                <Router>
                    <Switch>
                        <Route path='/registration/:id' render={(props) =>
                            <Registration {...props} constants={this.state}/>}/>
                        <Route path='/resetPassword/:id' render={(props) =>
                            <Registration {...props} constants={this.state}
                                          pwreset={true}/>}/>
                        <Route exact path={"/login/"} render={() =>
                            <div className="card login-card">
                                <div className="card-header">
                                    <h1>NSC OBS Login</h1>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 padding-bottom-15">
                                            <TextField id="username" label="Username" fullWidth/>
                                            <TextField id="password" type="password" label="Password" fullWidth/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 align-center padding-top-15">
                                            <Button variant="contained" color="primary"
                                                    onClick={() => this.login(document.getElementById("username").value,
                                                        document.getElementById("password").value)}>Login
                                            </Button>
                                            <div className="padding-top-15">
                                                <Link to={'/resetPassword/forgot'}>
                                                    <div>Forgot password</div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-12 padding-15">
                                            <hr/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 align-center">
                                            <div>
                                                <h2>
                                                    No account yet?
                                                </h2>
                                            </div>
                                            <div className="padding-top-15 padding-bottom-15">
                                                <Button variant="contained" color="primary"
                                                        onClick={() => window.open("/registration/new", "_self")}>
                                                    Register
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 padding-15">
                                        <hr/>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 align-center">
                                            <div>
                                                <h2>
                                                    NSC Pro Check
                                                </h2>
                                            </div>
                                        </div>
                                        <div className="col-12 align-center">
                                            <div>Input ID to check if the user is a pro member.</div>
                                        </div>
                                        <div className="col-12 align-center">
                                            <TextField id="idToProCheck" label="NSC ID"></TextField>
                                        </div>
                                        <div className="col-12 align-center padding-top-15 padding-bottom-15">
                                            <Button variant="contained" color="primary"
                                                    onClick={() => this.checkIfProMember(document.getElementById("idToProCheck").value)}>
                                                Pro Check
                                            </Button>
                                        </div>


                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="row">
                                        <div className="col-12 align-right">
                                            <a className="padding-left-15"
                                               href="https://dive-nsc.com/impressum">Imprint</a>
                                            <a className="padding-left-15" href="https://dive-nsc.com/datenschutz">Data
                                                Protection</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }/>
                        <Route path='*' render={() =>
                            <div className="card login-card">
                                <div className="card-header">
                                    <h1>Login required</h1>
                                </div>
                                <div className="card-body">
                                    Please log in first.
                                </div>
                                <div className="card-footer align-right">
                                    <Button variant="contained" color="primary"
                                            onClick={() =>
                                                window.open("/login", "_self")}>Go to login
                                    </Button>
                                </div>
                            </div>
                        }
                        />
                    </Switch>
                </Router>
            )
        } else {
            return (
                <div className="container-fluid bg-main">
                    <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"/>
                    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500"/>
                    <Router>
                        <div className="main-frame">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12 header-nav-container align-right">
                                        <Drawer modal open={this.state.menuToggle}
                                                onClose={() => this.setState({menuToggle: false})}>
                                            <div className="drawer-navigation">
                                                {adminMenu}
                                                {userMenu}
                                                {trainerMenu}
                                                {centerMenu}
                                                <div className="nav-section">Session</div>
                                                <div className="nav-link logout-button"
                                                     onClick={() => this.logout()}>Logout
                                                </div>
                                            </div>
                                        </Drawer>
                                        <div className="row">
                                            <div className="col-5 align-left">
                                                <button className="material-icons icon-button-styler"
                                                        onClick={() => this.setState({menuToggle: !this.state.open})}>menu
                                                </button>
                                                <Link to='/'>
                                                    <button
                                                        className="material-icons icon-button-styler">home
                                                    </button>
                                                </Link>
                                                <button className="material-icons icon-button-styler"
                                                        onClick={() => window.open("https://dive-nsc.com/hilfesysteme-obs", "_blank")}>video_library
                                                </button>

                                            </div>
                                            <div className="col-7 align-right">
                                                <div className="nav-headline">
                                                    {this.state.user.firstName} {this.state.user.lastName}
                                                    <img className="logo-image" alt="logo"
                                                         src={logoSmall}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Switch>
                                    <Route path='/profile' render={(props) =>
                                        <User {...props} constants={this.state}/>}/>
                                    <Route path='/admin' render={(props) =>
                                        <Admin {...props} constants={this.state}/>}/>
                                    <Route path='/center/:id' render={(props) =>
                                        <Center {...props} constants={this.state}/>}/>
                                    <Route path='/trainer' render={(props) =>
                                        <Trainer {...props} constants={this.state}/>}/>
                                    <Route path='/pdf'/>
                                    <Route path='*' render={() => {
                                        if (this.state.user.managerOfCenters.length > 0
                                            || this.state.user.trainerOfCenter != null) {
                                            return (
                                                <div className="row">
                                                    <div className="col-12 main-container">
                                                        <iframe className="home-frame"
                                                                src="https://dive-nsc-manuals.sandboxtools.de/dive-leader-home/"
                                                                title="home-dive-leader"/>
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (

                                                <div className="row">
                                                    <div className="col-12 main-container">
                                                        <iframe className="home-frame"
                                                                src="https://dive-nsc-manuals.sandboxtools.de/student-home/"
                                                                title="home-student"/>
{/*                                                        <div className="front-page-container">
                                                            <h1>Welcome to NSC!</h1>
                                                            <img alt="NSC Logo" src={logoBig} className="front-image"/>
                                                            <div>See our <a className="link"
                                                                            href="https://dive-nsc.com/hilfesysteme-obs"
                                                                            target="_blank" rel="noopener noreferrer">manual
                                                                videos</a> for this system.
                                                            </div>
                                                        </div>*/}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }
                                    }/>
                                </Switch>
                                <div className="row">
                                    <div className="col-12 align-right footer-container">
                                        <a className="footer-link" href="https://dive-nsc.com/impressum">Imprint</a>
                                        <a className="footer-link" href="https://dive-nsc.com/datenschutz">Data
                                            Protection</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Router>
                </div>
            )
        }
    }
}

export default App;
