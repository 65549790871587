import React from 'react';
import axios from 'axios';
import {Route, Switch} from "react-router-dom";
import {
    RegistrationCreateView,
    RegistrationActivateView,
    PasswordResetRequestView,
    PasswordChangeView
} from "./RegistrationView";
import userData from "../models/UserModel";

export default class Registration extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            newUser: JSON.parse(JSON.stringify(userData)),
            activationLoadingStatus: "loading",
            passwordResetLoadingStatus: false,
            passwordChangeLoadingStatus: false,
            registrationLoadingStatus: false
        };
        if ((this.props.match.params.id !== 'new')&&(this.props.match.params.id !== 'forgot-password')) {
            this.activateAccount(this.props.match.params.id);
        }
    };

    handleUserFieldChange = (event, field, subfield) => {
        this.newUser = this.state.newUser;
        if (subfield === null) {
            this.newUser[field] = event.target.value;
        } else {
            this.newUser[field][subfield] = event.target.value;
        }
        this.setState({newUser: this.newUser});
    };
    registerUser = (user) => {
        if (user.password === "") {
            alert("Please set a password!")
        } else if (user.password === user.passwordRepeat) {
            this.setState({registrationLoadingStatus: true});
            axios({
                method: 'post',
                url: this.props.constants.baseAPIURL + 'ffa/register',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: user

            })
                .then(() => {
                        this.setState({registrationLoadingStatus: false});
                        alert("Thank you for your registration. You will receive an e-mail to confirm that it's you. Please follow the instructions in the e-mail to finalize your registration.");
                        window.open("/login", "_self");
                    }
                )
                .catch((error) => {
                        this.setState({registrationLoadingStatus: false});
                        if (error.response.data.status === 400) {
                            alert("Something went wrong. Please check your data.")
                        } else alert(error.response.data.message);
                    }
                )
        } else alert("Passwords don't match!")
    };
    activateAccount = (token) => {
        axios({
            method: 'post',
            url: this.props.constants.baseAPIURL + 'ffa/register/' + token + '/confirm',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(() => {
                    this.setState({activationLoadingStatus: "success"});
                }
            )
            .catch(() => {
                this.setState({activationLoadingStatus: "fail"});
            });
    };
    requestPasswordReset = (userName, email) => {
        if (!(userName && email)) {
            alert("Please input user name and e-mail.")
        } else {
            this.setState({passwordResetLoadingStatus: true});
            axios({
                method: 'post',
                url: this.props.constants.baseAPIURL + 'ffa/password/reset',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    email: email,
                    userName: userName
                }

            })
                .then(() => {
                        this.setState({passwordResetLoadingStatus: false});
                        alert("Password reset link will be sent to your e-mail address.");
                        window.open("/login", "_self");
                    }
                )
                .catch((error) => {
                        this.setState({passwordResetLoadingStatus: false});
                        if(error.response.data.message) {
                            alert(error.response.data.message);
                        }
                        else alert("Something went wrong. Please try agin");
                    }
                )
        }
    };
    changePassword = (password, passwordRepeat) => {
        if (password === "") {
            alert("Please set a password!")
        } else if (password === passwordRepeat) {
            this.setState({passwordChangeLoadingStatus: true});
            axios({
                method: 'post',
                url: this.props.constants.baseAPIURL + 'ffa/password/change',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    password: password,
                    token: this.props.match.params.id
                }

            })
                .then(() => {
                        this.setState({passwordChangeLoadingStatus: false});
                        alert("Your password has been changed. You can now log in with the new password.");
                        window.open("/login", "_self");
                    }
                )
                .catch((error) => {
                        this.setState({passwordChangeLoadingStatus: false});
                        if (!error.response.data.message) {
                            alert("The activation link is not valid. Aborting...")
                        } else alert(error.response.data.message);
                        window.open("/login", "_self");
                    }
                )
        } else alert("Passwords don't match!")
    };


    render() {
        return (
            <div>
                <Switch>
                    <Route exact path='/resetPassword/forgot' render={(props) =>
                        <PasswordResetRequestView {...props}
                                                  passwordResetLoadingStatus={this.state.passwordResetLoadingStatus}
                                                  requestPasswordReset={this.requestPasswordReset}/>}/>
                    <Route exact path='/resetPassword/:id' render={(props) =>
                        <PasswordChangeView {...props}
                                                  passwordChangeLoadingStatus={this.state.passwordChangeLoadingStatus}
                                                changePassword={this.changePassword}/>}/>
                    <Route exact path='/registration/new' render={(props) =>
                        <RegistrationCreateView {...props}
                                                registrationLoadingStatus={this.state.registrationLoadingStatus}
                                                newUser={this.state.newUser}
                                                handleUserFieldChange={this.handleUserFieldChange}
                                                registerUser={this.registerUser}/>}/>
                    <Route exact path='/registration/:id' render={(props) =>
                        <RegistrationActivateView {...props}
                                                  activationLoadingStatus={this.state.activationLoadingStatus}/>}/>
                </Switch>
            </div>
        );
    }
}